import { Injectable } from '@angular/core';
import { Horizon, Objective, Step } from '@app/types';
import { transaction } from '@datorama/akita';
import { Observable } from 'rxjs';
import { OnboardingStore } from './onboarding.store';
import { OnboardingQuery } from './onboarding.query';
import { OnboardingState, OnboardingType } from './onboarding.state';
import { InvestmentPlan } from '../investment-plans/investment-plans.model';
import { ESGUserParametersRequestDTO } from '@src/app/backend/dto/esg.dto';
import { DimensionsDTO } from '@src/app/backend/dto';

@Injectable({ providedIn: 'root' })
export class OnboardingStoreService {
  constructor(private readonly _onboardingStore: OnboardingStore, private readonly _onboardingQuery: OnboardingQuery) {}

  get state$(): Observable<OnboardingState> {
    return this._onboardingQuery.select();
  }

  get state(): OnboardingState {
    return this._onboardingQuery.getValue();
  }

  get isVirtual(): boolean {
    return this._onboardingQuery.getValue().isVirtual;
  }

  get isVirtual$(): Observable<boolean> {
    return this._onboardingQuery.select((state) => state.isVirtual);
  }

  get selectedObjective$(): Observable<Objective> {
    return this._onboardingQuery.select((state) => state.objective);
  }

  get selectedHorizon(): Horizon {
    return this._onboardingQuery.getValue().horizon;
  }

  get selectedHorizon$(): Observable<Horizon> {
    return this._onboardingQuery.select((state) => state.horizon);
  }

  get investmentPlanIdentifier(): string {
    return this._onboardingQuery.getValue().investmentPlanIdentifier;
  }

  get investmentPlanIdentifier$(): Observable<string> {
    return this._onboardingQuery.select((state) => state.investmentPlanIdentifier);
  }

  get investmentPlanToUpdate(): InvestmentPlan {
    return this._onboardingQuery.getValue().investmentPlanToUpdate;
  }

  get investmentPlanToUpdate$(): Observable<InvestmentPlan> {
    return this._onboardingQuery.select((state) => state.investmentPlanToUpdate);
  }

  get steps(): Step[] {
    return this._onboardingQuery.getValue().steps;
  }

  get hasSteps(): boolean {
    return !!this._onboardingQuery.getValue()?.steps;
  }

  get steps$(): Observable<Step[]> {
    return this._onboardingQuery.select((state) => state.steps);
  }

  get onboardingType(): OnboardingType {
    return this._onboardingQuery.getValue()?.onboardingType;
  }

  get onboardingType$(): Observable<OnboardingType> {
    return this._onboardingQuery.select((state) => state.onboardingType);
  }

  get createInvestmentPlanPayload(): Partial<OnboardingState> {
    return {
      investmentPlanIdentifier: this._onboardingQuery.getValue()?.investmentPlanIdentifier,
      isVirtual: this._onboardingQuery.getValue()?.isVirtual
    };
  }

  get confirmUpdateVirtualMandateProposition(): boolean {
    return this._onboardingQuery.getValue()?.confirmUpdateVirtualMandateProposition;
  }

  get onboardingCompleted(): boolean {
    return this._onboardingQuery.getValue()?.onboardingCompleted;
  }

  get onboardingStarted(): boolean {
    return this._onboardingQuery.getValue()?.onboardingStarted;
  }

  get esgCompleted(): boolean {
    return this._onboardingQuery.getValue()?.esgCompleted;
  }

  get investmentPreferenceCompleted(): boolean {
    return this._onboardingQuery.getValue()?.investmentPreferenceCompleted;
  }

  get investmentPreferenceStarted(): boolean {
    return this._onboardingQuery.getValue()?.investmentPreferenceStarted;
  }

  get subscriptionCompleted(): boolean {
    return this._onboardingQuery.getValue()?.subscriptionCompleted;
  }

  get subscriptionStarted(): boolean {
    return this._onboardingQuery.getValue()?.subscriptionStarted;
  }

  get esgParameters(): ESGUserParametersRequestDTO {
    return this._onboardingQuery.getValue().esgParameters;
  }

  set investmentPlanIdentifier(value: string) {
    this._onboardingStore.update({ investmentPlanIdentifier: value });
  }

  set selectedObjective(objective: Objective) {
    this._onboardingStore.update({ objective });
  }

  set selectedHorizon(horizon: Horizon) {
    this._onboardingStore.update({ horizon });
  }

  set enteredEsgParameters(params: Partial<ESGUserParametersRequestDTO>) {
    this._onboardingStore.update({ esgParameters: { ...this.esgParameters, ...params } });
  }

  set enteredTargetAmount(targetAmount: string) {
    this._onboardingStore.update({ targetAmount });
  }

  set enteredInitialAmount(initialAmount: string) {
    this._onboardingStore.update({ initialAmount });
  }

  set isVirtual(isVirtual: boolean) {
    this._onboardingStore.update({ isVirtual });
  }

  set confirmUpdateVirtualMandateProposition(confirmUpdateVirtualMandateProposition: boolean) {
    this._onboardingStore.update({ confirmUpdateVirtualMandateProposition });
  }

  set onboardingCompleted(onboardingCompleted: boolean) {
    this._onboardingStore.update({ onboardingCompleted });
  }

  set onboardingStarted(onboardingStarted: boolean) {
    this._onboardingStore.update({ onboardingStarted });
  }

  set esgCompleted(esgCompleted: boolean) {
    this._onboardingStore.update({ esgCompleted });
  }

  set subscriptionCompleted(subscriptionCompleted: boolean) {
    this._onboardingStore.update({ subscriptionCompleted });
  }

  set investmentPreferenceStarted(investmentPreferenceStarted: boolean) {
    this._onboardingStore.update({ investmentPreferenceStarted });
  }

  set investmentPreferenceCompleted(investmentPreferenceCompleted: boolean) {
    this._onboardingStore.update({ investmentPreferenceCompleted });
  }

  set subscriptionStarted(subscriptionStarted: boolean) {
    this._onboardingStore.update({ subscriptionStarted });
  }

  public setESGParametersFromMandateDimensions(dimensions: DimensionsDTO) {
    let params: Partial<ESGUserParametersRequestDTO> = {};
    const esgMandateDimensions = dimensions.esgPreferences ? JSON.parse(dimensions.esgPreferences) : '';
    if (!esgMandateDimensions) return;
    params.q1 = esgMandateDimensions.Q1;
    params.q2 = esgMandateDimensions.Q2;
    params.q2CatA = esgMandateDimensions.Q2CatA;
    params.q2CatB = esgMandateDimensions.Q2CatB;
    params.q2CatC = esgMandateDimensions.Q2CatC;
    params.q3 = esgMandateDimensions.Q3;
    this._onboardingStore.update({ esgParameters: { ...this.esgParameters, ...params } });
  }

  @transaction()
  public setNewInitialState(state: Partial<OnboardingState>): void {
    this._onboardingStore.reset();
    this._onboardingStore.update(state);
  }

  public updateStepsState(steps: Step[]): void {
    this._onboardingStore.update({ steps });
  }

  public resetStore(): void {
    this._onboardingStore.reset();
  }
}
