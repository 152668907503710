import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { InvestmentPlanAllocationPerformanceResponseDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlanAllocationPerformanceRequest extends Request<
  never,
  InvestmentPlanAllocationPerformanceResponseDTO
> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      path: `/broad${options?.version ?? '/v2'}/me/investment_plans/${
        options?.pathParams?.investmentPlanIdentifier
      }/allocation_performance`
    });
  }

  public execute(): Observable<InvestmentPlanAllocationPerformanceResponseDTO> {
    return super
      .execute()
      .pipe(
        map(
          (response: HttpResponse<InvestmentPlanAllocationPerformanceResponseDTO>) => response.body
        )
      );
  }
}

export function getInvestmentPlanAllocationPerformance(
  options?: RequestOptions<never>
): InvestmentPlanAllocationPerformanceRequest {
  return new InvestmentPlanAllocationPerformanceRequest(options);
}
