import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class CloseQworkflowSessionRequest extends Request<never, void> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      method: 'PUT',
      path: `/broad${options?.version ?? '/v1'}/module_questionnaires/${
        options?.pathParams?.sessionId
      }/close_session`
    });
  }

  public execute(): Observable<void> {
    return super.execute().pipe(map((response: HttpResponse<any>) => response.body));
  }
}

export function closeQworkflowSession(
  options?: RequestOptions<never>
): CloseQworkflowSessionRequest {
  return new CloseQworkflowSessionRequest(options);
}
