import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IdleService, LoaderService } from '@services';
import { ApplicationConfigurationStoreService } from '@stores';
import { Subject } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  public canDisplayHeader: boolean;

  private readonly _destroyed$ = new Subject<boolean>();

  constructor(
    private readonly _loaderService: LoaderService,
    private readonly _router: Router,
    private readonly _applicationConfigurationStoreService: ApplicationConfigurationStoreService,
    private readonly _idleService: IdleService,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  public ngOnInit(): void {
    this._loaderService.isLoading$
      .pipe(takeUntil(this._destroyed$), distinctUntilChanged(), delay(0))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });
    this._router.events
      .pipe(
        takeUntil(this._destroyed$),
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => !event.url.includes('authentication'))
      )
      .subscribe((result: boolean) => {
        this.canDisplayHeader = result;
      });

    this._document.body.style.backgroundColor = '#efefef';
    this._idleService.initialize(this._applicationConfigurationStoreService.state);
  }

  public ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.unsubscribe();
  }
}
