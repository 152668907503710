import { Injectable } from '@angular/core';
import { LogOutCommand } from '@commands/ui/log-out.command';
import { IdleWarningNotificationModal } from '@modules/modals';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationConfigurationState, TokenStoreService } from '@stores';
import { NzModalService } from 'ng-zorro-antd';

@Injectable({ providedIn: 'root' })
export class IdleService {
  private _modal;

  constructor(
    private readonly _idle: Idle,
    private readonly _nzModalService: NzModalService,
    private readonly _tokenStoreService: TokenStoreService,
    private readonly _logOutCommand: LogOutCommand
  ) {}

  public initialize(configState: ApplicationConfigurationState): void {
    this._idle.setIdle(configState.IDLE_DURATION ?? 270);
    this._idle.setTimeout(configState.IDLE_COUNTDOWN ?? 10);
    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this._idle.onIdleStart.subscribe(() => this.onIdleStart());
    this._idle.onIdleEnd.subscribe(() => this.onIdleEnd());
    this._idle.onTimeout.subscribe(() => this.onIdleTimeout());
    this._idle.watch();
  }

  public destroy(): void {
    this._idle.stop();
    if (this._modal) {
      this._modal.close();
      this._modal = undefined;
    }
  }

  private onIdleStart() {
    if (!this._tokenStoreService.isLogged) {
      this.destroy();
      return this._logOutCommand.execute();
    }

    this._modal = this._nzModalService.create({
      nzClassName: 'idle-warning-notification-modal',
      nzContent: IdleWarningNotificationModal,
      nzComponentParams: {
        timeoutWarning$: this._idle.onTimeoutWarning
      },
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false
    });
  }

  private onIdleEnd() {
    if (!this._tokenStoreService.isLogged) {
      this.destroy();
      return this._logOutCommand.execute();
    }

    if (this._modal) {
      this._modal.close();
      this._modal = undefined;
    }

    this._idle.watch();
  }

  private onIdleTimeout() {
    this.destroy();
    this._logOutCommand.execute();
  }
}
