import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { persistState } from '@datorama/akita';
import { environment } from '@environments/environment';

if (environment.production) {
  enableProdMode();
}

const storage = persistState({
  storage: window.sessionStorage,
  include: ['token', 'user', 'onboarding', 'analytics'],
  key: 'bpf-store'
});

const providers = [
  {
    provide: 'persistStorage',
    useValue: storage
  }
];

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
