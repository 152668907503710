import { Injectable } from '@angular/core';
import { ProjectionChartPoint } from '@app/types';
import { InitialInvestmentMatrixDTO } from '@backend/dto';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Advice,
  LookingForwardBackendServiceNotWorking,
  Probability,
  SimulationCashFlowState,
  SimulationScenariosState
} from './simulation-cash-flow.state';
import { SimulationCashFlowQuery } from './simulation-cash-flow.query';
import { createInitialState, SimulationCashFlowStore } from './simulation-cash-flow.store';

@Injectable({ providedIn: 'root' })
export class SimulationCashFlowStoreService {
  constructor(
    private readonly _simulationCashFlowStore: SimulationCashFlowStore,
    private readonly _simulationCashFlowQuery: SimulationCashFlowQuery
  ) {}

  get state$(): Observable<SimulationCashFlowState> {
    return this._simulationCashFlowQuery.select();
  }

  get scenarios$(): Observable<SimulationScenariosState> {
    return this._simulationCashFlowQuery
      .select((state) => {
        return {
          matrix: state.matrix,
          horizonInMonths: state.projectionParameters.horizonInMonths
        };
      })
      .pipe(
        map((data) => {
          let index;

          if (typeof data.horizonInMonths === 'number') {
            index = data.horizonInMonths ? data.horizonInMonths : 0;
          } else {
            index = 5 * 12;
          }

          return {
            bestScenario: data.matrix.upperBestScenario.length ? Math.round(data.matrix.upperBestScenario[index]) : 0,
            mostLikelyScenario: data.matrix.medianScenario.length ? Math.round(data.matrix.medianScenario[index]) : 0,
            worstScenario: data.matrix.lowerWorstScenario.length ? Math.round(data.matrix.lowerWorstScenario[index]) : 0
          };
        })
      );
  }

  get probability$(): Observable<Probability> {
    return this._simulationCashFlowQuery.select((state) => state.probability);
  }

  get advices$(): Observable<Advice[]> {
    return this._simulationCashFlowQuery.select((state) => state.advices);
  }

  get chartPoints$(): Observable<ProjectionChartPoint[]> {
    return this._simulationCashFlowQuery.select((state) => state.chartPoints);
  }

  get lookingForwardBackendServiceNotWorking$(): Observable<LookingForwardBackendServiceNotWorking> {
    return this._simulationCashFlowQuery.select((state) => state.lookingForwardBackendServiceNotWorking);
  }

  public updateMatrix(newState: InitialInvestmentMatrixDTO): void {
    this._simulationCashFlowStore.update({ matrix: newState });
  }

  public updateProjectionChartPoints(newState: ProjectionChartPoint[]): void {
    this._simulationCashFlowStore.update({ chartPoints: newState });
  }

  public updateHorizonProjectionParameters(horizonInYears: number): void {
    this._simulationCashFlowStore.update((state) => ({
      ...state,
      projectionParameters: {
        ...state.projectionParameters,
        horizonInYears,
        horizonInMonths: horizonInYears * 12
      }
    }));
  }

  public updateProbability(newState: Probability): void {
    this._simulationCashFlowStore.update({ probability: newState });
  }

  public updateAdvices(newState: Advice[]): void {
    this._simulationCashFlowStore.update({ advices: newState });
  }

  public updateLookingForwardBackendServiceNotWorking(newState: LookingForwardBackendServiceNotWorking): void {
    this._simulationCashFlowStore.update((state) => ({
      ...state,
      lookingForwardBackendServiceNotWorking: {
        ...state.lookingForwardBackendServiceNotWorking,
        ...newState
      }
    }));
  }

  public resetState(): void {
    this._simulationCashFlowStore.update({ ...createInitialState() });
  }
}
