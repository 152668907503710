import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PreSubscriptionContract } from '@app/types';
import { getPreSubscriptionSynthesis } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { HelpersService } from '@services/helpers.service';
import { WINDOW } from '@services/window.service';
import { OnboardingStoreService } from '@stores';
import { format } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GetPreSubscriptionSynthesisCommand implements ICommand {
  constructor(
    private readonly _helpersService: HelpersService,
    private readonly _onboardingStoreService: OnboardingStoreService,
    @Inject(WINDOW) private readonly _window
  ) {}

  public execute(): Observable<PreSubscriptionContract> {
    return getPreSubscriptionSynthesis({
      pathParams: {
        investmentPlanIdentifier: this._onboardingStoreService.investmentPlanIdentifier
      }
    })
      .execute()
      .pipe(
        map((response: HttpResponse<string>) => {
          const blob: Blob = this._helpersService.getDataAsBlob(response.body);
          const url = this._window.URL.createObjectURL(blob);
          const filename = response.headers.has('content-disposition')
            ? this._helpersService.getFilenameFromContentDispositionHeader(
                response.headers.get('content-disposition')
              )
            : `risk-profile-contract_${format(new Date(), 'yyyyMMdd_HHmmss')}.pdf`;

          return { blob, url, filename } as PreSubscriptionContract;
        })
      );
  }
}
