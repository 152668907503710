<i nz-icon [nzType]="'info-circle'"></i>

<div id="modal-content">
  <p>{{ 'modals.feature-access-denied.message' | translate }}</p>
</div>
<div id="modal-footer">
  <button nz-button nzType="primary" (click)="closeModal()">
    {{ 'common.cta.understand' | translate }}
  </button>
</div>
