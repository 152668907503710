import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

class CustomTranslateLoader implements TranslateLoader {
  constructor(private readonly _httpClient: HttpClient) {}

  public getTranslation(culture: string): Observable<any> {
    return this._httpClient
      .get(`assets/i18n/${culture}.json?v=${environment.applicationVersion}`)
      .pipe(first());
  }
}

export function CustomTranslateLoaderFactory(httpClient: HttpClient) {
  return new CustomTranslateLoader(httpClient);
}
