import { Injectable } from '@angular/core';
import { RGBColor } from '@app/types';

@Injectable({ providedIn: 'root' })
export class ColorService {
  private _colors = new Map<string, RGBColor>();

  public getRGBColorFromHexCode(hexCode: string): RGBColor {
    let color: RGBColor = this._colors.get(hexCode);

    if (color) {
      return color;
    }

    const rgb = this._convertHexToRGB(hexCode);
    color = {
      r: parseInt(rgb[1], 16),
      g: parseInt(rgb[2], 16),
      b: parseInt(rgb[3], 16)
    };

    this._colors.set(hexCode, color);

    return color;
  }

  private _convertHexToRGB(hexCode: string): any[] {
    return /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexCode);
  }
}
