import { Injectable } from '@angular/core';
import { ProjectionChartPoint, ProjectionParameters } from '@app/types';
import { InitialInvestmentMatrixDTO } from '@backend/dto';

@Injectable({ providedIn: 'root' })
export class MandateProjectionComputationService {
  public compute(matrix: InitialInvestmentMatrixDTO, projectionParameters: ProjectionParameters): ProjectionChartPoint[] {
    return new Array(16).fill(null).map((_item, index) => {
      const indexSourcePoint = index ? index * 12 - 1 : index;
      return {
        upperBestScenario: matrix.upperBestScenario[indexSourcePoint] ?? 0,
        lowerBestScenario: matrix.lowerBestScenario[indexSourcePoint] ?? 0,
        medianScenario: matrix.medianScenario[indexSourcePoint] ?? 0,
        upperWorstScenario: matrix.upperWorstScenario[indexSourcePoint] ?? 0,
        lowerWorstScenario: matrix.lowerWorstScenario[indexSourcePoint] ?? 0,
        objective: projectionParameters.objective || projectionParameters.investedAmount
      };
    });
  }
}
