import { Directive, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { AccessControlFunction } from '@app/types';
import { UserStoreService } from '@stores';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[accessControl]'
})
export class AccessControlDirective implements OnInit, OnDestroy {
  @Input()
  public accessControlFunction: AccessControlFunction | AccessControlFunction[];
  @Input()
  public deactivateElement: boolean;

  @HostBinding('attr.disabled')
  public disabled: boolean;

  private _destroyed$ = new Subject<boolean>();

  constructor(private readonly _userStoreService: UserStoreService) {}

  public ngOnInit(): void {
    let observable: Observable<boolean>;

    if (Array.isArray(this.accessControlFunction)) {
      observable = this._userStoreService.hasAccessControlFunctions$(this.accessControlFunction);
    } else {
      observable = this._userStoreService.hasAccessControlFunction$(this.accessControlFunction);
    }

    observable.pipe(takeUntil(this._destroyed$)).subscribe((hasAccess) => {
      if (!hasAccess || (hasAccess && this.deactivateElement)) {
        this.disabled = true;
      } else {
        this.disabled = null;
      }
    });
  }

  public ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.unsubscribe();
  }
}
