import { Injectable } from '@angular/core';
import { ICommand } from '@commands/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd';

@Injectable({ providedIn: 'root' })
export class ShowFeatureNotAvailableModalCommand implements ICommand {
  constructor(
    private readonly _nzModalService: NzModalService,
    private readonly _translateService: TranslateService
  ) {}

  public execute(): void {
    this._nzModalService.confirm({
      nzTitle: this._translateService.instant('common.confirm-modals.feature-not-available'),
      nzOkText: this._translateService.instant('common.cta.understand'),
      nzCancelText: null,
      nzClassName: 'feature-not-available-confirm-modal',
      nzIconType: 'info-circle'
    });
  }
}
