import { Injectable } from '@angular/core';
import { DimensionProfileCode } from '@app/types';
import { UserInvestmentPlansAggregatedPerformanceResponseDTO, UserInvestmentPlansAggregatedTNAResponseDTO } from '@backend/dto';
import {
  closeWorkflows,
  getInvestmentPlansAggregatedPerformance,
  getInvestmentPlansAggregatedTna,
  getInvestmentPlansHistoricalPerformance,
  getInvestmentPlansHistoricalPerformanceStored,
  getInvestmentPlansSummary
} from '@backend/requests';
import { GetUserInformationCommand } from '@commands/backend/get-user-information.command';
import { ICommand } from '@commands/interfaces';
import { EContractStatus } from '@enums';
import { ToPercentagePipe } from '@gambit/ng-resources';
import { HelpersService } from '@services/helpers.service';
import {
  AggregatedPerformance,
  AggregatedPerformanceStoreService,
  InvestmentPlan,
  InvestmentPlansStoreService,
  ReferencesStoreService
} from '@stores';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserOnboardingStatusService } from '@services/user-onboarding-status.service';

@Injectable({ providedIn: 'root' })
export class FetchUserMandatesCommand implements ICommand {
  constructor(
    private readonly _referencesStoreService: ReferencesStoreService,
    private readonly _investmentPlansStoreService: InvestmentPlansStoreService,
    private readonly _aggregatedPerformanceStoreService: AggregatedPerformanceStoreService,
    private readonly _getUserInformationCommand: GetUserInformationCommand,
    private readonly _helpersService: HelpersService,
    private readonly _toPercentagePipe: ToPercentagePipe,
    private readonly _userOnboardingStatusService: UserOnboardingStatusService
  ) {}

  public execute(): Observable<undefined> {
    return closeWorkflows()
      .execute()
      .pipe(
        switchMap(() => {
          return !this._userOnboardingStatusService.status ? this._userOnboardingStatusService.updateUserOnboardingStatus() : of(null);
        }),
        switchMap(() => {
          return combineLatest([
            this._getUserInformationCommand.execute(),
            getInvestmentPlansSummary().execute(),
            getInvestmentPlansHistoricalPerformance().execute(),
            getInvestmentPlansHistoricalPerformanceStored().execute()
          ]);
        }),
        switchMap((response) => this._getUserInvestmentPlansData(response)),
        map(([investmentPlans, aggregatedPerformance]) => {
          this._investmentPlansStoreService.addOrUpdateInvestmentPlans(investmentPlans);
          this._aggregatedPerformanceStoreService.updateState(aggregatedPerformance);
          return undefined;
        })
      );
  }

  private _getUserInvestmentPlansData([
    userInformation,
    investmentPlansSummary,
    investmentPlansHistoricalPerformance,
    investmentPlansHistoricalPerformanceStored
  ]): Observable<[InvestmentPlan[], AggregatedPerformance]> {
    let haveSignedContract = false;
    let virtualMandatesCumulatedAmount = 0;
    const investmentPlans = investmentPlansSummary.investmentPlanSummaries.map((investmentPlan) => {
      const item = investmentPlan as InvestmentPlan;
      item.profile = this._referencesStoreService.getInvestmentPlanProfile(investmentPlan.profileTextId);
      item.investmentPlanType = this._referencesStoreService.getInvestmentPlanType(investmentPlan.planTypeTextId);
      item.contract = investmentPlansSummary.contracts.find((contract) => contract.number === investmentPlan.contractNumber);
      item.colorClassName = item.contract.status.toLowerCase();
      item.contractName = this._helpersService.getInvestmentPlanContractName(
        item.contract.status as EContractStatus,
        item.contract.status === EContractStatus.Virtual ? item.name : item.contract.number,
        item.productName
      );
      item.contractNameDetailList = this._helpersService.getInvestmentPlanContractName(
        item.contract.status as EContractStatus,
        item.contract.status === EContractStatus.Virtual ? item.name : item.contract.number,
        item.productName,
        false
      );
      item.managementOrientation = this._helpersService.getInvestmentPlanManagementOrientationName(
        investmentPlan.dimensions.profile as DimensionProfileCode
      );
      item.historicalPerformance = investmentPlansHistoricalPerformance.find(
        (item) => item.planIdentifier === investmentPlan.investmentPlanIdentifier
      );
      item.historicalPerformanceStored = investmentPlansHistoricalPerformanceStored.find(
        (item) => item.planIdentifier === investmentPlan.investmentPlanIdentifier
      );
      item.earningsInPercentage = investmentPlan.isVirtual
        ? item.historicalPerformance?.earningInPercentage || 0
        : !!item.historicalPerformanceStored?.investmentPlanPerformance?.length
        ? item.historicalPerformanceStored.investmentPlanPerformance[item.historicalPerformanceStored.investmentPlanPerformance.length - 1]
            .percentageValue / 100
        : 0;
      item.tagNameKey = `common.words.${item.contract.status.toLowerCase()}`;

      if (!haveSignedContract) {
        haveSignedContract = item.contract.status === EContractStatus.Signed;
      }

      if (item.contract.status === EContractStatus.Virtual) {
        virtualMandatesCumulatedAmount += item.currentAmount;
      }

      return item;
    });

    return combineLatest([
      of<InvestmentPlan[]>(investmentPlans),
      this._getUserAggregatedPerformance(haveSignedContract, virtualMandatesCumulatedAmount)
    ]);
  }

  private _getUserAggregatedPerformance(
    haveSignedContract: boolean,
    virtualMandatesCumulatedAmount: number
  ): Observable<AggregatedPerformance> {
    return haveSignedContract
      ? getInvestmentPlansAggregatedTna()
          .execute()
          .pipe(
            map((response: UserInvestmentPlansAggregatedTNAResponseDTO) => {
              return {
                isVirtual: false,
                cumulatedAmount:
                  response.investmentPlanAggregatedTna && response.investmentPlanAggregatedTna.length
                    ? response.investmentPlanAggregatedTna[response.investmentPlanAggregatedTna.length - 1].cumulatedTna
                    : 0,
                performances: response.investmentPlanAggregatedTna.map((item) => ({
                  date: item.dateFrom,
                  value: item.cumulatedTna
                }))
              };
            })
          )
      : getInvestmentPlansAggregatedPerformance()
          .execute()
          .pipe(
            map((response: UserInvestmentPlansAggregatedPerformanceResponseDTO) => {
              return {
                isVirtual: true,
                cumulatedAmount: virtualMandatesCumulatedAmount,
                openingDate: response.openingDate,
                performances: response.performances?.map((performance) => ({
                  date: performance.date,
                  value: performance.value
                })),
                earnings: response.earningsInValue,
                earningsInPercentage: response.earnings
              };
            })
          );
  }
}
