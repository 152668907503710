import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injector } from '@angular/core';
import { IconsRegistryService } from '@services';
import { GetReferencesCommand } from '@commands/backend';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationConfigurationStoreService, TokenStoreService } from '@stores';
import { UserOnboardingStatusService } from '@services/user-onboarding-status.service';
import { AppInjectorRefInitializerFactory } from './app-injector-ref-initializer.factory';
import { IconsInitializerFactory } from './icons-initializer.factory';
import { ApplicationConfigurationLoaderFactory } from './application-configuration-loader.factory';
import { ApplicationLanguageInitializerFactory } from './application-language-initializer.factory';
import { ReferencesInitializerFactory } from './references-initializer.factory';
import { UserOnboardingInitializerFactory } from './user-onboarding-initializer.factory';

export const initializers = [
  {
    deps: [Injector],
    multi: true,
    provide: APP_INITIALIZER,
    useFactory: AppInjectorRefInitializerFactory
  },
  {
    deps: [HttpClient, ApplicationConfigurationStoreService],
    multi: true,
    provide: APP_INITIALIZER,
    useFactory: ApplicationConfigurationLoaderFactory
  },
  {
    deps: [TranslateService, Injector],
    multi: true,
    provide: APP_INITIALIZER,
    useFactory: ApplicationLanguageInitializerFactory
  },
  {
    deps: [GetReferencesCommand],
    multi: true,
    provide: APP_INITIALIZER,
    useFactory: ReferencesInitializerFactory
  },
  {
    deps: [IconsRegistryService],
    multi: true,
    provide: APP_INITIALIZER,
    useFactory: IconsInitializerFactory
  },
  {
    deps: [TokenStoreService, UserOnboardingStatusService],
    multi: true,
    provide: APP_INITIALIZER,
    useFactory: UserOnboardingInitializerFactory
  }
];

export * from './custom-translate-loader.factory';
export * from './application-configuration-loader.factory';
export * from './application-language-initializer.factory';
export * from './references-initializer.factory';
export * from './icons-initializer.factory';
export * from './app-injector-ref-initializer.factory';
export * from './user-onboarding-initializer.factory';
