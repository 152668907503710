import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LogOutAdminCommand, LogOutCommand } from '@commands/ui';
import { TranslateService } from '@ngx-translate/core';
import { WINDOW } from '@services/window.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenStoreService } from '@stores/token/token-store.service';

@Injectable({ providedIn: 'root' })
export class ErrorHttpInterceptor implements HttpInterceptor {
  private _errorModalRef: NzModalRef;

  constructor(
    private readonly _router: Router,
    private readonly _nzModalService: NzModalService,
    private readonly _translateService: TranslateService,
    private readonly _logOutCommand: LogOutCommand,
    private readonly _logOutAdminCommand: LogOutAdminCommand,
    private readonly _tokenStoreService: TokenStoreService,
    @Inject(WINDOW) private readonly _window: Window
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((responseError) => {
        if (responseError instanceof HttpErrorResponse) {
          return this._handleResponseError(responseError, request, next);
        }
      })
    );
  }

  private _handleResponseError(responseError: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (![400, 404, 500].some((code) => code === responseError.status)) {
      return throwError(responseError);
    }

    const notConnectedPage = ['/webclient/authentication', '/webclient/eligibility/fail'].some(
      (element) => this._router.url === element || this._router.url.includes(element)
    );
    // Check if the error comes from one of the looking forward requests and execute on mandate detail page
    const lookingForwardService =
      ['simulation_cash_flow', 'simulation/advices', 'simulation/probability'].some((element) => request.url.includes(element)) &&
      this._router.url.includes('webclient/mandates/');
    if (notConnectedPage || lookingForwardService) {
      return throwError(responseError);
    }

    switch (responseError.error?.error) {
      case 'NotEnoughMoney':
        this._errorModalRef = this._nzModalService.confirm({
          nzTitle: this._translateService.instant('common.confirm-modals.not-enough-money'),
          nzOkText: this._translateService.instant('common.cta.ok'),
          nzCancelText: null,
          nzClassName: 'not-enough-money-confirm-modal',
          nzIconType: 'info-circle'
        });
        break;
      default:
        this._errorModalRef = this._nzModalService.confirm({
          nzTitle: this._translateService.instant('common.confirm-modals.unknown-error'),
          nzOkText: this._translateService.instant('common.cta.retry'),
          nzCancelText: this._translateService.instant('common.cta.cancel'),
          nzClassName: 'unknown-error-confirm-modal',
          nzIconType: 'info-circle',
          nzOnOk: () => {
            this._window.location.reload();
          },
          nzOnCancel: () => {
            const message = `Receive ${responseError.status} HTTP error code from ${request.url}`;
            this._tokenStoreService.isAdmin ? this._logOutAdminCommand.execute(message) : this._logOutCommand.execute(message);
          }
        });
    }

    return throwError(responseError);
  }
}
