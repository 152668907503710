import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import {
  InvestmentPlanSimulationAdvicesRequestDTO,
  InvestmentPlanSimulationAdvicesResponseDTO
} from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlanSimulationAdvicesRequest extends Request<
  InvestmentPlanSimulationAdvicesRequestDTO,
  InvestmentPlanSimulationAdvicesResponseDTO
> {
  constructor(options?: RequestOptions<InvestmentPlanSimulationAdvicesRequestDTO>) {
    super({
      ...options,
      method: 'POST',
      path: `${options?.version ?? '/v2'}/users/me/investment_plans/${
        options?.pathParams?.investmentPlanIdentifier
      }/simulation/advices`,
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

  public execute(): Observable<InvestmentPlanSimulationAdvicesResponseDTO> {
    return super
      .execute()
      .pipe(
        map((response: HttpResponse<InvestmentPlanSimulationAdvicesResponseDTO>) => response.body)
      );
  }
}

export function getInvestmentPlanSimulationAdvices(
  options?: RequestOptions<InvestmentPlanSimulationAdvicesRequestDTO>
): InvestmentPlanSimulationAdvicesRequest {
  return new InvestmentPlanSimulationAdvicesRequest(options);
}
