import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { EligibilityState } from './eligibility.state';
import { EligibilityStore } from './eligibility.store';

@Injectable({ providedIn: 'root' })
export class EligibilityQuery extends Query<EligibilityState> {
  constructor(protected eligibilityStore: EligibilityStore) {
    super(eligibilityStore);
  }
}
