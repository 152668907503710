import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@services';
import { TokenStoreService } from '@stores';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TokenHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly _tokenStoreService: TokenStoreService,
    private readonly _authenticationService: AuthenticationService
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const state = this._tokenStoreService.appState;

    if (
      ['/references', '/broad/v1.0/token_apim', '/auth/token'].some((element) =>
        request.url.includes(element)
      )
    ) {
      return next.handle(request);
    }

    if (state && state.tokenType && state.accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `${state.tokenType} ${state.accessToken}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((responseError) => {
        if (responseError instanceof HttpErrorResponse) {
          return this._handleResponseError(responseError, request, next);
        }
      })
    );
  }

  private _handleResponseError(
    responseError: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    switch (responseError.status) {
      case 401:
        return this._authenticationService.refreshToken(responseError, request, next);
      default:
        return throwError(responseError);
    }
  }
}
