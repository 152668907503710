import { Injectable } from '@angular/core';
import { ProjectionParameters } from '@app/types';
import { InvestmentPlanSimulationAdvicesRequestDTO, SimulationAdviceDTO } from '@backend/dto';
import { getInvestmentPlanSimulationAdvices } from '@backend/requests';
import { ESimulationAdviceType } from '@enums';
import { ICommand } from '@commands/interfaces';
import { AmountPipe } from '@modules/shared/pipes';
import { TranslateService } from '@ngx-translate/core';
import { Advice, InvestmentPlan, SimulationCashFlowStoreService } from '@stores';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GetInvestmentPlanSimulationAdvicesCommand implements ICommand {
  constructor(
    private readonly _simulationCashFlowStoreService: SimulationCashFlowStoreService,
    private readonly _amountPipe: AmountPipe,
    private readonly _translateService: TranslateService
  ) {}

  public execute(data: { investmentPlan: InvestmentPlan; projectionParameters: ProjectionParameters }): void {
    const payload: InvestmentPlanSimulationAdvicesRequestDTO = {
      nbPeriod: data.projectionParameters.horizonInMonths,
      initialCapital: data.projectionParameters.investedAmount,
      recurrenceTextId: data.projectionParameters.recurrenceFrequency.textId,
      recurringAmount: data.projectionParameters.recurrentAmount,
      tna: data.projectionParameters.tna,
      targetAmount: data.projectionParameters.objective
    };

    getInvestmentPlanSimulationAdvices({
      pathParams: { investmentPlanIdentifier: data.investmentPlan.investmentPlanIdentifier },
      payload
    })
      .execute()
      .pipe(
        map((response) => {
          return response.map((item) => {
            return {
              ...item,
              label: this._getAdviceLabel(data.investmentPlan, data.projectionParameters, item)
            };
          }) as Advice[];
        })
      )
      .subscribe(
        (result) => {
          this._simulationCashFlowStoreService.updateAdvices(result);
        },
        (error) => {
          this._simulationCashFlowStoreService.updateLookingForwardBackendServiceNotWorking({ isAdviceNotWorking: true });
        }
      );
  }

  private _getAdviceLabel(investmentPlan: InvestmentPlan, projectionParameters: ProjectionParameters, advice: SimulationAdviceDTO): string {
    let translateKey = '';
    let value: string | number;
    const name = investmentPlan.isVirtual ? investmentPlan.name.trim() : this._obfuscatedContractNumber(investmentPlan.contract.number);

    switch (advice.type) {
      case ESimulationAdviceType.InitialAmount:
        translateKey = investmentPlan.isVirtual
          ? 'mandates.mandate-detail.projection.with-objective.blocks.advices.virtual.initial-amount'
          : 'mandates.mandate-detail.projection.with-objective.blocks.advices.real.initial-amount';
        value = this._amountPipe.transform(advice.advisedValue, '€');
        break;
      case ESimulationAdviceType.RecurringCashFlow:
        translateKey = investmentPlan.isVirtual
          ? 'mandates.mandate-detail.projection.with-objective.blocks.advices.virtual.recurring-cash-flow'
          : 'mandates.mandate-detail.projection.with-objective.blocks.advices.real.recurring-cash-flow';
        value = this._amountPipe.transform(advice.advisedValue, '€');
        break;
      case ESimulationAdviceType.TimeHorizon:
        if (advice.advisedValue <= 12) {
          translateKey = investmentPlan.isVirtual
            ? 'mandates.mandate-detail.projection.with-objective.blocks.advices.virtual.time-horizon.year'
            : 'mandates.mandate-detail.projection.with-objective.blocks.advices.real.time-horizon.year';
        } else {
          translateKey = investmentPlan.isVirtual
            ? 'mandates.mandate-detail.projection.with-objective.blocks.advices.virtual.time-horizon.years'
            : 'mandates.mandate-detail.projection.with-objective.blocks.advices.real.time-horizon.years';
        }
        value = Math.min(Math.round(advice.advisedValue / 12), 15);
        break;
    }

    return this._translateService.instant(translateKey, {
      value,
      name,
      frequency: projectionParameters.recurrenceFrequency.label,
      colorClassName: investmentPlan.colorClassName
    });
  }

  private _obfuscatedContractNumber(contractNumber: string): string {
    return `****${contractNumber.length > 5 ? contractNumber.substr(contractNumber.length - 5) : contractNumber}`;
  }
}
