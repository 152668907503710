import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService } from '@services';
import { Observable, Observer } from 'rxjs';

@Injectable()
export class LoaderHttpInterceptor implements HttpInterceptor {
  private _requests: HttpRequest<any>[] = [];

  constructor(private readonly _loaderService: LoaderService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('x-ignore-loader')) {
      return next.handle(req);
    }

    this._requests.push(req);
    this._loaderService.updateLoadingStatus(this._requests.length > 0);

    return new Observable((observer: Observer<any>) => {
      const subscription = next.handle(req).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this._removeRequest(req);
            observer.next(event);
          }
        },
        (err) => {
          this._removeRequest(req);
          observer.error(err);
        },
        () => {
          this._removeRequest(req);
          observer.complete();
        }
      );

      // remove request from queue when cancelled
      return () => {
        this._removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }

  private _removeRequest(req: HttpRequest<any>): void {
    const index = this._requests.indexOf(req);

    if (index === -1) {
      return;
    }

    this._requests.splice(index, 1);
    this._loaderService.updateLoadingStatus(this._requests.length > 0);
  }
}
