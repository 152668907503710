import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MoneyValueConverter } from '@gambit/ng-form';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

type AmountInputChanges = {
  reset: SimpleChange;
};

@Component({
  selector: 'app-amount-input',
  templateUrl: './amount-input.component.html',
  styleUrls: ['./amount-input.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmountInputComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public className: string;
  @Input() public reset: number;

  @Output() public amountInputValueChanges = new EventEmitter<number>();

  public amountInput = new FormControl();
  public amountPlaceholder = '1000';
  public maxValueAuthorized = '100000000';
  public moneyValueConverter = new MoneyValueConverter({ emptyValue: true, precision: 0, positiveValue: true });

  private _destroyed$ = new Subject<boolean>();

  public ngOnInit(): void {
    this.amountInput.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        filter((value) => value > this.maxValueAuthorized),
        debounceTime(10)
      )
      .subscribe((value) => {
        this.amountInput.setValue(this.maxValueAuthorized);
      });
    this.amountInput.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        filter((value) => value <= this.maxValueAuthorized),
        debounceTime(500)
      )
      .subscribe((value) => {
        this._onAmountInputValueChanged(value);
      });
    this.amountInputValueChanges.emit(+this.amountPlaceholder);
  }

  public ngOnChanges(changes: AmountInputChanges): void {
    if (changes?.reset?.currentValue) {
      this.amountInput.patchValue('', { emitEvent: false });
    }
  }

  public ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.unsubscribe();
  }

  private _onAmountInputValueChanged(value: string): void {
    const number = Math.abs(!value ? +this.amountPlaceholder : +value);
    this.amountInputValueChanges.emit(number);
  }
}
