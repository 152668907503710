import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ReferencesState } from './references.state';
import { ReferencesStore } from './references.store';

@Injectable({ providedIn: 'root' })
export class ReferencesQuery extends Query<ReferencesState> {
  constructor(protected referencesStore: ReferencesStore) {
    super(referencesStore);
  }
}
