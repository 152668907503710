import { Injectable } from '@angular/core';
import { CreateWithdrawInvestAction, InvestAction } from '@app/types';
import { createInvestmentPlanVirtualWithdrawTransfer, createInvestmentPlanWithdrawTransfer } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { EContractStatus, EPaymentMethod, EPaymentTarget } from '@enums';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FetchUserMandatesCommand } from '@commands/ui/fetch-user-mandates.command';

@Injectable({ providedIn: 'root' })
export class CreateInvestmentPlanWithdrawTransferCommand implements ICommand {
  constructor(private readonly _fetchUserMandatesCommand: FetchUserMandatesCommand) {}

  public execute(action: InvestAction): Observable<undefined> {
    const observable$ =
      action.data.investmentPlan.contractStatus === EContractStatus.Virtual
        ? this._createVirtualWithdrawTransfer(action as CreateWithdrawInvestAction)
        : this._createWithdrawTransfer(action as CreateWithdrawInvestAction);

    return observable$.pipe(
      switchMap(() => {
        return this._fetchUserMandatesCommand.execute();
      })
    );
  }

  private _createVirtualWithdrawTransfer(action: CreateWithdrawInvestAction): Observable<any> {
    return createInvestmentPlanVirtualWithdrawTransfer({
      pathParams: { investmentPlanIdentifier: action.data.investmentPlan.identifier },
      payload: {
        amount: action.data.amount,
        paymentMethod: EPaymentMethod.Virtual,
        paymentTarget: EPaymentTarget.VirtualPortfolioManagement
      }
    }).execute();
  }

  private _createWithdrawTransfer(action: CreateWithdrawInvestAction): Observable<any> {
    return createInvestmentPlanWithdrawTransfer({
      pathParams: { investmentPlanIdentifier: action.data.investmentPlan.identifier },
      payload: {
        amount: action.data.amount
      }
    }).execute();
  }
}
