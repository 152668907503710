<div id="confirm-update-signed-mandate-notification-modal__container">
  <h3 class="title">
    {{'modals.confirm-update-signed-mandate-notification.title' | translate}}
  </h3>
  <p class="message">
    {{'modals.confirm-update-signed-mandate-notification.message' | translate}}
  </p>
  <div class="actions">
    <button nz-button nzType="primary" (click)="confirm()">
      {{'common.cta.understand' | translate}}
    </button>
  </div>
</div>
