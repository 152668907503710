import { Injectable } from '@angular/core';
import { ReferencesStoreService } from '@stores/references/references-store.service';
import { InvestmentPlanContractSynthesisResponseDTO } from '@backend/dto';
import { transaction } from '@datorama/akita';
import { EContractStatus, EMandatesUIState } from '@enums';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import {
  InvestmentPlan,
  InvestmentPlanAggregatedTNA,
  InvestmentPlanAllocation,
  InvestmentPlanSelectorItem,
  RecurrentDepositInvestmentPlan
} from './investment-plans.model';
import { InvestmentPlansQuery } from './investment-plans.query';
import { InvestmentPlansStore } from './investment-plans.store';
import { AppStoreService } from '@stores/app/app-store.service';

@Injectable({ providedIn: 'root' })
export class InvestmentPlansStoreService {
  constructor(
    private readonly _investmentPlansStore: InvestmentPlansStore,
    private readonly _investmentPlansQuery: InvestmentPlansQuery,
    private readonly _referencesStoreService: ReferencesStoreService,
    private readonly _appStoreService: AppStoreService
  ) {}

  get emptyVirtualInvestmentPlan$(): Observable<InvestmentPlan> {
    let investmentPlansTotalCount;
    return this.investmentPlansTotalCount$.pipe(
      switchMap((total) => {
        investmentPlansTotalCount = total;
        return this._investmentPlansQuery.selectAll({
          filterBy: [
            (investmentPlan) => investmentPlan.contract.status === EContractStatus.Virtual,
            (investmentPlan) => !investmentPlan.currentAmount
          ]
        });
      }),
      map((investmentPlans) => (investmentPlansTotalCount >= 2 && investmentPlans.length ? investmentPlans[0] : undefined))
    );
  }

  get firstEmptyVirtualInvestmentPlan$(): Observable<InvestmentPlan> {
    return this._investmentPlansQuery
      .selectAll({
        filterBy: [({ contract }) => contract.status === EContractStatus.Virtual, ({ currentAmount }) => !currentAmount]
      })
      .pipe(map((investmentPlans) => (investmentPlans.length ? investmentPlans[0] : undefined)));
  }

  get investmentPlansTotalCount$(): Observable<number> {
    return this._investmentPlansQuery.selectCount();
  }

  get signedInvestmentPlans$(): Observable<InvestmentPlan[]> {
    return this._investmentPlansQuery.selectAll({
      filterBy: ({ contract }) => contract.status === EContractStatus.Signed
    });
  }

  get pendingInvestmentPlans$(): Observable<InvestmentPlan[]> {
    return this._investmentPlansQuery.selectAll({
      filterBy: ({ contract }) => contract.status === EContractStatus.PendingSignature
    });
  }

  get virtualInvestmentPlans$(): Observable<InvestmentPlan[]> {
    return this._investmentPlansQuery.selectAll({
      filterBy: ({ contract }) => contract.status === EContractStatus.Virtual
    });
  }

  get virtualInvestmentPlanHasAmount$(): Observable<boolean> {
    return this.virtualInvestmentPlans$.pipe(
      map((investmentPlans) => !!investmentPlans.find((investmentPlan) => !!investmentPlan.currentAmount))
    );
  }

  get signedInvestmentPlansSelectorItems$(): Observable<InvestmentPlanSelectorItem[]> {
    return this._investmentPlansQuery
      .selectAll({
        filterBy: ({ contract }) => contract.status === EContractStatus.Signed
      })
      .pipe(
        map((investmentPlans) =>
          investmentPlans.map(
            (investmentPlan) =>
              ({
                identifier: investmentPlan.investmentPlanIdentifier,
                isVirtual: investmentPlan.isVirtual,
                contractName: investmentPlan.contractName,
                contractNameDetailList: investmentPlan.contractNameDetailList,
                contractStatus: investmentPlan.contract.status,
                colorClassName: investmentPlan.colorClassName,
                name: investmentPlan.name
              } as InvestmentPlanSelectorItem)
          )
        )
      );
  }

  get allInvestmentPlansSelectorItems$(): Observable<InvestmentPlanSelectorItem[]> {
    return this._investmentPlansQuery.selectAll().pipe(
      map((investmentPlans) =>
        investmentPlans.map(
          (investmentPlan) =>
            ({
              identifier: investmentPlan.investmentPlanIdentifier,
              isVirtual: investmentPlan.isVirtual,
              contractName: investmentPlan.contractName,
              contractNameDetailList: investmentPlan.contractNameDetailList,
              contractStatus: investmentPlan.contract.status,
              colorClassName: investmentPlan.colorClassName,
              name: investmentPlan.name
            } as InvestmentPlanSelectorItem)
        )
      )
    );
  }

  get investmentPlansInvestableSelectorItems$(): Observable<InvestmentPlanSelectorItem[]> {
    return this._investmentPlansQuery
      .selectAll({
        filterBy: ({ contract }) => contract.status !== EContractStatus.PendingSignature
      })
      .pipe(
        map((investmentPlans) =>
          investmentPlans.map(
            (investmentPlan) =>
              ({
                identifier: investmentPlan.investmentPlanIdentifier,
                isVirtual: investmentPlan.isVirtual,
                contractName: investmentPlan.contractName,
                contractNameDetailList: investmentPlan.contractNameDetailList,
                contractStatus: investmentPlan.contract.status,
                colorClassName: investmentPlan.colorClassName,
                name: investmentPlan.name,
                currentAmount: investmentPlan.currentAmount
              } as InvestmentPlanSelectorItem)
          )
        )
      );
  }

  get investmentPlansInvestableSelectorItemsWithoutRecurrentDeposit$(): Observable<InvestmentPlanSelectorItem[]> {
    return this._investmentPlansQuery
      .selectAll({
        filterBy: [({ contract }) => contract.status !== EContractStatus.PendingSignature, ({ recurrentDeposit }) => !recurrentDeposit]
      })
      .pipe(
        map((investmentPlans) =>
          investmentPlans.map(
            (investmentPlan) =>
              ({
                identifier: investmentPlan.investmentPlanIdentifier,
                isVirtual: investmentPlan.isVirtual,
                contractName: investmentPlan.contractName,
                contractStatus: investmentPlan.contract.status,
                contractNameDetailList: investmentPlan.contractNameDetailList,
                colorClassName: investmentPlan.colorClassName,
                name: investmentPlan.name
              } as InvestmentPlanSelectorItem)
          )
        )
      );
  }

  get investmentPlansWithRecurrentDepositAmount$(): Observable<RecurrentDepositInvestmentPlan[]> {
    return combineLatest([
      this._investmentPlansQuery.selectAll({
        filterBy: ({ recurrentDeposit }) => !!recurrentDeposit
      }),
      this._referencesStoreService.frequencies$
    ]).pipe(
      map(([investmentPlans, frequencies]) => {
        return investmentPlans.map((investmentPlan) => {
          return {
            identifier: investmentPlan.investmentPlanIdentifier,
            isVirtual: investmentPlan.isVirtual,
            contractName: investmentPlan.contractName,
            contractStatus: investmentPlan.contract.status,
            currentAmount: investmentPlan.currentAmount,
            recurrentDeposit: investmentPlan.recurrentDeposit,
            frequency: frequencies.find((frequency) => investmentPlan.recurringFrequencyTextId === frequency.textId),
            nextRecurrenceDate: investmentPlan.nextRecurrenceDate,
            colorClassName: investmentPlan.colorClassName,
            name: investmentPlan.name
          } as RecurrentDepositInvestmentPlan;
        });
      })
    );
  }

  get hasInvestmentPlans$(): Observable<boolean> {
    return this._investmentPlansQuery
      .selectCount(({ contract, recurrentDeposit }) => {
        return (contract.status === EContractStatus.Virtual && !recurrentDeposit) || contract.status === EContractStatus.Signed;
      })
      .pipe(map((count) => !!count));
  }

  get isStoreUpdated$(): Observable<boolean> {
    return this._investmentPlansQuery.select((state) => state.updated);
  }

  @transaction()
  public addOrUpdateInvestmentPlans(data: InvestmentPlan[]): void {
    this._investmentPlansStore.remove();
    this._investmentPlansStore.add(data);
    this._investmentPlansStore.update({ updated: true });
    this._updateMandatesUIState();
  }

  public getInvestmentPlanById(investmentPlanIdentifier: string): InvestmentPlan {
    return this._investmentPlansQuery.getEntity(investmentPlanIdentifier);
  }

  public getInvestmentPlanById$(investmentPlanIdentifier: string): Observable<InvestmentPlan> {
    return this._investmentPlansQuery.selectEntity(investmentPlanIdentifier);
  }

  public updateInvestmentPlanContractSynthesis(
    investmentPlanIdentifier: string,
    contractSynthesis: InvestmentPlanContractSynthesisResponseDTO
  ): void {
    this._investmentPlansStore.update(investmentPlanIdentifier, { contractSynthesis });
  }

  public updateInvestmentPlanAllocation(investmentPlanIdentifier: string, allocation: InvestmentPlanAllocation): void {
    this._investmentPlansStore.update(investmentPlanIdentifier, { allocation });
  }

  public updateInvestmentPlanAggregatedTna(investmentPlanIdentifier: string, aggregatedTna: InvestmentPlanAggregatedTNA): void {
    this._investmentPlansStore.update(investmentPlanIdentifier, { aggregatedTna });
  }

  private _updateMandatesUIState(): void {
    combineLatest([
      this.signedInvestmentPlans$,
      this.pendingInvestmentPlans$,
      this.virtualInvestmentPlans$,
      this.virtualInvestmentPlanHasAmount$,
      this.investmentPlansTotalCount$
    ])
      .pipe(take(1))
      .subscribe(
        ([
          signedInvestmentPlans,
          pendingSignatureInvestmentPlans,
          virtualInvestmentPlans,
          virtualInvestmentPlanHasAmount,
          investmentPlansTotalCount
        ]) => {
          if (!!signedInvestmentPlans?.length || (virtualInvestmentPlanHasAmount && !!virtualInvestmentPlans?.length)) {
            this._appStoreService.updateMandatesUIState(EMandatesUIState.PerformanceChart);
            return;
          }

          if (
            !investmentPlansTotalCount ||
            (!!pendingSignatureInvestmentPlans?.length && !virtualInvestmentPlans?.length && !signedInvestmentPlans?.length)
          ) {
            this._appStoreService.updateMandatesUIState(EMandatesUIState.Welcome);
            return;
          }

          this._appStoreService.updateMandatesUIState(EMandatesUIState.FirstInvest);
        }
      );
  }
}
