import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AnalyticsState } from './analytics.state';

export function createAnalyticsInitialState(): AnalyticsState {
  return {
    stepNumber: 1,
    steps: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'analytics', resettable: true })
export class AnalyticsStore extends Store<AnalyticsState> {
  constructor() {
    super({
      ...createAnalyticsInitialState(),
      macroSteps: []
    });
  }
}
