import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ICommand } from '@commands/interfaces';

@Injectable({ providedIn: 'root' })
export class NavigateToCommand implements ICommand {
  constructor(private readonly _router: Router) {}

  public execute(data: { commands: any[]; extras?: NavigationExtras }): void {
    this._router.navigate(data.commands, data.extras);
  }
}
