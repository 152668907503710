import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TokenState } from './token.state';
import { TokenStore } from './token.store';

@Injectable({ providedIn: 'root' })
export class TokenQuery extends Query<TokenState> {
  constructor(protected tokenStore: TokenStore) {
    super(tokenStore);
  }
}
