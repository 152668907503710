import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { selectPersistStateInit } from '@datorama/akita';
import { TokenStoreService } from '@stores';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticateGuard implements CanActivate {
  constructor(
    private readonly _tokenStoreService: TokenStoreService,
    private readonly _router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([this._tokenStoreService.isLogged$, selectPersistStateInit()]).pipe(
      first(),
      map(([isLogged]) => {
        if (isLogged) {
          return true;
        }
        this._router.navigate(['webclient', 'authentication']);
        return false;
      })
    );
  }
}
