import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EligibilityStatus } from '@app/types';
import { ICommand } from '@commands/interfaces';
import { UserOnboardingStatusService } from '@services/user-onboarding-status.service';
import { EligibilityStoreService } from '@stores';

@Injectable({ providedIn: 'root' })
export class NavigateToEligibilityPageCommand implements ICommand {
  constructor(
    private readonly _router: Router,
    private readonly _eligibilityStoreService: EligibilityStoreService,
    private readonly _userOnboardingStatusService: UserOnboardingStatusService
  ) {}

  public execute(data?: { updateUserOnboardingStatus?: boolean; isUserOnboarded?: boolean }): void {
    if (data?.updateUserOnboardingStatus) {
      return this._updateUserOnboardingStatus();
    }

    this._handleNavigation(data?.isUserOnboarded);
  }

  private _updateUserOnboardingStatus(): void {
    this._userOnboardingStatusService
      .updateUserOnboardingStatus()
      .subscribe((isUserOnboarded) => this._handleNavigation(isUserOnboarded));
  }

  private _handleNavigation(isUserOnboarded: boolean): void {
    if (isUserOnboarded) {
      this._router.navigate(['webclient', 'mandates']);
      return;
    }

    this._eligibilityStoreService.state$.subscribe((state) => {
      this._navigateToEligibilityPage(state.isEligible ? 'success' : 'fail');
    });
  }

  private _navigateToEligibilityPage(status: EligibilityStatus): void {
    this._router.navigate(['webclient', 'eligibility', status]);
  }
}
