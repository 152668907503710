import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { InvestmentPlanAggregatedTNAResponseDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlanAggregatedTnaRequest extends Request<
  never,
  InvestmentPlanAggregatedTNAResponseDTO
> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      path: `${options?.version ?? '/v1'}/users/me/investment_plans/${
        options?.pathParams?.investmentPlanIdentifier
      }/aggregated_TNA`
    });
  }

  public execute(): Observable<InvestmentPlanAggregatedTNAResponseDTO> {
    return super
      .execute()
      .pipe(map((response: HttpResponse<InvestmentPlanAggregatedTNAResponseDTO>) => response.body));
  }
}

export function getInvestmentPlanAggregatedTNA(
  options?: RequestOptions<never>
): InvestmentPlanAggregatedTnaRequest {
  return new InvestmentPlanAggregatedTnaRequest(options);
}
