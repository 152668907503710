import { Injectable } from '@angular/core';
import { DimensionProfileCode } from '@app/types';
import { AnalyticsQworkflow } from '@app/types/analytics.type';
import { EContractStatus, EMimeType } from '@enums';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';

@Injectable({ providedIn: 'root' })
export class HelpersService {
  constructor(private readonly _translateService: TranslateService) {}

  public getQworkflowQuestionTrackingData(data: any): AnalyticsQworkflow {
    let questionText;
    const header = data.container.children.find((item) => item.type === 'text' && item.textType === 'header');
    let question = data.container.children.find((item) => item.type === 'question');

    if (!question) {
      const container = data.container.children.find((item) => item.type === 'container');
      question = container.children.find((item) => item.type === 'question');
    }

    questionText = question?.children.find((item) => item.type === 'text' && item.textType === 'question');

    return {
      title: header?.value.toLowerCase(),
      textId: question?.formGroupID,
      question: questionText?.value
    };
  }

  public getDataAsBlob(data: any, fileType: string = EMimeType.pdf): Blob {
    return new Blob([data], { type: fileType });
  }

  public getFilenameFromContentDispositionHeader(contentDisposition: string): string {
    return contentDisposition.split(';')[1].trim().split('=')[1].replace(/"/g, '');
  }

  public saveFileAs({ blobData, fileType, fileName }: { blobData: any; fileType: string; fileName: string }): void {
    FileSaver.saveAs(new Blob([blobData], { type: fileType }), fileName);
  }

  public getInvestmentPlanManagementOrientationName(dimensionProfileCode: DimensionProfileCode): string {
    return this._translateService.instant(`investment-plan.dimensions.profile.${dimensionProfileCode}`);
  }

  public getInvestmentPlanContractName(
    type: EContractStatus,
    contractNumber: string,
    productName: string,
    needContractNameForDetaiList = true
  ): string {
    switch (type) {
      case EContractStatus.Signed:
        return this._getLifeInsuranceContractName(contractNumber, productName, needContractNameForDetaiList);
      case EContractStatus.PendingSignature:
        return contractNumber.includes('Account')
          ? this._getPendingContractName()
          : this._getLifeInsuranceContractName(contractNumber, productName, needContractNameForDetaiList);
      case EContractStatus.Virtual:
        return contractNumber.toUpperCase();
      default:
        return this._getPendingContractName();
    }
  }

  private _slicedContractNumber(contractNumber: string): string {
    return contractNumber.slice(contractNumber.length - 5);
  }

  private _getLifeInsuranceContractName(contractNumber: string, productName: string, needContractNameForDetaiList: boolean): string {
    return (
      this._translateService.instant(`investment-plan${needContractNameForDetaiList ? '.contract' : ''}.life-insurance.label`, {
        productName
      }) + this._slicedContractNumber(contractNumber)
    );
  }

  private _getPendingContractName(): string {
    return this._translateService.instant('investment-plan.contract.pending.label');
  }
}
