import { Injectable } from '@angular/core';
import { UserStoreService } from '@stores/user/user-store.service';
import { AccessControlFunction } from '@app/types';
import { NzModalService } from 'ng-zorro-antd';
import { FeatureAccessDeniedModal } from '@modules/modals';

@Injectable({ providedIn: 'root' })
export class AccessControlService {
  constructor(
    private readonly _userStoreService: UserStoreService,
    private readonly _nzModalService: NzModalService
  ) {}

  public canPerformAction(
    accessControlFunction: AccessControlFunction | AccessControlFunction[]
  ): boolean {
    if (Array.isArray(accessControlFunction)) {
      const canPerformAction = accessControlFunction
        .map((fn) => this._userStoreService.hasAccessControlFunction(fn))
        .some((value) => value);

      if (canPerformAction) {
        return true;
      }
    } else if (
      this._userStoreService.hasAccessControlFunction(
        accessControlFunction as AccessControlFunction
      )
    ) {
      return true;
    }

    this._nzModalService.create({
      nzClassName: 'feature-access-denied-modal',
      nzContent: FeatureAccessDeniedModal,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false
    });

    return false;
  }
}
