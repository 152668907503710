import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ApplicationConfigurationStore } from './application-configuration.store';
import { ApplicationConfigurationState } from './application-configuration.state';

@Injectable({ providedIn: 'root' })
export class ApplicationConfigurationQuery extends Query<ApplicationConfigurationState> {
  constructor(protected applicationConfigurationStore: ApplicationConfigurationStore) {
    super(applicationConfigurationStore);
  }
}
