<div id="confirm-subscription-notification-modal__container">
  <h3 class="title">
    {{'modals.confirm-subscription-notification.title' | translate}}
  </h3>
  <p class="message">
    {{'modals.confirm-subscription-notification.message' | translate}}
  </p>
  <div class="actions">
    <button nz-button nzType="primary" (click)="confirmSubscription()">
      {{'common.cta.confirm' | translate}}
    </button>
  </div>
</div>
