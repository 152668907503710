import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AggregatedPerformanceState } from './aggregated-performance.state';

function createInitialState(): AggregatedPerformanceState {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'aggregated-performance' })
export class AggregatedPerformanceStore extends Store<AggregatedPerformanceState> {
  constructor() {
    super(createInitialState());
  }
}
