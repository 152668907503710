import { Injectable } from '@angular/core';
import {
  InvestmentPlanAllocationPerformanceResponseDTO,
  InvestmentPlanAllocationResponseDTO
} from '@backend/dto';
import {
  getInvestmentPlanAllocation,
  getInvestmentPlanAllocationPerformance
} from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { environment } from '@environments/environment';
import { InvestmentPlan, InvestmentPlanAllocation, InvestmentPlansStoreService } from '@stores';
import { combineLatest, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GetUserInvestmentPlanAllocationCommand implements ICommand {
  constructor(private readonly _investmentPlansStoreService: InvestmentPlansStoreService) {}

  public execute(
    investmentPlan: InvestmentPlan
  ): Observable<
    [InvestmentPlanAllocationResponseDTO, InvestmentPlanAllocationPerformanceResponseDTO]
  > {
    return combineLatest([
      getInvestmentPlanAllocation({
        pathParams: { investmentPlanIdentifier: investmentPlan.investmentPlanIdentifier }
      }).execute(),
      !investmentPlan.isVirtual
        ? getInvestmentPlanAllocationPerformance({
            pathParams: { investmentPlanIdentifier: investmentPlan.investmentPlanIdentifier }
          }).execute()
        : of(undefined)
    ]).pipe(
      tap(([allocation, allocationPerformance]) => {
        const investmentPlanAllocation = allocation as InvestmentPlanAllocation;

        investmentPlanAllocation.individualAssets = investmentPlanAllocation.individualAssets.map(
          (individualAsset, index) => {
            const individualAssetPerformance = allocationPerformance?.broadAllocationPerformanceModel?.find(
              (item) => item.ticker === individualAsset.allocationTextId
            );

            if (individualAssetPerformance) {
              individualAsset = {
                ...individualAsset,
                performance: individualAssetPerformance.performance,
                amount: individualAssetPerformance.amount
              };
            }

            individualAsset.color =
              environment.chartColorsPalette[index % environment.chartColorsPalette.length];

            return individualAsset;
          }
        );

        this._investmentPlansStoreService.updateInvestmentPlanAllocation(
          investmentPlan.investmentPlanIdentifier,
          investmentPlanAllocation
        );
      })
    );
  }
}
