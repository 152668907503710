import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { TokenState } from './token.state';

function createInitialState(): TokenState {
  return {
    app: null,
    apim: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'token', resettable: true })
export class TokenStore extends Store<TokenState> {
  constructor() {
    super(createInitialState());
  }
}
