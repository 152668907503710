import { Injectable } from '@angular/core';
import { EErrorCodes } from '@enums';
import { EligibilityState } from './eligibility.state';
import { Observable } from 'rxjs';
import { EligibilityQuery } from './eligibility.query';
import { EligibilityStore } from './eligibility.store';

@Injectable({ providedIn: 'root' })
export class EligibilityStoreService {
  constructor(
    private readonly _eligibilityStore: EligibilityStore,
    private readonly _eligibilityQuery: EligibilityQuery
  ) {}

  get state$(): Observable<EligibilityState> {
    return this._eligibilityQuery.select();
  }

  get errorCode$(): Observable<EErrorCodes> {
    return this._eligibilityQuery.select((state) => state.errorCode);
  }

  public updateEligibilityStatus(isEligible: boolean): void {
    this._eligibilityStore.update((state) => ({ ...state, isEligible }));
  }

  public updateEligibilityError(errorCode: EErrorCodes): void {
    this._eligibilityStore.update((state) => ({
      ...state,
      errorCode
    }));
  }
}
