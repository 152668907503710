import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class GetImageRequest extends Request<never, string> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      format: 'blob',
      path: `/questionnaire${options?.version ?? '/v1'}/resources/${
        options?.pathParams?.resourceIdentifier
      }`
    });
  }

  public execute(): Observable<string> {
    return super.execute().pipe(map((response: HttpResponse<string>) => response.body));
  }
}

export function getImage(options?: RequestOptions<never>): GetImageRequest {
  return new GetImageRequest(options);
}
