import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { SubscriptionFlowBlockedResponseDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class SubscriptionFlowBlockedStatusRequest extends Request<
  never,
  SubscriptionFlowBlockedResponseDTO
> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      path: `/broad${options?.version ?? '/v1'}/${
        options?.pathParams?.investmentPlanIdentifier
      }/is_flow_blocked`
    });
  }

  public execute(): Observable<SubscriptionFlowBlockedResponseDTO> {
    return super
      .execute()
      .pipe(map((response: HttpResponse<SubscriptionFlowBlockedResponseDTO>) => response.body));
  }
}

export function getSubscriptionFlowBlockedStatus(
  options?: RequestOptions<never>
): SubscriptionFlowBlockedStatusRequest {
  return new SubscriptionFlowBlockedStatusRequest(options);
}
