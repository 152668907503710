import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { SimulationCashFlowState } from './simulation-cash-flow.state';

export function createInitialState(): SimulationCashFlowState {
  return {
    matrix: {
      medianScenario: [],
      upperBestScenario: [],
      lowerBestScenario: [],
      upperWorstScenario: [],
      lowerWorstScenario: []
    },
    projectionParameters: {
      investedAmount: 1000
    },
    probability: null,
    chartPoints: [],
    advices: [],
    lookingForwardBackendServiceNotWorking: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'simulationCashFlow' })
export class SimulationCashFlowStore extends Store<SimulationCashFlowState> {
  constructor() {
    super(createInitialState());
  }
}
