import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AggregatedPerformanceStore } from './aggregated-performance.store';
import { AggregatedPerformanceState } from './aggregated-performance.state';

@Injectable({ providedIn: 'root' })
export class AggregatedPerformanceQuery extends Query<AggregatedPerformanceState> {
  constructor(protected applicationConfigurationStore: AggregatedPerformanceStore) {
    super(applicationConfigurationStore);
  }
}
