import { Injectable } from '@angular/core';
import { cleanInvestmentPlanQuestionnaireSession } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CleanInvestmentPlanQuestionnaireSessionCommand implements ICommand {
  public execute(investmentPlanIdentifier: string): Observable<void> {
    return cleanInvestmentPlanQuestionnaireSession({
      pathParams: { investmentPlanIdentifier }
    }).execute();
  }
}
