import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { UserInvestmentPlansHistoricalPerformanceStoredResponseDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlansHistoricalPerformanceStoredRequest extends Request<
  never,
  UserInvestmentPlansHistoricalPerformanceStoredResponseDTO[]
> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      path: `${options?.version ?? '/v3'}/users/me/investment_plans/historical_performance_stored`
    });
  }

  public execute(): Observable<UserInvestmentPlansHistoricalPerformanceStoredResponseDTO[]> {
    return super
      .execute()
      .pipe(
        map(
          (response: HttpResponse<UserInvestmentPlansHistoricalPerformanceStoredResponseDTO[]>) =>
            response.body
        )
      );
  }
}

export function getInvestmentPlansHistoricalPerformanceStored(
  options?: RequestOptions<never>
): InvestmentPlansHistoricalPerformanceStoredRequest {
  return new InvestmentPlansHistoricalPerformanceStoredRequest(options);
}
