import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';

class DownloadInvestmentPlansReportRequest extends Request<never, HttpResponse<string>> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      format: 'blob',
      path: `/broad${options?.version ?? '/v1'}/me/investment_plans/report?documentId=${
        options?.pathParams?.documentId
      }`
    });
  }

  public execute(): Observable<HttpResponse<string>> {
    return super.execute();
  }
}

export function getInvestmentPlansReport(
  options?: RequestOptions<never>
): DownloadInvestmentPlansReportRequest {
  return new DownloadInvestmentPlansReportRequest(options);
}
