import { Injectable } from '@angular/core';
import { ApplicationConfigurationState } from './application-configuration.state';
import { Observable } from 'rxjs';
import { ApplicationConfigurationQuery } from './application-configuration.query';
import { ApplicationConfigurationStore } from './application-configuration.store';

@Injectable({ providedIn: 'root' })
export class ApplicationConfigurationStoreService {
  constructor(
    private readonly _applicationConfigurationStore: ApplicationConfigurationStore,
    private readonly _applicationConfigurationQuery: ApplicationConfigurationQuery
  ) {}

  get state$(): Observable<ApplicationConfigurationState> {
    return this._applicationConfigurationQuery.select();
  }

  get state(): ApplicationConfigurationState {
    return this._applicationConfigurationQuery.getValue();
  }

  public update(data: ApplicationConfigurationState): void {
    this._applicationConfigurationStore.update((state) => ({
      ...state,
      ...data
    }));
  }
}
