import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { QworkflowSessionRequestDTO, QworkflowSessionResponseDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class QworkflowSessionRequest extends Request<
  QworkflowSessionRequestDTO,
  QworkflowSessionResponseDTO
> {
  constructor(options?: RequestOptions<QworkflowSessionRequestDTO>) {
    super({
      ...options,
      method: 'POST',
      path: `/broad${options?.version ?? '/v1'}/module_questionnaires/questionnaire_session`,
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

  public execute(): Observable<QworkflowSessionResponseDTO> {
    return super
      .execute()
      .pipe(map((response: HttpResponse<QworkflowSessionResponseDTO>) => response.body));
  }
}

export function getQworkflowSession(
  options?: RequestOptions<QworkflowSessionRequestDTO>
): QworkflowSessionRequest {
  return new QworkflowSessionRequest(options);
}
