import { HttpClient, HttpHeaders } from '@angular/common/http';
import { appInjector } from '@app/app.injector';
import { HttpMethod, Parameters, RequestOptions, ResponseFormat } from '@app/types';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

export abstract class Request<RequestDTO, ResponseDTO> {
  private _headers: HttpHeaders;

  private readonly _format: ResponseFormat;
  private readonly _method: HttpMethod;
  private readonly _path: string;
  private readonly _httpClient: HttpClient;
  private readonly _basePath = '/api';
  private readonly _payload: RequestDTO;
  private readonly _queryParams: Parameters;

  protected constructor(options?: RequestOptions<RequestDTO>) {
    this._httpClient = appInjector().get(HttpClient);
    this._queryParams = options?.queryParams;
    this._payload = options?.payload;
    this._format = options?.format ?? 'json';
    this._method = options?.method ?? 'GET';
    this._headers = options?.headers ?? new HttpHeaders();
    this._path = options?.path ?? '';

    if (options.ignoreLoader) {
      this._headers = this._headers.append('x-ignore-loader', 'true');
    }
  }

  public execute(): Observable<any> {
    const url = this._basePath + this._path + this._queryString();

    switch (this._format) {
      case 'blob':
        return this._httpClient
          .request(this._method, url, {
            body: this._payload,
            headers: this._headers,
            observe: 'response',
            responseType: 'blob'
          })
          .pipe(first());
      case 'json':
        return this._httpClient
          .request<ResponseDTO>(this._method, url, {
            body: this._payload,
            headers: this._headers,
            observe: 'response',
            responseType: 'json'
          })
          .pipe(first());
    }
  }

  private _queryString(): string {
    if (!this._queryParams) {
      return '';
    }

    let queryString;

    queryString = Object.keys(this._queryParams)
      .map((key: string) => `${key}=${this._queryParams[key]}`)
      .join('&');

    if (queryString && queryString.length) {
      queryString = '?' + queryString;
    }

    return queryString;
  }
}
