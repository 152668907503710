import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CleanInvestmentPlanQuestionnaireSessionCommand } from '@commands/backend/clean-investment-plan-questionnaire-session.command';
import { ICommand } from '@commands/interfaces';
import { NavigateToCommand } from '@commands/ui/navigate-to.command';
import { AnalyticsStoreService, OnboardingStoreService, InvestmentPlansStoreService } from '@stores';
import { GetSynthesisCommand } from '../backend';

@Injectable({ providedIn: 'root' })
export class StartSubscriptionProcessCommand implements ICommand {
  constructor(
    private readonly _onboardingStoreService: OnboardingStoreService,
    private readonly _investmentPlanStoreService: InvestmentPlansStoreService,
    private readonly _getSynthesisCommand: GetSynthesisCommand,
    private readonly _router: Router,
    private readonly _cleanInvestmentPlanQuestionnaireSessionCommand: CleanInvestmentPlanQuestionnaireSessionCommand,
    private readonly _analyticsStoreService: AnalyticsStoreService
  ) {}

  public execute(investmentPlanIdentifier: string): void {
    this._onboardingStoreService.resetStore();
    this._getSynthesisCommand.resetSynthesisBehaviorSubject();
    this._analyticsStoreService.resetStore();
    this._onboardingStoreService.setNewInitialState({
      investmentPlanIdentifier,
      investmentPlanToUpdate: this._investmentPlanStoreService.getInvestmentPlanById(investmentPlanIdentifier),
      isVirtual: false,
      onboardingType: 'create',
      subscriptionStarted: true,
      subscriptionCompleted: false
    });
    this._cleanInvestmentPlanQuestionnaireSessionCommand.execute(investmentPlanIdentifier).subscribe(() => {
      this._router.navigate(['webclient', 'subscription', 'questionnaire']);
    });
  }
}
