import { Injectable } from '@angular/core';
import { QworkflowSessionResponseDTO } from '@backend/dto';
import { getQworkflowSession } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { Observable } from 'rxjs';
import { OnboardingType } from '@src/app/stores';

@Injectable({ providedIn: 'root' })
export class GetQworkflowSessionCommand implements ICommand {
  public execute(data?: { planIdentifier: string; onboardingType: OnboardingType }): Observable<QworkflowSessionResponseDTO> {
    return getQworkflowSession({
      payload: {
        LinkedExternalId: data.planIdentifier,
        LinkedExternalIdType: 'InvestmentPlan',
        skipRiskProfile: data.onboardingType === 'update-preference'
      }
    }).execute();
  }
}
