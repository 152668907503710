import { Injectable } from '@angular/core';
import { ArbitrageMovementReportDTO } from '@backend/dto';
import { transaction } from '@datorama/akita';
import { Document } from './documents.model';
import { Observable } from 'rxjs';
import { DocumentsQuery } from './documents.query';
import { DocumentsStore } from './documents.store';

@Injectable({ providedIn: 'root' })
export class DocumentsStoreService {
  constructor(
    private readonly _documentsStore: DocumentsStore,
    private readonly _documentsQuery: DocumentsQuery
  ) {}

  get documents$(): Observable<Document[]> {
    return this._documentsQuery.selectAll();
  }

  @transaction()
  public resetWithNewValues(data: ArbitrageMovementReportDTO[]): void {
    this._documentsStore.remove();
    this._documentsStore.add(data);
  }
}
