import { Injectable } from '@angular/core';
import { getImage } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GetImageCommand implements ICommand {
  public execute(identifier: string): Observable<string> {
    return getImage({ pathParams: { resourceIdentifier: identifier } }).execute();
  }
}
