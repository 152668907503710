import { Injectable } from '@angular/core';
import { InvestAction, NotificationParams } from '@app/types';
import { AnalyticsDepositInteraction, AnalyticsRecurringDepositInteraction, AnalyticsTrackObject } from '@app/types/analytics.type';
import { ICommand } from '@commands/interfaces';
import { FetchUserMandatesCommand } from '@commands/ui/fetch-user-mandates.command';
import { ShowAdvisorNotificationModalCommand } from '@commands/ui/show-advisor-notification-modal.command';
import { EContractStatus, EInvestActionType } from '@enums';
import { InvestmentPlanDepositModal } from '@modules/invest/modals/investment-plan-deposit/investment-plan-deposit.modal';
import { AmountPipe } from '@modules/shared/pipes';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '@services/analytics.service';
import { ToastNotificationService } from '@services/toast-notification.service';
import { InvestmentPlan, RecurrentDepositInvestmentPlan } from '@stores';
import { NzModalService } from 'ng-zorro-antd';
import { combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { StartSubscriptionProcessCommand } from '@commands/ui/start-subscription-process.command';

@Injectable({ providedIn: 'root' })
export class ShowInvestmentPlanDepositModalCommand implements ICommand {
  constructor(
    private readonly _nzModalService: NzModalService,
    private readonly _fetchUserMandatesCommand: FetchUserMandatesCommand,
    private readonly _showAdvisorNotificationModalCommand: ShowAdvisorNotificationModalCommand,
    private readonly _toastNotificationService: ToastNotificationService,
    private readonly _translateService: TranslateService,
    private readonly _amountPipe: AmountPipe,
    private readonly _analyticsService: AnalyticsService,
    private readonly _startSubscriptionProcessCommand: StartSubscriptionProcessCommand
  ) {}

  public execute(investmentPlan: InvestmentPlan): void {
    if (!investmentPlan) {
      return;
    }

    const modal = this._nzModalService.create<InvestmentPlanDepositModal>({
      nzFooter: null,
      nzContent: InvestmentPlanDepositModal,
      nzClassName: 'investment-plan-deposit-modal',
      nzComponentParams: {
        investmentPlan
      }
    });

    modal.afterClose
      .pipe(
        switchMap((result: InvestAction) => {
          return result ? combineLatest([of(result), this._fetchUserMandatesCommand.execute()]) : of();
        })
      )
      .subscribe(([result]) => {
        const trackPageObject: AnalyticsTrackObject = { type: 'interaction' };

        if (result) {
          if (investmentPlan.contract.status === EContractStatus.Signed) {
            this._showAdvisorNotificationModalCommand.execute();

            switch (result.type) {
              case EInvestActionType.CreateDeposit:
                trackPageObject.interaction = {
                  type: 'deposit',
                  data: {
                    amount: result.data.amount,
                    contractStatus: 'reel'
                  } as AnalyticsDepositInteraction
                };
                break;
              case EInvestActionType.UpdateRecurrentDeposit:
                trackPageObject.interaction = {
                  type: 'add-recurring-deposit',
                  data: {
                    amount: result.data.amount,
                    contractStatus: 'reel',
                    frequenceVir: result.data.frequency.label
                  } as AnalyticsRecurringDepositInteraction
                };
                break;
            }

            this._analyticsService.track(trackPageObject);
          } else {
            let params: NotificationParams;

            switch (result.type) {
              case EInvestActionType.CreateDeposit:
                params = {
                  title: this._translateService.instant('common.notifications.transactions.success.create-deposit.title'),
                  message: this._translateService.instant('common.notifications.transactions.success.create-deposit.message', {
                    amount: this._amountPipe.transform(result.data.amount, '€'),
                    className: investmentPlan.contract.status.toLowerCase(),
                    contractName: investmentPlan.name
                  })
                };
                trackPageObject.interaction = {
                  type: 'deposit',
                  data: {
                    amount: result.data.amount,
                    contractStatus: 'virtuel'
                  } as AnalyticsDepositInteraction
                };
                this._analyticsService.track(trackPageObject);
                break;
              case EInvestActionType.UpdateRecurrentDeposit:
                params = {
                  title: this._translateService.instant('common.notifications.transactions.success.create-recurrent-deposit.title'),
                  message: this._translateService.instant('common.notifications.transactions.success.create-recurrent-deposit.message')
                };

                const resultData = result.data as RecurrentDepositInvestmentPlan;

                if (!resultData.recurrentDeposit) {
                  trackPageObject.interaction = {
                    type: 'add-recurring-deposit',
                    data: {
                      amount: result.data.amount,
                      contractStatus: 'virtuel',
                      frequenceVir: result.data.frequency.label
                    } as AnalyticsRecurringDepositInteraction
                  };
                } else {
                  trackPageObject.interaction = {
                    type: 'update-recurring-deposit',
                    data: {
                      amount: result.data.amount,
                      contractStatus: 'virtuel',
                      frequenceVir: result.data.frequency.label
                    } as AnalyticsRecurringDepositInteraction
                  };
                }

                this._analyticsService.track(trackPageObject);
                break;
              case EInvestActionType.CreateSubscription:
                this._startSubscriptionProcessCommand.execute(result.data.investmentPlan.investmentPlanIdentifier);
                break;
            }

            if (params) {
              this._toastNotificationService.success(params);
            }
          }
        }
      });
  }
}
