import { EContractStatus } from '@enums';
import { InvestmentPlan } from '@stores/investment-plans/investment-plans.model';

export class InvestmentPlanSummary {
  public amount: number;
  public startDate: string;
  public identifier: string;
  public managementOrientation: string;
  public earningsInPercentage: number;
  public earningsInPercentageSign: string;
  public contractName: string;
  public contractStatus: EContractStatus;
  public colorClassName: string;
  public investmentPlanType: string;
  public tagNameKey: string;
  public isVirtual: boolean;

  constructor(investmentPlan: InvestmentPlan) {
    this.amount = investmentPlan.currentAmount;
    this.startDate = investmentPlan.contract.startDate;
    this.identifier = investmentPlan.investmentPlanIdentifier;
    this.managementOrientation = investmentPlan.managementOrientation;
    this.earningsInPercentage = investmentPlan.earningsInPercentage;
    this.contractName = investmentPlan.contractName;
    this.contractStatus = investmentPlan.contract.status;
    this.colorClassName = investmentPlan.colorClassName;
    this.investmentPlanType = investmentPlan.planTypeTextId;
    this.tagNameKey = investmentPlan.tagNameKey;
    this.isVirtual = investmentPlan.isVirtual;

    if (investmentPlan.earningsInPercentage && investmentPlan.earningsInPercentage > 0) {
      this.earningsInPercentageSign = '+';
    }
  }
}
