import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AppState } from './app.state';
import { EMandatesUIState } from '@enums';

function createInitialState(): AppState {
  return {
    mandatesUIState: EMandatesUIState.Empty
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'app' })
export class AppStore extends Store<AppState> {
  constructor() {
    super(createInitialState());
  }
}
