import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { OperationsState } from './operations.model';
import { OperationsStore } from './operations.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'date',
  sortByOrder: Order.DESC
})
export class OperationsQuery extends QueryEntity<OperationsState> {
  constructor(protected operationsStore: OperationsStore) {
    super(operationsStore);
  }
}
