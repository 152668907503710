import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenApimAuthResponseDTO, TokenAuthResponseDTO } from '@backend/dto';
import { getToken, getTokenApim } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { NavigateToEligibilityPageCommand } from '@commands/ui/navigate-to-eligibility-page.command';
import { EErrorCodes, EGrantType } from '@enums';
import { AnalyticsService } from '@services/analytics.service';
import { UserOnboardingStatusService } from '@services/user-onboarding-status.service';
import { AnalyticsStoreService, ApplicationConfigurationStoreService, EligibilityStoreService, TokenStoreService } from '@stores';
import { switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class AuthenticateUserCommand implements ICommand {
  constructor(
    private readonly _applicationConfigurationStoreService: ApplicationConfigurationStoreService,
    private readonly _tokenStoreService: TokenStoreService,
    private readonly _userOnboardingStatus: UserOnboardingStatusService,
    private readonly _eligibilityStoreService: EligibilityStoreService,
    private readonly _navigateToEligibilityPageCommand: NavigateToEligibilityPageCommand,
    private readonly _analyticsStoreService: AnalyticsStoreService,
    private readonly _analyticsService: AnalyticsService
  ) {}

  public execute(code: string): void {
    this._analyticsStoreService.resetStore();
    this._applicationConfigurationStoreService.state$
      .pipe(
        switchMap((state) => {
          return getTokenApim({
            payload: {
              code,
              grant_type: EGrantType.AuthorizationCode,
              redirect_uri: `${window.location.protocol}//${window.location.host}/webclient/authentication`
            }
          }).execute();
        }),
        switchMap((response: TokenApimAuthResponseDTO) => {
          this._tokenStoreService.updateApimState(response);
          return getToken({
            payload: new HttpParams({
              fromObject: {
                grant_type: EGrantType.BroadCodeGrant,
                id_token: response.id_token
              }
            })
          }).execute();
        }),
        switchMap((response: TokenAuthResponseDTO) => {
          this._tokenStoreService.updateAppState(response);
          this._analyticsStoreService.setCustomerInfo({
            customerRole: 'client',
            customerId: uuidv4()
          });
          return this._userOnboardingStatus.updateUserOnboardingStatus();
        })
      )
      .subscribe(
        (isUserOnboarded: boolean) => {
          this._eligibilityStoreService.updateEligibilityStatus(true);
          this._analyticsService.track({
            type: 'page',
            page: { title: 'connexion/client' }
          });
          this._navigateToEligibilityPageCommand.execute({ isUserOnboarded });
        },
        (responseError) => {
          switch (responseError.error.error) {
            case EErrorCodes.InvalidGrantUserNotEligible:
            case EErrorCodes.InvalidGrantUserIsNotEligibleAnymore:
              this._eligibilityStoreService.updateEligibilityError(responseError.error.error);
              this._navigateToEligibilityPageCommand.execute();
              break;
            default:
              this._tokenStoreService.setError(responseError);
          }
        }
      );
  }
}
