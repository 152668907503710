import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { DocumentState } from './documents.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'documents',
  idKey: 'documentId'
})
export class DocumentsStore extends EntityStore<DocumentState> {
  constructor() {
    super();
  }
}
