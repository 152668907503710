import { Injectable } from '@angular/core';
import { HeaderMenuItem } from '@models';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class HeaderService {
  private _headerMenuItems: HeaderMenuItem[];

  constructor(private readonly _translateService: TranslateService) {}

  get menuItems(): HeaderMenuItem[] {
    if (!this._headerMenuItems) {
      this._initialiseHeaderMenu();
    }
    return this._headerMenuItems;
  }

  private _initialiseHeaderMenu(): void {
    this._headerMenuItems = [
      new HeaderMenuItem({
        label: this._translateService.instant('app.header-menu.mandates'),
        redirectTo: 'mandates'
      }),
      new HeaderMenuItem({
        label: this._translateService.instant('app.header-menu.transactions'),
        redirectTo: 'transactions'
      }),
      new HeaderMenuItem({
        label: this._translateService.instant('app.header-menu.activities'),
        redirectTo: 'activities'
      }),
      new HeaderMenuItem({
        label: this._translateService.instant('app.header-menu.documents'),
        redirectTo: 'documents'
      })
    ];
  }
}
