import { HttpErrorResponse, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { TokenAuthResponseDTO } from '@backend/dto';
import { AuthenticateAdminCommand, AuthenticateUserCommand, RefreshTokenCommand } from '@commands/backend';
import { ClearUserSessionDataCommand, NavigateToAuthorizationPageCommand, NavigateToEligibilityPageCommand } from '@commands/ui';
import { IdleService } from '@services/idle.service';
import { ApplicationConfigurationStoreService, TokenStoreService } from '@stores';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private _refreshTokenInProgress = false;
  private _refreshToken$ = new BehaviorSubject<TokenAuthResponseDTO>(null);

  constructor(
    private readonly _applicationConfigurationStoreService: ApplicationConfigurationStoreService,
    private readonly _authenticateUserCommand: AuthenticateUserCommand,
    private readonly _tokenStoreService: TokenStoreService,
    private readonly _navigateToEligibilityPageCommand: NavigateToEligibilityPageCommand,
    private readonly _idleService: IdleService,
    private readonly _navigateToAuthorizationPageCommand: NavigateToAuthorizationPageCommand,
    private readonly _refreshTokenCommand: RefreshTokenCommand,
    private readonly _authenticateAdminCommand: AuthenticateAdminCommand,
    private readonly _clearUserSessionDataCommand: ClearUserSessionDataCommand
  ) {}

  public authenticate(params?: ParamMap): void {
    if (params?.has('admin') || params?.has('code')) {
      this._clearUserSessionDataCommand.execute();
    }

    if (params?.has('admin')) {
      return this._authenticateAdminCommand.execute();
    }

    const tokenState = this._tokenStoreService.tokenState;

    if (tokenState?.apim?.accessToken && this._tokenStoreService.isLogged) {
      return this._navigateToEligibilityPageCommand.execute({ updateUserOnboardingStatus: true });
    }

    if (params?.has('code')) {
      return this._authenticateUserCommand.execute(params.get('code'));
    }

    this._navigateToAuthorizationPageCommand.execute();
  }

  public refreshToken(responseError: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this._refreshTokenInProgress) {
      // If refreshTokenInProgress is true, we will wait until
      // refreshTokenBehaviourSubject has a non-null value
      return this._refreshToken$.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => {
          request = request.clone({
            setHeaders: {
              Authorization: `${token.token_type} ${token.access_token}`
            }
          });
          return next.handle(request);
        })
      );
    } else {
      // Set the refreshTokenSubject to null so that subsequent
      // API calls will wait until the new token has been retrieved
      this._refreshTokenInProgress = true;
      this._refreshToken$.next(null);

      return this._refreshTokenCommand.execute().pipe(
        switchMap((response) => {
          this._refreshTokenInProgress = false;
          this._refreshToken$.next(response);
          this._tokenStoreService.updateAppState(response);
          request = request.clone({
            setHeaders: {
              Authorization: `${response.token_type} ${response.access_token}`
            }
          });
          return next.handle(request);
        }),
        catchError(() => {
          this._refreshTokenInProgress = false;
          this._navigateToAuthorizationPageCommand.execute();
          return throwError(responseError);
        })
      );
    }
  }
}
