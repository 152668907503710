import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { ArbitrationsState } from './arbitrations.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'arbitrations' })
export class ArbitrationsStore extends EntityStore<ArbitrationsState> {
  constructor() {
    super();
  }
}
