import { Injectable } from '@angular/core';
import { ProjectionParameters } from '@app/types';
import { InvestmentPlanSimulationProbabilityRequestDTO } from '@backend/dto';
import { getInvestmentPlanSimulationProbability } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { Probability, ReferencesStoreService, SimulationCashFlowStoreService } from '@stores';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GetInvestmentPlanSimulationProbabilityCommand implements ICommand {
  constructor(
    private readonly _simulationCashFlowStoreService: SimulationCashFlowStoreService,
    private readonly _referencesStoreService: ReferencesStoreService
  ) {}

  public execute(data: { investmentPlanIdentifier: string; projectionParameters: ProjectionParameters }): void {
    let newState: Probability;
    const payload: InvestmentPlanSimulationProbabilityRequestDTO = {
      nbPeriod: data.projectionParameters.horizonInMonths,
      initialCapital: data.projectionParameters.investedAmount,
      recurrenceTextId: data.projectionParameters.recurrenceFrequency.textId,
      recurringAmount: data.projectionParameters.recurrentAmount,
      tna: data.projectionParameters.tna,
      targetAmount: data.projectionParameters.objective
    };

    getInvestmentPlanSimulationProbability({
      pathParams: { investmentPlanIdentifier: data.investmentPlanIdentifier },
      payload
    })
      .execute()
      .pipe(
        switchMap((response) => {
          newState = {
            percentage: response.probabilityPercentage,
            statusTextId: response.probabilityStatusTextId.toLowerCase()
          };
          return this._referencesStoreService.getObjectivePercentageStatus$(response.probabilityStatusTextId);
        })
      )
      .subscribe(
        (response) => {
          if (response) {
            newState.description = response.name;
          }

          this._simulationCashFlowStoreService.updateProbability(newState);
        },
        (error) => {
          this._simulationCashFlowStoreService.updateLookingForwardBackendServiceNotWorking({ isProbabilityNotWorking: true });
        }
      );
  }
}
