import { HttpResponse } from '@angular/common/http';
import {
  UserInvestmentPlanActivityDTO,
  UserInvestmentPlansActivitiesResponseDTO
} from '@backend/dto';
import { RequestOptions } from '@app/types';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlansActivitiesRequest extends Request<
  never,
  UserInvestmentPlansActivitiesResponseDTO
> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      path: `/broad${options?.version ?? '/v2'}/me/investment_plans/get_activities`
    });
  }

  public execute(): Observable<UserInvestmentPlanActivityDTO[]> {
    return super
      .execute()
      .pipe(
        map(
          (response: HttpResponse<UserInvestmentPlansActivitiesResponseDTO>) =>
            response.body?.activities
        )
      );
  }
}

export function getInvestmentPlansActivities(
  options?: RequestOptions<never>
): InvestmentPlansActivitiesRequest {
  return new InvestmentPlansActivitiesRequest(options);
}
