<nz-select
  nzMode="default"
  [ngModel]="selectedFrequency"
  [ngClass]="className"
  (ngModelChange)="onSelectorModelChange($event)"
>
  <ng-container *ngFor="let frequency of frequencies; trackBy: trackByTextId">
    <nz-option nzCustomContent [nzLabel]="frequency.label" [nzValue]="frequency">
      {{ frequency.label }}
    </nz-option>
  </ng-container>
</nz-select>
