// credits > https://johnpapa.net/predictive-preloading-of-your-angular-bundles/

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export class OnDemandPreloadOptions {
  constructor(public routePath: string, public preload = true) {}
}

@Injectable({ providedIn: 'root' })
export class OnDemandPreloadService {
  public state$: Observable<OnDemandPreloadOptions>;

  private readonly _state$ = new Subject<OnDemandPreloadOptions>();

  constructor() {
    this.state$ = this._state$.asObservable();
  }

  public startPreload(routePath: string): void {
    this._state$.next(new OnDemandPreloadOptions(routePath));
  }
}
