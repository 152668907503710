import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { ProfilingSynthesisResponseDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class GetSynthesisRequest extends Request<never, ProfilingSynthesisResponseDTO> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      method: 'POST',
      path: `/broad${options?.version ?? '/v1'}/sessions/${
        options?.pathParams?.sessionIdentifier
      }/profiling_Synthesis`
    });
  }

  public execute(): Observable<ProfilingSynthesisResponseDTO> {
    return super
      .execute()
      .pipe(map((response: HttpResponse<ProfilingSynthesisResponseDTO>) => response.body));
  }
}

export function getSynthesis(options?: RequestOptions<never>): GetSynthesisRequest {
  return new GetSynthesisRequest(options);
}
