import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { Observable } from 'rxjs';
import { ArbitrationItem } from './arbitrations.model';
import { ArbitrationsQuery } from './arbitrations.query';
import { ArbitrationsStore } from './arbitrations.store';

@Injectable({ providedIn: 'root' })
export class ArbitrationsStoreService {
  constructor(
    private readonly _arbitrationsStore: ArbitrationsStore,
    private readonly _arbitrationsQuery: ArbitrationsQuery
  ) {}

  get arbitrations$(): Observable<ArbitrationItem[]> {
    return this._arbitrationsQuery.selectAll();
  }

  @transaction()
  public resetWithNewValues(data: ArbitrationItem[]): void {
    this._arbitrationsStore.remove();
    this._arbitrationsStore.upsertMany(data);
  }
}
