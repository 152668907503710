import { Injectable } from '@angular/core';
import { getInvestmentPlansReport } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GetUserInvestmentPlanReportCommand implements ICommand {
  public execute(documentId: number): Observable<any> {
    return getInvestmentPlansReport({ pathParams: { documentId } }).execute();
  }
}
