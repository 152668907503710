<div id="fare-guide__modal-container">
  <div id="fare-guide__table">
    <nz-table #fareGuideTable nzBordered [nzShowPagination]="false" [nzData]="fareGuideItems">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th><span translate="modals.fare-guide.table-header.arbitration-fees-included"></span></th>
          <th><span translate="modals.fare-guide.table-header.performance-based-pricing"></span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let fareGuideItem of fareGuideTable.data">
          <td rowspan="3" *ngIf="fareGuideItem.description" class="no-hover-style">{{ fareGuideItem.description }}</td>
          <td rowspan="1">{{ fareGuideItem.title }}</td>
          <ng-container *ngFor="let value of fareGuideItem.values">
            <td rowspan="1">{{ value }}</td>
          </ng-container>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div id="fare-guide__arbitration-fee">
    <div class="title">
      <p>{{'modals.fare-guide.arbitration-fee.title' | translate}}</p>
    </div>
    <div class="content">
      <p class="arbitration-fee__percentage">
        {{'modals.fare-guide.arbitration-fee.percentage' | translate}}
      </p>
      <p class="arbitration-fee__percentage-subtitle">
        {{'modals.fare-guide.arbitration-fee.subtitle' | translate}}
      </p>
    </div>
  </div>
  <div id="fare-guide__disclaimer">
    <p>{{'modals.fare-guide.disclaimer.1' | translate}}</p>
    <p>{{'modals.fare-guide.disclaimer.2' | translate}}</p>
    <p>{{'modals.fare-guide.disclaimer.3' | translate}}</p>
  </div>
</div>
