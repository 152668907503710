import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Frequency, ReferencesStoreService } from '@stores';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-frequency-selector',
  templateUrl: './frequency-selector.component.html',
  styleUrls: ['./frequency-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FrequencySelectorComponent implements OnInit {
  @Input() public useNone = false;
  @Input() public className: string;
  @Input() set selectedFrequencyTextId(value: string) {
    this._selectedFrequencyTextId = value;
    this._updateView();
  }
  @Input() set updateFrequencyView(value: boolean) {
    if (value === null) return;
    this._updateView();
  }

  @Output() public selectedFrequencyChange = new EventEmitter<Frequency>();

  public frequencies: Frequency[];
  public selectedFrequency: Frequency;

  private _noneFrequency = new Frequency({ textId: 'None', description: 'Aucune' });
  private _selectedFrequencyTextId: string;

  constructor(private readonly _referencesStoreService: ReferencesStoreService, private readonly _changeDetectorRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this._referencesStoreService.frequencies$.pipe(first()).subscribe((data) => {
      this.frequencies = data;

      if (this.useNone) {
        this.frequencies = [this._noneFrequency, ...this.frequencies];
      }

      this._updateView();
    });
  }

  public trackByTextId(index: number, frequency: Frequency): string {
    return frequency.textId;
  }

  public onSelectorModelChange(selectedFrequency: Frequency): void {
    this.selectedFrequencyChange.emit(selectedFrequency);
  }

  private _updateView(): void {
    if (!this.frequencies?.length) {
      return;
    }

    let frequency: Frequency;

    if (this._selectedFrequencyTextId) {
      frequency = this.frequencies.find((frequency) => frequency.textId === this._selectedFrequencyTextId);
    }
    // Tricky change to fix issue with nz-select value when model is complex
    this.selectedFrequency = null;
    setTimeout(() => {
      this.selectedFrequency = frequency || this.frequencies[0];
      this._changeDetectorRef.detectChanges();
      this.selectedFrequencyChange.emit(this.selectedFrequency);
    });
  }
}
