import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { Observable } from 'rxjs';
import { OperationItem } from './operations.model';
import { OperationsQuery } from './operations.query';
import { OperationsStore } from './operations.store';

@Injectable({ providedIn: 'root' })
export class OperationsStoreService {
  constructor(
    private readonly _operationsStore: OperationsStore,
    private readonly _operationsQuery: OperationsQuery
  ) {}

  get operations$(): Observable<OperationItem[]> {
    return this._operationsQuery.selectAll();
  }

  @transaction()
  public resetWithNewValues(data: OperationItem[]): void {
    this._operationsStore.remove();
    this._operationsStore.add(data);
  }
}
