import { HttpResponse } from '@angular/common/http';
import {
  UserInvestmentPlanArbitrationDTO,
  UserInvestmentPlansArbitrationsResponseDTO
} from '@backend/dto';
import { RequestOptions } from '@app/types';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlansArbitrationsRequest extends Request<
  never,
  UserInvestmentPlansArbitrationsResponseDTO
> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      path: `/broad${options?.version ?? '/v2'}/me/investment_plans/arbitration_details`
    });
  }

  public execute(): Observable<UserInvestmentPlanArbitrationDTO[]> {
    return super
      .execute()
      .pipe(
        map(
          (response: HttpResponse<UserInvestmentPlansArbitrationsResponseDTO>) =>
            response.body?.simplifiedBroadArbitrageModels
        )
      );
  }
}

export function getInvestmentPlansArbitrations(
  options?: RequestOptions<never>
): InvestmentPlansArbitrationsRequest {
  return new InvestmentPlansArbitrationsRequest(options);
}
