import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { ReferencesResponseDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class ReferencesRequest extends Request<never, ReferencesResponseDTO> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      path: `${options?.version ?? '/v1'}/references`
    });
  }

  public execute(): Observable<ReferencesResponseDTO> {
    return super
      .execute()
      .pipe(map((response: HttpResponse<ReferencesResponseDTO>) => response.body));
  }
}

export function getReferences(options?: RequestOptions<never>): ReferencesRequest {
  return new ReferencesRequest(options);
}
