export const chartsEnv = {
  chartColorsPalette: [
    '#4B4A69',
    '#7555A9',
    '#B256AE',
    '#FB7555',
    '#F9B65C',
    '#68C3DE',
    '#0088A8',
    '#899EA6',
    '#A4123F',
    '#642F6C',
    '#B7BF10',
    '#00816D',
    '#006A8E',
    '#b2965b'
  ]
};
