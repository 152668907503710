<div id="container">
  <app-loader *ngIf="isLoading"></app-loader>
  <div id="header" *ngIf="canDisplayHeader">
    <app-header></app-header>
  </div>
  <div id="content">
    <router-outlet></router-outlet>
  </div>
  <div id="footer">
    <app-footer></app-footer>
  </div>
</div>
