import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'app-confirm-update-signed-mandate-notification-modal',
  templateUrl: './confirm-update-signed-mandate-notification.modal.html',
  styleUrls: ['./confirm-update-signed-mandate-notification.modal.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmUpdateSignedMandateNotificationModal {
  constructor(private readonly _nzModalRef: NzModalRef) {}

  public confirm(): void {
    this._nzModalRef.close();
  }
}
