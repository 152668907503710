// credits > https://johnpapa.net/predictive-preloading-of-your-angular-bundles/

import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import {
  OnDemandPreloadOptions,
  OnDemandPreloadService
} from '@services/on-demand-preload.service';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OnDemandPreloadStrategy implements PreloadingStrategy {
  constructor(private readonly _onDemandPreloadService: OnDemandPreloadService) {}

  public preload(route: Route, load: () => Observable<any>): Observable<any> {
    return this._onDemandPreloadService.state$.pipe(
      mergeMap((preloadOptions) => {
        return this._shouldPreload(route, preloadOptions) ? load() : EMPTY;
      })
    );
  }

  private _shouldPreload(route: Route, preloadOptions: OnDemandPreloadOptions): boolean {
    return (
      route.data?.preload ||
      ([route.path, '*'].includes(preloadOptions.routePath) && preloadOptions.preload)
    );
  }
}
