import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';

class PreSubscriptionSynthesisRequest extends Request<never, HttpResponse<string>> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      format: 'blob',
      path: `/broad${options?.version ?? '/v1'}/users/me/investment_plans/${
        options?.pathParams?.investmentPlanIdentifier
      }/pre_Subscription_Synthesis`
    });
  }

  public execute(): Observable<HttpResponse<string>> {
    return super.execute();
  }
}

export function getPreSubscriptionSynthesis(
  options?: RequestOptions<never>
): PreSubscriptionSynthesisRequest {
  return new PreSubscriptionSynthesisRequest(options);
}
