import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CreateDepositInvestAction, InvestAction } from '@app/types';
import { CreateInvestmentPlanDepositTransferCommand, UpdateInvestmentPlanRecurrentTransferCommand } from '@commands/backend';
import { EInvestActionType } from '@enums';
import { InvestmentPlan } from '@stores';
import { NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'app-investment-plan-deposit-modal',
  templateUrl: './investment-plan-deposit.modal.html',
  styleUrls: ['./investment-plan-deposit.modal.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestmentPlanDepositModal {
  @Input() public investmentPlan: InvestmentPlan;

  constructor(
    private readonly _nzModalRef: NzModalRef,
    private readonly _updateInvestmentPlanRecurrentTransferCommand: UpdateInvestmentPlanRecurrentTransferCommand,
    private readonly _createInvestmentPlanDepositTransferCommand: CreateInvestmentPlanDepositTransferCommand
  ) {}

  public onActionEmitted(action: InvestAction): void {
    switch (action.type) {
      case EInvestActionType.CreateDeposit:
        this._createInvestmentPlanDepositTransferCommand
          .execute(action as CreateDepositInvestAction)
          .subscribe(() => this._nzModalRef.close(action));
        break;
      case EInvestActionType.UpdateRecurrentDeposit:
        this._updateInvestmentPlanRecurrentTransferCommand
          .execute(action as CreateDepositInvestAction)
          .subscribe(() => this._nzModalRef.close(action));
        break;
      case EInvestActionType.CreateSubscription:
        this._nzModalRef.close(action);
        break;
    }
  }
}
