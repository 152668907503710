import { Injectable } from '@angular/core';
import { AggregatedPerformance, AggregatedPerformanceState } from './aggregated-performance.state';
import { Observable } from 'rxjs';
import { AggregatedPerformanceQuery } from './aggregated-performance.query';
import { AggregatedPerformanceStore } from './aggregated-performance.store';

@Injectable({ providedIn: 'root' })
export class AggregatedPerformanceStoreService {
  constructor(
    private readonly _aggregatedPerformanceStore: AggregatedPerformanceStore,
    private readonly _aggregatedPerformanceQuery: AggregatedPerformanceQuery
  ) {}

  get state$(): Observable<AggregatedPerformanceState> {
    return this._aggregatedPerformanceQuery.select();
  }

  public updateState(data: AggregatedPerformance): void {
    this._aggregatedPerformanceStore.update((state) => ({
      ...state,
      ...data
    }));
  }
}
