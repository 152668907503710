import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageHttpInterceptor implements HttpInterceptor {
  constructor(private readonly _translateService: TranslateService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentLang = this._translateService.currentLang || 'fr';
    if (currentLang) {
      request = request.clone({
        setHeaders: {
          'Accept-Language': currentLang
        }
      });
    }
    return next.handle(request);
  }
}
