import { Injectable } from '@angular/core';
import { AppTokenState, TokenState } from './token.state';
import { Observable } from 'rxjs';
import { TokenQuery } from './token.query';
import { TokenApimAuthResponseDTO, TokenAuthResponseDTO } from '@backend/dto';
import { TokenStore } from './token.store';

@Injectable({ providedIn: 'root' })
export class TokenStoreService {
  public state$: Observable<TokenState> = this._tokenQuery.select();
  public isLogged$: Observable<boolean> = this._tokenQuery.select((state) => !!state?.app?.accessToken);
  public error$ = this._tokenQuery.selectError();

  constructor(private readonly _tokenStore: TokenStore, private readonly _tokenQuery: TokenQuery) {}

  get isAdmin(): boolean {
    return this._tokenQuery.getValue()?.isAdmin;
  }

  get isLogged(): boolean {
    return !!this._tokenQuery.getValue()?.app?.accessToken && Date.now() < this._tokenQuery.getValue()?.app?.expiresTimestamp;
  }

  get appState(): AppTokenState {
    return this._tokenQuery.getValue().app;
  }

  get tokenState(): TokenState {
    return this._tokenQuery.getValue();
  }

  public updateApimState(data: TokenApimAuthResponseDTO): void {
    this._tokenStore.update((state) => {
      return {
        app: state.app,
        apim: {
          accessToken: data.access_token,
          expiresIn: data.expires_in,
          idToken: data.id_token,
          refreshToken: data.refresh_token,
          tokenType: data.token_type
        }
      };
    });
  }

  public updateAppState(data: TokenAuthResponseDTO, isAdmin = false): void {
    this._tokenStore.update((state) => {
      return {
        apim: state.apim,
        app: {
          accessToken: data.access_token,
          expiresIn: data.expires_in,
          expiresTimestamp: Date.now() + data.expires_in * 1000,
          refreshToken: data.refresh_token,
          tokenType: data.token_type
        },
        isAdmin
      };
    });
  }

  public setError(error): void {
    this._tokenStore.setError(error);
  }

  public resetStore(): void {
    this._tokenStore.reset();
  }
}
