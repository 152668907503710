import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { InvestmentPlanContractSynthesisResponseDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlanContractSynthesisRequest extends Request<
  never,
  InvestmentPlanContractSynthesisResponseDTO
> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      path: `/broad${options?.version ?? '/v1'}/users/me/investment_plans/${
        options?.pathParams?.investmentPlanIdentifier
      }/contract_synthesis`
    });
  }

  public execute(): Observable<InvestmentPlanContractSynthesisResponseDTO> {
    return super
      .execute()
      .pipe(
        map((response: HttpResponse<InvestmentPlanContractSynthesisResponseDTO>) => response.body)
      );
  }
}

export function getInvestmentPlanContractSynthesis(
  options?: RequestOptions<never>
): InvestmentPlanContractSynthesisRequest {
  return new InvestmentPlanContractSynthesisRequest(options);
}
