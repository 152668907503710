import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { OnboardingState } from './onboarding.state';

function createInitialState(): OnboardingState {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'onboarding', resettable: true })
export class OnboardingStore extends Store<OnboardingState> {
  constructor() {
    super(createInitialState());
  }
}
