import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { UserInvestmentPlansAggregatedTNAResponseDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlansAggregatedTnaRequest extends Request<
  never,
  UserInvestmentPlansAggregatedTNAResponseDTO
> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      path: `${options?.version ?? '/v3'}/users/me/investment_plans/aggregated_TNA`
    });
  }

  public execute(): Observable<UserInvestmentPlansAggregatedTNAResponseDTO> {
    return super
      .execute()
      .pipe(
        map((response: HttpResponse<UserInvestmentPlansAggregatedTNAResponseDTO>) => response.body)
      );
  }
}

export function getInvestmentPlansAggregatedTna(
  options?: RequestOptions<never>
): InvestmentPlansAggregatedTnaRequest {
  return new InvestmentPlansAggregatedTnaRequest(options);
}
