import { Injectable } from '@angular/core';
import { Step } from '@app/types';
import { TranslateService } from '@ngx-translate/core';
import { OnboardingStoreService } from '@stores';

@Injectable({ providedIn: 'root' })
export class OnboardingStepperService {
  private _steps: Step[];

  constructor(private readonly _translateService: TranslateService, private readonly _onboardingStoreService: OnboardingStoreService) {
    if (this._onboardingStoreService.hasSteps) {
      this._steps = this._onboardingStoreService.steps.map((step) => ({ ...step }));
    }

    if (!this._steps) {
      this._steps = this._getNewStepsState();
    }

    this._onboardingStoreService.updateStepsState(this.steps);
  }

  get steps(): Step[] {
    return this._steps;
  }

  public updateCurrentStep(newCurrentStepIndex: number): void {
    this._steps.forEach((step, index) => {
      step.isCurrent = false;
      if (index < newCurrentStepIndex) {
        step.status = 'finish';
      } else if (index === newCurrentStepIndex) {
        step.isCurrent = true;
        step.status = 'process';
      } else {
        step.status = 'wait';
      }
    });
  }

  public nextStep(): void {
    const currentStepIndex = this._steps.findIndex((step) => step.isCurrent);
    const nextStepIndex = currentStepIndex + 1;

    this._steps[currentStepIndex].isCurrent = false;
    this._steps[currentStepIndex].status = 'finish';

    if (nextStepIndex < this._steps.length) {
      this._steps[nextStepIndex].isCurrent = true;
      this._steps[nextStepIndex].status = 'process';
    }
  }

  public previousStep(): void {
    const currentStepIndex = this._steps.findIndex((step) => step.isCurrent);
    const previousStep = currentStepIndex - 1;

    this._steps[currentStepIndex].isCurrent = false;
    this._steps[currentStepIndex].status = 'wait';

    if (previousStep < this._steps.length) {
      this._steps[previousStep].isCurrent = true;
      this._steps[previousStep].status = 'process';
    }
  }

  public resetStepsState(): void {
    this._steps = this._getNewStepsState();
  }

  private _getNewStepsState(): Step[] {
    return [
      {
        title: this._translateService.instant('onboarding.layouts.questionnaires.stepper.step.1.title'),
        isCurrent: true,
        status: 'process'
      },
      {
        title: this._translateService.instant('onboarding.layouts.questionnaires.stepper.step.2.title'),
        isCurrent: false,
        status: 'wait'
      },
      {
        title: this._translateService.instant('onboarding.layouts.questionnaires.stepper.step.3.title'),
        isCurrent: false,
        status: 'wait'
      },
      {
        title: this._translateService.instant('onboarding.layouts.questionnaires.stepper.step.4.title'),
        isCurrent: false,
        status: 'wait'
      }
    ];
  }
}

export enum EOnboardingStep {
  Objective,
  RiskProfile,
  ESG,
  InvestmentPreference
}
