import { Injector } from '@angular/core';
import { appInjector } from '@app/app.injector';

export function AppInjectorRefInitializerFactory(injector: Injector): () => Promise<unknown> {
  return () => {
    return new Promise<unknown>((resolve, reject) => {
      appInjector(injector);
      resolve(null);
    });
  };
}
