import { TokenStoreService } from '@stores/token/token-store.service';
import { UserOnboardingStatusService } from '@services/user-onboarding-status.service';

export function UserOnboardingInitializerFactory(
  tokenStoreService: TokenStoreService,
  userOnboardingStatusService: UserOnboardingStatusService
) {
  return () => {
    return new Promise<any>((resolve, reject) => {
      if (!tokenStoreService.isLogged) {
        return resolve();
      }

      userOnboardingStatusService.updateUserOnboardingStatus().subscribe({
        complete: () => resolve()
      });
    });
  };
}
