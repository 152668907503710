import { Injectable } from '@angular/core';
import { ESGParametersDTO, ReferencesResponseDTO } from '@backend/dto';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ESGParametersStateModel,
  Frequency,
  InvestmentPlanType,
  ObjectivePercentageStatus,
  Profile,
  ReferencesState
} from './references.state';
import { ReferencesStore } from './references.store';
import { ReferencesQuery } from './references.query';

@Injectable({ providedIn: 'root' })
export class ReferencesStoreService {
  constructor(private readonly _referencesStore: ReferencesStore, private readonly _referencesQuery: ReferencesQuery) {}

  get state(): ReferencesState {
    return this._referencesQuery.getValue();
  }

  get esgParameters(): ESGParametersStateModel {
    return this._referencesQuery.getValue().esgParameters;
  }

  get esgCategoriesOptions(): string[] {
    const params = this._referencesQuery.getValue().esgParameters;
    return [params.q2_C_Theme_E, params.q2_C_Theme_S, params.q2_C_Theme_G];
  }

  get frequencies$(): Observable<Frequency[]> {
    return this._referencesQuery
      .select((state) => state.recurrences)
      .pipe(map((recurrences) => recurrences.map((recurrence) => new Frequency(recurrence))));
  }

  get investmentPlanTypes$(): Observable<InvestmentPlanType[]> {
    return this._referencesQuery.select((state) => state.investmentPlanTypes);
  }

  public getObjectivePercentageStatus$(textId: string): Observable<ObjectivePercentageStatus> {
    return this._referencesQuery
      .select((state) => state.objectivePercentageStatuses)
      .pipe(
        map((objectivePercentageStatuses) => {
          return objectivePercentageStatuses.find((element) => element.textId === textId);
        })
      );
  }

  public getInvestmentPlanProfile(profileTextId: string): Profile {
    return this.state.profiles.find((profile) => profile.textId === profileTextId);
  }

  public getInvestmentPlanProfile$(profileTextId: string): Observable<Profile> {
    return this._referencesQuery
      .select((state) => state.profiles)
      .pipe(map((profiles) => profiles.find((profile) => profile.textId === profileTextId)));
  }

  public getInvestmentPlanType(investmentPlanTypeTextId: string): InvestmentPlanType {
    return this.state.investmentPlanTypes.find((investmentPlanType) => investmentPlanType.textId === investmentPlanTypeTextId);
  }

  public updateReferencesState(data: ReferencesResponseDTO): void {
    this._referencesStore.update((state) => JSON.parse(JSON.stringify(data)));
  }
}
