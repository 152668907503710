import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { ComputeTargetAmountRequestDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class ComputeTargetAmountRequest extends Request<ComputeTargetAmountRequestDTO, number> {
  constructor(options?: RequestOptions<ComputeTargetAmountRequestDTO>) {
    super({
      ...options,
      method: 'POST',
      path: `${options?.version ?? '/v2'}/investment_plans/target_amount`,
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

  public execute(): Observable<number> {
    return super.execute().pipe(map((response: HttpResponse<number>) => response.body));
  }
}

export function computeTemporaryInvestmentPlanTargetAmount(
  options?: RequestOptions<ComputeTargetAmountRequestDTO>
): ComputeTargetAmountRequest {
  return new ComputeTargetAmountRequest(options);
}
