import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'app-advisor-notification-modal',
  templateUrl: './advisor-notification.modal.html',
  styleUrls: ['./advisor-notification.modal.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvisorNotificationModal {
  constructor(private readonly _nzModalRef: NzModalRef) {}

  public onButtonClicked(): void {
    this._nzModalRef.close();
  }
}
