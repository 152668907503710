import { Injectable } from '@angular/core';
import { MeResponseDTO } from '@backend/dto';
import { getUserData } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { UserStoreService } from '@stores';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GetUserInformationCommand implements ICommand {
  constructor(private readonly _userStoreService: UserStoreService) {}

  public execute(): Observable<MeResponseDTO> {
    return getUserData()
      .execute()
      .pipe(tap((response) => this._userStoreService.updateUserState(response)));
  }
}
