import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenAuthResponseDTO } from '@backend/dto';
import { refreshToken } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { EGrantType } from '@enums';
import { TokenStoreService } from '@stores';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RefreshTokenCommand implements ICommand {
  constructor(private readonly _tokenStoreService: TokenStoreService) {}

  public execute(): Observable<TokenAuthResponseDTO> {
    return refreshToken({
      payload: new HttpParams({
        fromObject: {
          grant_type: EGrantType.RefreshToken,
          refresh_token: this._tokenStoreService.appState.refreshToken
        }
      })
    }).execute();
  }
}
