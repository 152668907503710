import { Injectable } from '@angular/core';
import { ICommand } from '@commands/interfaces';
import { NavigateToCommand } from '@commands/ui/navigate-to.command';
import { OnboardingStepperService } from '@services/onboarding-stepper.service';
import { OnboardingState, OnboardingStoreService, InvestmentPlansStoreService, AnalyticsStoreService } from '@stores';
import { CleanInvestmentPlanQuestionnaireSessionCommand } from '@commands/backend/clean-investment-plan-questionnaire-session.command';
import { Observable, of } from 'rxjs';
import { GetSynthesisCommand } from '../backend';

@Injectable({ providedIn: 'root' })
export class StartOnboardingProcessCommand implements ICommand {
  constructor(
    private readonly _analyticsStoreService: AnalyticsStoreService,
    private readonly _getSynthesisCommand: GetSynthesisCommand,
    private readonly _investmentPlanStoreService: InvestmentPlansStoreService,
    private readonly _onboardingStoreService: OnboardingStoreService,
    private readonly _navigateToCommand: NavigateToCommand,
    private readonly _onboardingStepperService: OnboardingStepperService,
    private readonly _cleanInvestmentPlanQuestionnaireSessionCommand: CleanInvestmentPlanQuestionnaireSessionCommand
  ) {}

  public execute(state: Partial<OnboardingState>): void {
    this._onboardingStoreService.resetStore();
    this._getSynthesisCommand.resetSynthesisBehaviorSubject();
    this._analyticsStoreService.resetStore();
    state.onboardingStarted = true;
    state.onboardingCompleted = false;
    this._onboardingStepperService.resetStepsState();
    const investmentPlanToUpdate = this._investmentPlanStoreService.getInvestmentPlanById(state?.investmentPlanIdentifier);
    this._onboardingStoreService.setNewInitialState({
      ...state,
      investmentPlanToUpdate
    });
    this._onboardingStoreService.updateStepsState(this._onboardingStepperService.steps);

    //FOR P1 flow (= update complete of the mandate) need to save esgParams on store to prefill the form
    if (state.onboardingType === 'update') {
      this._onboardingStoreService.setESGParametersFromMandateDimensions(investmentPlanToUpdate.dimensions);
    }

    let commands: string[];
    let observable$: Observable<any>;

    switch (state.onboardingType) {
      case 'create':
        commands = ['webclient', 'onboarding', 'start'];
        observable$ = of(true);
        break;
      case 'update':
        commands = ['webclient', 'onboarding', 'questionnaires', 'objective-choice'];
        observable$ = this._cleanInvestmentPlanQuestionnaireSessionCommand.execute(state.investmentPlanIdentifier);
        break;
      case 'update-preference':
        commands = ['webclient', 'onboarding', 'questionnaires', 'management-preferences'];
        observable$ = this._cleanInvestmentPlanQuestionnaireSessionCommand.execute(state.investmentPlanIdentifier);
        break;
    }

    observable$.subscribe(() => {
      this._navigateToCommand.execute({ commands });
    });
  }
}
