import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MandateProjectionWithObjectiveService {
  public horizonInYears$: Observable<number>;

  private _horizonInYears$ = new Subject<number>();

  constructor() {
    this.horizonInYears$ = this._horizonInYears$.asObservable();
  }

  set horizonInYears(value: number) {
    this._horizonInYears$.next(value);
  }
}
