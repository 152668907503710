import { Injectable } from '@angular/core';
import { getSynthesis } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProfilingSynthesisResponseDTO } from '@backend/dto/onboarding.dto';

@Injectable({ providedIn: 'root' })
export class GetSynthesisCommand implements ICommand {
  public getSynthesis$: Observable<ProfilingSynthesisResponseDTO>;
  private _getSynthesisBehaviorSubject = new BehaviorSubject<ProfilingSynthesisResponseDTO>(null);

  constructor() {
    this.getSynthesis$ = this._getSynthesisBehaviorSubject.asObservable();
  }

  public execute(sessionIdentifier: string): Observable<ProfilingSynthesisResponseDTO> {
    return this._getSynthesisBehaviorSubject.getValue()
      ? this.getSynthesis$
      : getSynthesis({ pathParams: { sessionIdentifier } })
          .execute()
          .pipe(tap((response) => this._getSynthesisBehaviorSubject.next(response)));
  }

  public resetSynthesisBehaviorSubject(): void {
    this._getSynthesisBehaviorSubject.next(null);
  }
}
