import { Injectable } from '@angular/core';
import { deleteInvestmentPlan } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { FetchUserMandatesCommand } from '@commands/ui/fetch-user-mandates.command';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DeleteMandateCommand implements ICommand {
  constructor(
    private readonly _nzModalService: NzModalService,
    private readonly _translateService: TranslateService,
    private readonly _fetchUserMandatesCommand: FetchUserMandatesCommand
  ) {}

  public execute(investmentPlanIdentifier: string): void {
    this._nzModalService.confirm({
      nzTitle: this._translateService.instant(
        'mandates.mandates-list.confirm-modals.delete-mandate'
      ),
      nzOkText: this._translateService.instant('common.cta.confirm'),
      nzCancelText: this._translateService.instant('common.cta.cancel'),
      nzClassName: 'delete-mandate-confirm-modal',
      nzIconType: 'info-circle',
      nzOnOk: () => this._deleteMandate(investmentPlanIdentifier)
    });
  }

  private _deleteMandate(investmentPlanIdentifier: string): void {
    deleteInvestmentPlan({ pathParams: { investmentPlanIdentifier } })
      .execute()
      .pipe(switchMap(() => this._fetchUserMandatesCommand.execute()))
      .subscribe();
  }
}
