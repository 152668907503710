<div id="investment-plan-deposit__container">
  <h3 nz-title class="investment-plan-deposit">
    {{ 'invest.investment-plan-deposit.title' | translate }}
  </h3>

  <div id="deposit-amount__container">
    <p>{{ 'invest.deposit.labels.amount' | translate }}</p>
    <app-amount-input [className]="'invest'" (amountInputValueChanges)="onAmountInputValueChanged($event)"></app-amount-input>
  </div>

  <div id="contract__container">
    <p>{{ 'invest.deposit.labels.investment-plans-selector' | translate }}</p>
    <p [ngClass]="investmentPlan.colorClassName">{{ investmentPlan.contractName }}</p>
    <p>{{ investmentPlan.currentAmount | toAmount: '€' }} ²</p>
  </div>

  <div id="frequency-selector__container">
    <p>{{ 'invest.recurrent.labels.frequency-selector' | translate }}</p>
    <app-frequency-selector
      [useNone]="true"
      [className]="'invest'"
      (selectedFrequencyChange)="onSelectedFrequencyChanged($event)"
    ></app-frequency-selector>
    <p>{{ 'invest.deposit.labels.disclaimer.2' | translate }}</p>
  </div>

  <div id="actions__container">
    <ng-container [ngSwitch]="investmentPlan?.contract?.status">
      <ng-container *ngSwitchCase="'Signed'">
        <button
          accessControl
          nz-button
          nzType="primary"
          e2e-id="real-invest-button"
          [accessControlFunction]="'Birdee.InvestmentPlan.Real.Deposit'"
          (click)="createDeposit()"
        >
          {{ 'common.cta.validate' | translate }}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'Virtual'">
        <button
          accessControl
          nz-button
          nzType="primary"
          class="secondary"
          e2e-id="virtual-invest-button"
          [accessControlFunction]="'Birdee.InvestmentPlan.Virtual.Deposit'"
          (click)="createDeposit()"
        >
          {{ 'common.cta.virtual-invest' | translate }}
        </button>
        <button
          accessControl
          nz-button
          nzType="primary"
          e2e-id="subscribe-button"
          [accessControlFunction]="'Birdee.InvestmentPlan.Real.Create'"
          (click)="createSubscription()"
        >
          {{ 'common.cta.subscribe-mandate' | translate }}
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>
