import { Injectable } from '@angular/core';
import { getSubscriptionFlowBlockedStatus } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { OnboardingStoreService } from '@stores';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GetSubscriptionFlowBlockedStatusCommand implements ICommand {
  constructor(private readonly _onboardingStoreService: OnboardingStoreService) {}

  public execute(): Observable<boolean> {
    return getSubscriptionFlowBlockedStatus({
      pathParams: {
        investmentPlanIdentifier: this._onboardingStoreService.investmentPlanIdentifier
      }
    })
      .execute()
      .pipe(map((response) => response.is_flow_blocked));
  }
}
