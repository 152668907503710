import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fare-guide-modal',
  templateUrl: './fare-guide.modal.html',
  styleUrls: ['./fare-guide.modal.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FareGuideModal {
  public fareGuideItems: FareGuideItem[];

  constructor(private readonly _translateService: TranslateService) {
    this.fareGuideItems = [
      {
        title: this._translateService.instant('modals.fare-guide.table.item.1.title'),
        description: this._translateService.instant('modals.fare-guide.table.description'),
        values: [
          this._translateService.instant('modals.fare-guide.table.item.1.value.1'),
          this._translateService.instant('modals.fare-guide.table.item.1.value.2')
        ]
      },
      {
        title: this._translateService.instant('modals.fare-guide.table.item.2.title'),
        values: [
          this._translateService.instant('modals.fare-guide.table.item.2.value.1'),
          this._translateService.instant('modals.fare-guide.table.item.2.value.2')
        ]
      },
      {
        title: this._translateService.instant('modals.fare-guide.table.item.3.title'),
        values: [
          this._translateService.instant('modals.fare-guide.table.item.3.value.1'),
          this._translateService.instant('modals.fare-guide.table.item.3.value.2')
        ]
      }
    ];
  }
}

type FareGuideItem = {
  title: string;
  values: string[];
  description?: string;
};
