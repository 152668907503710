import { Injectable } from '@angular/core';
import { CreateDepositInvestAction } from '@app/types';
import {
  createInvestmentPlanDepositTransfer,
  createInvestmentPlanVirtualRecurrentTransfer
} from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { EContractStatus } from '@enums';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpdateInvestmentPlanRecurrentTransferCommand implements ICommand {
  public execute(action: CreateDepositInvestAction): Observable<unknown> {
    return action.data.investmentPlan.contractStatus === EContractStatus.Virtual
      ? this._updateVirtualRecurrentDepositTransfer(action)
      : this._updateRecurrentDepositTransfer(action);
  }

  private _updateVirtualRecurrentDepositTransfer(
    action: CreateDepositInvestAction
  ): Observable<string> {
    return createInvestmentPlanVirtualRecurrentTransfer({
      pathParams: { investmentPlanIdentifier: action.data.investmentPlan.identifier },
      payload: {
        amount: action.data.amount,
        recurringFrequencyTextId: action.data.frequency.textId
      }
    }).execute();
  }

  private _updateRecurrentDepositTransfer(action: CreateDepositInvestAction): Observable<void> {
    return createInvestmentPlanDepositTransfer({
      pathParams: { investmentPlanIdentifier: action.data.investmentPlan.identifier },
      payload: {
        amount: action.data.amount,
        recurringFrequencyTextId: action.data.frequency.textId
      }
    }).execute();
  }
}
