import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticateGuard, OnboardingGuard, SubscriptionGuard } from '@guards';
import { OnDemandPreloadStrategy } from '@strategies/on-demand.preload-strategy';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/webclient/mandates',
    pathMatch: 'full'
  },
  {
    path: 'webclient',
    children: [
      {
        path: 'authentication',
        loadChildren: () => import('@auth/auth.module').then((m) => m.AuthModule),
        data: {
          preload: true
        }
      },
      {
        path: 'mandates',
        canActivate: [AuthenticateGuard],
        loadChildren: () => import('@mandates/mandates.module').then((m) => m.MandatesModule),
        data: {
          preload: true
        }
      },
      {
        path: 'documents',
        canActivate: [AuthenticateGuard],
        loadChildren: () => import('@documents/documents.module').then((m) => m.DocumentsModule)
      },
      {
        path: 'eligibility',
        loadChildren: () =>
          import('@eligibility/eligibility.module').then((m) => m.EligibilityModule),
        data: {
          preload: true
        }
      },
      {
        path: 'onboarding',
        canActivate: [AuthenticateGuard, OnboardingGuard],
        loadChildren: () => import('@onboarding/onboarding.module').then((m) => m.OnboardingModule)
      },
      {
        path: 'subscription',
        canActivate: [AuthenticateGuard, SubscriptionGuard],
        loadChildren: () =>
          import('@subscription/subscription.module').then((m) => m.SubscriptionModule)
      },
      {
        path: 'activities',
        canActivate: [AuthenticateGuard],
        loadChildren: () => import('@activities/activities.module').then((m) => m.ActivitiesModule)
      },
      {
        path: 'transactions',
        canActivate: [AuthenticateGuard],
        loadChildren: () =>
          import('@transactions/transactions.module').then((m) => m.TransactionsModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: OnDemandPreloadStrategy })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
