import { svgIcons } from '@app/icons';
import { IconsRegistryService } from '@services';

export function IconsInitializerFactory(iconsRegistryService: IconsRegistryService) {
  return () =>
    new Promise((resolve, reject) => {
      iconsRegistryService.registerIcons(svgIcons);
      resolve();
    });
}
