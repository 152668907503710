import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationConfigurationStoreService } from '@stores';

@Component({
  selector: 'app-idle-warning-notification-modal',
  templateUrl: './idle-warning-notification.modal.html',
  styleUrls: ['./idle-warning-notification.modal.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdleWarningNotificationModal implements OnInit {
  @Input() set timeoutWarning$(eventEmitter: EventEmitter<any>) {
    if (!eventEmitter) {
      return;
    }

    this._timeoutWarning$ = eventEmitter;
    this._timeoutWarning$.subscribe((countdown: number) => {
      if (countdown > 1) {
        this.message = this._translateService.instant(
          'modals.idle-warning-notification.logging-out.plural',
          { sec: countdown }
        );
      } else if (countdown === 1) {
        this.message = this._translateService.instant(
          'modals.idle-warning-notification.logging-out.single',
          { sec: countdown }
        );
      }
      this._changeDetectorRef.detectChanges();
    });
  }

  public message: string;

  private _timeoutWarning$: EventEmitter<any>;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _translateService: TranslateService,
    private readonly _applicationConfigurationStoreService: ApplicationConfigurationStoreService
  ) {}

  public ngOnInit(): void {
    this.message = this._translateService.instant(
      'modals.idle-warning-notification.logging-out.plural',
      { sec: this._applicationConfigurationStoreService.state?.IDLE_COUNTDOWN }
    );
  }
}
