import { Inject, Injectable } from '@angular/core';
import { logout } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { NavigateToAuthorizationPageCommand } from '@commands/ui/navigate-to-authorization-page.command';
import { WINDOW } from '@services/window.service';
import { TokenStoreService } from '@stores';
import { ClearUserSessionDataCommand } from '@commands/ui/clear-user-session-data.command';

@Injectable({ providedIn: 'root' })
export class LogOutAdminCommand implements ICommand {
  constructor(
    private readonly _tokenStoreService: TokenStoreService,
    private readonly _navigateToAuthorizationPageCommand: NavigateToAuthorizationPageCommand,
    private readonly _clearUserSessionDataCommand: ClearUserSessionDataCommand,
    @Inject(WINDOW) private readonly _window: Window
  ) {}

  public execute(reason: string = 'unknown logout action'): void {
    if (!this._tokenStoreService.isLogged) {
      return;
    }

    logout({
      payload: {
        Reason: reason,
        Token: this._tokenStoreService.appState.refreshToken
      }
    })
      .execute()
      .subscribe({
        /*
        instead of using same call on successful emission and on error
        we can use only the complete callback
        */
        complete: () => {
          this._clearUserSessionDataCommand.execute();
          this._window.close();
          /*
          in case the parent window has been closed
          redirect to authorization page
           */
          this._navigateToAuthorizationPageCommand.execute();
        }
      });
  }
}
