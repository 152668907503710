import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { TokenApimAuthRequestDTO, TokenApimAuthResponseDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class TokenApimRequest extends Request<TokenApimAuthRequestDTO, TokenApimAuthResponseDTO> {
  constructor(options?: RequestOptions<TokenApimAuthRequestDTO>) {
    super({
      ...options,
      method: 'POST',
      path: `/broad${options?.version ?? '/v1.0'}/token_apim`,
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      ignoreLoader: true
    });
  }

  public execute(): Observable<TokenApimAuthResponseDTO> {
    return super
      .execute()
      .pipe(map((response: HttpResponse<TokenApimAuthResponseDTO>) => response.body));
  }
}

export function getTokenApim(options?: RequestOptions<TokenApimAuthRequestDTO>): TokenApimRequest {
  return new TokenApimRequest(options);
}
