import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { UserInvestmentPlansAggregatedPerformanceResponseDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlansAggregatedPerformanceRequest extends Request<
  never,
  UserInvestmentPlansAggregatedPerformanceResponseDTO
> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      path: `${options?.version ?? '/v3'}/users/me/aggregated_performance`
    });
  }

  public execute(): Observable<UserInvestmentPlansAggregatedPerformanceResponseDTO> {
    return super
      .execute()
      .pipe(
        map(
          (response: HttpResponse<UserInvestmentPlansAggregatedPerformanceResponseDTO>) =>
            response.body
        )
      );
  }
}

export function getInvestmentPlansAggregatedPerformance(
  options?: RequestOptions<never>
): InvestmentPlansAggregatedPerformanceRequest {
  return new InvestmentPlansAggregatedPerformanceRequest(options);
}
