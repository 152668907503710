import { Injectable } from '@angular/core';
import { InvestmentPlanTemporaryVirtualRequestDTO } from '@backend/dto';
import { createTemporaryVirtualInvestmentPlan } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CreateTemporaryVirtualInvestmentPlanCommand implements ICommand {
  public execute(data: InvestmentPlanTemporaryVirtualRequestDTO): Observable<string> {
    return createTemporaryVirtualInvestmentPlan({ payload: data })
      .execute()
      .pipe(map((response) => response.investmentPlanIdentifier));
  }
}
