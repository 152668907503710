import { Inject, Injectable } from '@angular/core';
import {
  AnalyticsDownloadDocumentInteraction,
  AnalyticsFlow,
  AnalyticsInteraction,
  AnalyticsInteractionData,
  AnalyticsPage,
  AnalyticsPageObject,
  AnalyticsTrackObject
} from '@app/types/analytics.type';
import { WINDOW } from '@services/window.service';
import { AnalyticsStoreService, OnboardingStoreService } from '@stores';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  constructor(
    private readonly _analyticsStoreService: AnalyticsStoreService,
    private readonly _onboardingStoreService: OnboardingStoreService,
    @Inject(WINDOW) private readonly _window
  ) {
    if (!this._window.digitalData) {
      this._window.digitalData = { services: { myMandate: {} } };
    } else if (!this._window.digitalData.services) {
      this._window.digitalData.services = { myMandate: {} };
    } else if (!this._window.digitalData.services.myMandate) {
      this._window.digitalData.services.myMandate = {};
    }
  }

  public track(data: AnalyticsTrackObject): void {
    switch (data.type) {
      case 'flow':
        return this._trackFlow(data.flow);
      case 'interaction':
        return this._trackInteractionType(data.interaction);
      case 'page':
        return this._trackPage(data.page);
    }
  }

  private _trackPage(page: AnalyticsPage): void {
    const steps = [
      ...this._analyticsStoreService.macroSteps,
      {
        isFirstStep: false,
        isLastStep: false,
        title: page.title
      }
    ];
    const pageObject: AnalyticsPageObject = {
      custom: {
        ...page.customData,
        ...this._analyticsStoreService.customerInfo
      },
      noStep: true,
      pathTitle: 'MyMandate',
      steps
    };
    this._analyticsStoreService.macroSteps = steps;
    this._track(pageObject);
  }

  private _trackFlow(flow: AnalyticsFlow): void {
    let pathTitle: string;
    let qworkflowTitle: string;
    const steps = [...this._analyticsStoreService.steps];
    const stepNumber = this._analyticsStoreService.stepNumber;

    switch (flow.type) {
      case 'onboarding':
        pathTitle = `MyMandate/simulation${this._onboardingType()}`;
        break;
      case 'subscription':
        pathTitle = `MyMandate/mandat reel${this._onboardingType()}`;
        break;
    }

    if (flow.qworkflow) {
      const items: string[] = [];

      !!flow.qworkflow.title && items.push(flow.qworkflow.title);
      !!flow.qworkflow.textId && items.push(flow.qworkflow.textId);
      !!flow.qworkflow.question && items.push(flow.qworkflow.question);

      qworkflowTitle = items.join(' - ');
    }

    steps.push({
      isFirstStep: flow.isFirstStep ?? !steps.length,
      isLastStep: flow.isLastStep ?? false,
      title: flow.title ?? qworkflowTitle,
      stepNumber
    });
    const pageObject: AnalyticsPageObject = {
      custom: { ...this._analyticsStoreService.customerInfo },
      noStep: false,
      pathTitle,
      steps
    };

    this._analyticsStoreService.stepNumber = stepNumber + 1;
    this._analyticsStoreService.steps = steps;
    this._track(pageObject);
  }

  private _trackInteractionType(interaction: AnalyticsInteraction): void {
    switch (interaction.type) {
      case 'deposit':
        interaction.data.linkName = 'MyMandate versement approuve';
        break;
      case 'withdraw':
        interaction.data.linkName = 'MyMandate rachat approuve';
        break;
      case 'add-recurring-deposit':
        interaction.data.linkName = 'MyMandate versement regulier - ajout';
        break;
      case 'update-recurring-deposit':
        interaction.data.linkName = 'MyMandate versement regulier - modification';
        break;
      case 'delete-recurring-deposit':
        interaction.data.linkName = 'MyMandate versement regulier - suppression';
        break;
      case 'download-document':
        interaction.data.linkName = `MyMandate download doc - ${(interaction.data as AnalyticsDownloadDocumentInteraction).typeDoc}`;
        break;
      case 'projection':
        interaction.data.linkName = 'MyMandate contract details - update projection';
        break;
      case 'advices':
        interaction.data.linkName = 'MyMandate contract details - update advice';
        break;
      case 'create-or-update-mandate':
        interaction.data.linkName = `MyMandate dashboard - ${this._onboardingStoreService.onboardingType} contract`;
        break;
    }

    this._trackInteraction(interaction.data);
  }

  private _trackInteraction(data: AnalyticsInteractionData): void {
    const custom = {
      ...data,
      ...this._analyticsStoreService.customerInfo
    };
    console.groupCollapsed('ADOBE:INTERACTION');
    console.log(data);
    console.log(this._window.digitalData.services.myMandate);
    this._window.digitalData.services.myMandate.custom = custom;
    console.groupEnd();
    // @ts-ignore
    _satellite.track('myMandateInteractionLink');
  }

  private _track(data: AnalyticsPageObject): void {
    this._window.digitalData.services.myMandate = { ...data };
    console.groupCollapsed('ADOBE:PAGE_VIEW');
    console.log(data);
    console.groupEnd();
  }

  private _onboardingType(): string {
    switch (this._onboardingStoreService.onboardingType) {
      case 'create':
        return '/creation';
      case 'update':
        return '/modification';
      case 'update-preference':
        return '/modification preferences';
      default:
        return '';
    }
  }
}
