import { Injectable } from '@angular/core';
import { logout } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { NavigateToAuthorizationPageCommand } from '@commands/ui/navigate-to-authorization-page.command';
import { TokenStoreService } from '@stores/token/token-store.service';
import { ClearUserSessionDataCommand } from '@commands/ui/clear-user-session-data.command';

@Injectable({ providedIn: 'root' })
export class LogOutCommand implements ICommand {
  constructor(
    private readonly _tokenStoreService: TokenStoreService,
    private readonly _navigateToAuthorizationPageCommand: NavigateToAuthorizationPageCommand,
    private readonly _clearUserSessionDataCommand: ClearUserSessionDataCommand
  ) {}

  public execute(reason: string = 'unknown logout action'): void {
    if (!this._tokenStoreService.isLogged) {
      this._clearUserSessionDataCommand.execute();
      this._navigateToAuthorizationPageCommand.execute();
      return;
    }

    logout({
      payload: {
        Reason: reason,
        Token: this._tokenStoreService.appState.refreshToken
      }
    })
      .execute()
      .subscribe({
        /*
        instead of using same call on successful emission and on error
        we can use only the complete callback
        */
        complete: () => {
          this._clearUserSessionDataCommand.execute();
          this._navigateToAuthorizationPageCommand.execute();
        }
      });
  }
}
