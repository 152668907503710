import { Injectable } from '@angular/core';
import { deleteInvestmentPlanPreSubscription } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { FetchUserMandatesCommand } from '@commands/ui/fetch-user-mandates.command';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CancelMandateSubscriptionCommand implements ICommand {
  constructor(
    private readonly _nzModalService: NzModalService,
    private readonly _translateService: TranslateService,
    private readonly _fetchUserMandatesCommand: FetchUserMandatesCommand,
    private readonly _router: Router
  ) {}

  public execute(investmentPlanIdentifier: string): void {
    this._nzModalService.confirm({
      nzTitle: this._translateService.instant(
        'mandates.mandates-list.confirm-modals.cancel-subscription'
      ),
      nzOkText: this._translateService.instant('common.cta.confirm'),
      nzCancelText: this._translateService.instant('common.cta.cancel'),
      nzClassName: 'cancel-mandate-subscription-confirm-modal',
      nzIconType: 'info-circle',
      nzOnOk: () => this._cancelMandateSubscription(investmentPlanIdentifier)
    });
  }

  private _cancelMandateSubscription(investmentPlanIdentifier: string): void {
    deleteInvestmentPlanPreSubscription({ pathParams: { investmentPlanIdentifier } })
      .execute()
      .pipe(switchMap(() => this._fetchUserMandatesCommand.execute()))
      .subscribe(() => {
        this._router.navigate(['webclient', 'mandates']);
      });
  }
}
