import { Injectable } from '@angular/core';
import { CreateDepositInvestAction, InvestAction } from '@app/types';
import { createInvestmentPlanDepositTransfer, createInvestmentPlanVirtualDepositTransfer } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { EContractStatus, EPaymentMethod, EPaymentTarget } from '@enums';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FetchUserMandatesCommand } from '@commands/ui/fetch-user-mandates.command';

@Injectable({ providedIn: 'root' })
export class CreateInvestmentPlanDepositTransferCommand implements ICommand {
  constructor(private readonly _fetchUserMandatesCommand: FetchUserMandatesCommand) {}

  public execute(action: InvestAction): Observable<unknown> {
    const observable$ =
      action.data.investmentPlan.contractStatus === EContractStatus.Virtual
        ? this._createVirtualDepositTransfer(action as CreateDepositInvestAction)
        : this._createDepositTransfer(action as CreateDepositInvestAction);

    return observable$.pipe(
      switchMap(() => {
        return this._fetchUserMandatesCommand.execute();
      })
    );
  }

  private _createVirtualDepositTransfer(action: CreateDepositInvestAction): Observable<any> {
    return createInvestmentPlanVirtualDepositTransfer({
      pathParams: { investmentPlanIdentifier: action.data.investmentPlan.identifier },
      payload: {
        amount: action.data.amount,
        paymentMethod: EPaymentMethod.Virtual,
        paymentTarget: EPaymentTarget.VirtualPortfolioManagement
      }
    }).execute();
  }

  private _createDepositTransfer(action: CreateDepositInvestAction): Observable<any> {
    return createInvestmentPlanDepositTransfer({
      pathParams: { investmentPlanIdentifier: action.data.investmentPlan.identifier },
      payload: {
        amount: action.data.amount,
        recurringFrequencyTextId: action.data.frequency?.textId || 'Once'
      }
    }).execute();
  }
}
