import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CleanInvestmentPlanQuestionnaireSessionCommand } from '@commands/backend';
import { AnalyticsService } from '@services/analytics.service';
import { UserOnboardingStatusService } from '@services/user-onboarding-status.service';
import { NzModalRef } from 'ng-zorro-antd';
import { first } from 'rxjs/operators';
import { OnboardingStoreService } from '@src/app/stores';

@Component({
  selector: 'app-low-or-high-risk-notification-modal',
  templateUrl: './low-or-high-risk-notification.modal.html',
  styleUrls: ['./low-or-high-risk-notification.modal.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LowOrHighRiskNotificationModal implements OnInit {
  @Input() public investmentPlanIdentifier: string;

  public isUserOnboarded: boolean;

  constructor(
    private readonly _userOnboardingStatusService: UserOnboardingStatusService,
    private readonly _nzModalRef: NzModalRef,
    private readonly _cleanInvestmentPlanQuestionnaireSessionCommand: CleanInvestmentPlanQuestionnaireSessionCommand,
    private readonly _analyticsService: AnalyticsService,
    private readonly _onboardingStoreService: OnboardingStoreService
  ) {}

  public ngOnInit(): void {
    this._userOnboardingStatusService.status$.pipe(first()).subscribe((isUserOnboarded) => {
      this.isUserOnboarded = isUserOnboarded;
    });
    this._analyticsService.track({
      type: 'flow',
      flow: {
        type: this._onboardingStoreService.isVirtual ? 'onboarding' : 'subscription',
        title: 'profil de risque - synthese profil redefinition'
      }
    });
  }

  public stopOnboardingProcess(): void {
    this._nzModalRef.close('stop-onboarding-process');
  }

  public redoRiskProfileQuestionnaire(): void {
    this._cleanInvestmentPlanQuestionnaireSessionCommand.execute(this.investmentPlanIdentifier).subscribe(() => {
      this._nzModalRef.close('redo-risk-profile-questionnaire');
    });
  }
}

export type LowOrHighRiskNotificationModalResult = 'stop-onboarding-process' | 'redo-risk-profile-questionnaire';
