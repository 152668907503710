import { Injectable } from '@angular/core';
import { ComputeTargetAmountRequestDTO } from '@backend/dto';
import { computeTemporaryInvestmentPlanTargetAmount } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ComputeTemporaryInvestmentPlanTargetAmountCommand implements ICommand {
  public execute(payload: ComputeTargetAmountRequestDTO): Observable<number> {
    return computeTemporaryInvestmentPlanTargetAmount({ payload }).execute();
  }
}
