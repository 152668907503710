import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OnboardingStoreService } from '@stores';

@Injectable({ providedIn: 'root' })
export class OnboardingGuard implements CanActivate {
  constructor(private readonly _onboardingStoreService: OnboardingStoreService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return !this._onboardingStoreService.onboardingCompleted;
  }
}

@Injectable({ providedIn: 'root' })
export class OnboardingStartGuard implements CanActivate {
  constructor(private readonly _onboardingStoreService: OnboardingStoreService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const hasStepCompleted = this._onboardingStoreService.steps.some((step) => step.status === 'finish');
    return !hasStepCompleted;
  }
}

@Injectable({ providedIn: 'root' })
export class OnboardingQuestionnaireGuard implements CanActivate {
  constructor(private readonly _onboardingStoreService: OnboardingStoreService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const hasAllStepsCompleted = this._onboardingStoreService.steps.every((step) => step.status === 'finish');
    return !hasAllStepsCompleted;
  }
}

@Injectable({ providedIn: 'root' })
export class OnboardingObjectiveChoiceGuard implements CanActivate {
  constructor(private readonly _onboardingStoreService: OnboardingStoreService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const stepCompleted = !!this._onboardingStoreService.steps.find((step, index) => step.status === 'finish' && index === 0);
    return !stepCompleted;
  }
}

@Injectable({ providedIn: 'root' })
export class OnboardingRiskProfileGuard implements CanActivate {
  constructor(private readonly _onboardingStoreService: OnboardingStoreService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const stepCompleted = !!this._onboardingStoreService.steps.find((step, index) => step.status === 'finish' && index === 1);
    return !stepCompleted;
  }
}

@Injectable({ providedIn: 'root' })
export class OnboardingESGGuard implements CanActivate {
  constructor(private readonly _onboardingStoreService: OnboardingStoreService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const stepCompleted = !!this._onboardingStoreService.steps.find((step, index) => step.status === 'finish' && index === 2);
    return !stepCompleted;
  }
}

@Injectable({ providedIn: 'root' })
export class OnboardingQuestionnaireESGGuard implements CanActivate {
  constructor(private readonly _onboardingStoreService: OnboardingStoreService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const stepCompleted =
      !!this._onboardingStoreService.steps.find((step, index) => step.status === 'finish' && index === 2) ||
      !!this._onboardingStoreService.esgCompleted;
    return !stepCompleted;
  }
}

@Injectable({ providedIn: 'root' })
export class OnboardingManagementPreferencesGuard implements CanActivate {
  constructor(private readonly _onboardingStoreService: OnboardingStoreService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const stepCompleted = !!this._onboardingStoreService.steps.find((step, index) => step.status === 'finish' && index === 3);
    return !stepCompleted;
  }
}
