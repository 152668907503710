import { Injectable } from '@angular/core';
import { InvestmentPlanCreateRequestDTO } from '@backend/dto';
import { createInvestmentPlan } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { OnboardingStoreService } from '@stores';
import { switchMap } from 'rxjs/operators';
import { GetQworkflowSessionCommand } from '@commands/backend/get-qworkflow-session.command';
import { CloseQworkflowSessionCommand } from '@commands/backend/close-qworkflow-session.command';
import { Router } from '@angular/router';
import { combineLatest, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CreateInvestmentPlanCommand implements ICommand {
  constructor(
    private readonly _onboardingStoreService: OnboardingStoreService,
    private readonly _getQworkflowSessionCommand: GetQworkflowSessionCommand,
    private readonly _closeQworkflowSessionCommand: CloseQworkflowSessionCommand,
    private readonly _router: Router
  ) {}

  public execute(): void {
    this._getQworkflowSessionCommand
      .execute({
        planIdentifier: this._onboardingStoreService.investmentPlanIdentifier,
        onboardingType: this._onboardingStoreService.onboardingType
      })
      .pipe(
        switchMap((response) => {
          return combineLatest([
            of(response),
            createInvestmentPlan({
              payload: this._onboardingStoreService.createInvestmentPlanPayload as InvestmentPlanCreateRequestDTO
            }).execute()
          ]);
        }),
        switchMap(([response, _]) => {
          return this._closeQworkflowSessionCommand.execute(response.questionnaireSessionId);
        })
      )
      .subscribe(() => {
        this._router.navigate(['webclient', 'mandates']);
      });
  }
}
