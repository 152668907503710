import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvestmentPlanTemporaryVirtualRequestDTO, InvestmentPlanTemporaryVirtualResponseDTO } from '@backend/dto';
import { createTemporaryVirtualInvestmentPlan, updateTemporaryVirtualInvestmentPlan } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { OnboardingStepperService } from '@services/onboarding-stepper.service';
import { OnboardingState, OnboardingStoreService } from '@stores';
import { addMonths, addYears, formatISO } from 'date-fns';
import { first, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CreateOrUpdateTemporaryInvestmentPlanCommand implements ICommand {
  constructor(
    private readonly _onboardingStoreService: OnboardingStoreService,
    private readonly _router: Router,
    private readonly _onboardingStepperService: OnboardingStepperService
  ) {}

  public execute(activatedRoute?: ActivatedRoute): void {
    this._onboardingStoreService.state$
      .pipe(
        first(),
        switchMap((state: OnboardingState) => {
          // if horizon value less than one
          // Has to add month to currentDate
          const payload: InvestmentPlanTemporaryVirtualRequestDTO = {
            initialAmount: state.initialAmount,
            isVirtual: state.isVirtual,
            planTypeTextId: state.objective.textId,
            targetAmount: state.targetAmount,
            targetDate:
              state.horizon.value < 1
                ? formatISO(addMonths(new Date(), state.horizon.value * 12))
                : formatISO(addYears(new Date(), state.horizon.value))
          };

          switch (state.onboardingType) {
            case 'create':
              return createTemporaryVirtualInvestmentPlan({ payload }).execute();
            case 'update':
              return updateTemporaryVirtualInvestmentPlan({
                payload,
                pathParams: {
                  investmentPlanIdentifier: state.investmentPlanIdentifier
                }
              }).execute();
            default: // TODO throw an error
          }
        })
      )
      .subscribe((response: InvestmentPlanTemporaryVirtualResponseDTO) => {
        this._onboardingStoreService.investmentPlanIdentifier = response.investmentPlanIdentifier;
        this._onboardingStepperService.nextStep();
        this._onboardingStoreService.updateStepsState(this._onboardingStepperService.steps);
        this._router.navigate(['risk-profile'], { relativeTo: activatedRoute.parent.parent });
      });
  }
}
