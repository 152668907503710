import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AnalyticsStore } from './analytics.store';
import { AnalyticsState } from './analytics.state';

@Injectable({ providedIn: 'root' })
export class AnalyticsQuery extends Query<AnalyticsState> {
  constructor(protected analyticsStore: AnalyticsStore) {
    super(analyticsStore);
  }
}
