import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { UsersOnboardingResponseDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class OnboardingRequest extends Request<never, UsersOnboardingResponseDTO> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      path: `${options?.version ?? '/v1'}/users/me/onboarding`,
      ignoreLoader: true
    });
  }

  public execute(): Observable<UsersOnboardingResponseDTO> {
    return super
      .execute()
      .pipe(map((response: HttpResponse<UsersOnboardingResponseDTO>) => response.body));
  }
}

export function getUserOnboarding(options?: RequestOptions<never>): OnboardingRequest {
  return new OnboardingRequest(options);
}
