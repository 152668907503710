import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CustomTranslateLoaderFactory, initializers } from '@app/factories';
import { components } from '@components';
import { akitaConfig } from '@datorama/akita';
import { environment } from '@environments/environment';
import { ToPercentagePipe } from '@gambit/ng-resources';
import { interceptors } from '@interceptors';
import { pipes } from '@modules/shared/pipes';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { WINDOW_PROVIDERS } from '@services';
import { NgZorroAntdModule, NZ_I18N, fr_FR } from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { NgIdleModule } from '@ng-idle/core';

registerLocaleData(fr);
akitaConfig({ resettable: true });

const imports: any[] = [
  AppRoutingModule,
  NgZorroAntdModule,
  FormsModule,
  HttpClientModule,
  BrowserAnimationsModule,
  TranslateModule.forRoot({
    loader: {
      deps: [HttpClient],
      provide: TranslateLoader,
      useFactory: CustomTranslateLoaderFactory
    }
  }),
  NgIdleModule.forRoot()
];

if (!environment.production) {
  imports.push(AkitaNgDevtools);
}

@NgModule({
  declarations: [AppComponent, components],
  imports,
  providers: [
    {
      provide: NZ_I18N,
      useValue: fr_FR
    },
    initializers,
    interceptors,
    ToPercentagePipe,
    pipes,
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
