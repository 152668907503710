import { Injectable } from '@angular/core';
import { IconModel } from '@app/icons';

export class SVGIcon {
  public svg: SVGElement;

  constructor(public content: string) {}
}

@Injectable({ providedIn: 'root' })
export class IconsRegistryService {
  private _registry = new Map<string, SVGIcon>();
  private _xmlSerializer = new XMLSerializer();

  public registerIcons(icons: IconModel[]): void {
    icons.forEach((icon: IconModel) => this._registry.set(icon.name, new SVGIcon(icon.data)));
  }

  public getIcon(iconName: string): SVGElement | undefined {
    const icon = this._registry.get(iconName);

    if (!icon) {
      console.warn(`Couldn't find icon with name ${iconName}, did you add it to the registry?`);
      return undefined;
    }

    return this._addAttributesToSVGIcon(icon);
  }

  public hasIcon(iconName: string): boolean {
    return this._registry.has(iconName);
  }

  private get _lazyXmlSerializer(): XMLSerializer {
    if (!this._xmlSerializer) {
      this._xmlSerializer = new XMLSerializer();
    }

    return this._xmlSerializer;
  }

  private _fromSVGElementToString(element: SVGElement): string {
    let content = element.outerHTML;

    if (content === undefined) {
      content = this._lazyXmlSerializer.serializeToString(element);
    }

    return content;
  }

  private _fromStringToSVGElement(content: string): SVGElement {
    const div = document.createElement('DIV');
    div.innerHTML = content;
    const svg = div.querySelector('svg') as SVGElement;

    if (!svg) {
      throw new Error('<svg> tag not found');
    }

    return svg;
  }

  private _setSVGAttributes(element: SVGElement): SVGElement {
    if (!element.getAttribute('xmlns')) {
      element.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    }
    element.setAttribute('fit', '');
    element.setAttribute('height', '100%');
    element.setAttribute('width', '100%');
    element.setAttribute('preserveAspectRatio', 'xMidYMid meet');
    // Disable IE11 default behavior to make SVGs focusable.
    element.setAttribute('focusable', 'false');

    return element;
  }

  private _addAttributesToSVGIcon(icon: SVGIcon): SVGElement {
    const svg = this._fromStringToSVGElement(icon.content);
    this._setSVGAttributes(svg);
    return svg;
  }
}
