import { Injectable } from '@angular/core';
import { getInvestmentPlansReports } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { DocumentsStoreService } from '@stores';

@Injectable({ providedIn: 'root' })
export class GetUserInvestmentPlansAvailableReportsCommand implements ICommand {
  constructor(private readonly _documentsStoreService: DocumentsStoreService) {}

  public execute(): void {
    getInvestmentPlansReports()
      .execute()
      .subscribe((response) => this._documentsStoreService.resetWithNewValues(response));
  }
}
