import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { InvestmentPlanDepositTransferRequestDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlanDepositTransferRequest extends Request<
  InvestmentPlanDepositTransferRequestDTO,
  void
> {
  constructor(options?: RequestOptions<InvestmentPlanDepositTransferRequestDTO>) {
    super({
      ...options,
      method: 'POST',
      path: `/broad${options?.version ?? '/v1'}/users/me/investment_plans/${
        options?.pathParams?.investmentPlanIdentifier
      }/transfers/deposit`,
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

  public execute(): Observable<void> {
    return super.execute().pipe(map((response: HttpResponse<any>) => response.body));
  }
}

export function createInvestmentPlanDepositTransfer(
  options?: RequestOptions<InvestmentPlanDepositTransferRequestDTO>
): InvestmentPlanDepositTransferRequest {
  return new InvestmentPlanDepositTransferRequest(options);
}
