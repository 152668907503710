import { Pipe, PipeTransform } from '@angular/core';
import { IconName } from '@app/icons';

@Pipe({ name: 'investmentPlanTypeIconName' })
export class InvestmentPlanTypeIconNamePipe implements PipeTransform {
  public transform(value: string): IconName {
    switch (value) {
      case 'PlanRetirement':
        return 'goal-retirement';
      case 'ValorizeCapital':
        return 'goal-loved';
      case 'TransmitCapital':
        return 'goal-buy-something';
      case 'GenerateIncome':
        return 'goal-just-invest';
    }
  }
}
