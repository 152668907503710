import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AnalyticsService } from '@services/analytics.service';
import { NzModalRef } from 'ng-zorro-antd';
import { OnboardingStoreService } from '@src/app/stores';

@Component({
  selector: 'app-confirm-subscription-notification-modal',
  templateUrl: './confirm-subscription-notification.modal.html',
  styleUrls: ['./confirm-subscription-notification.modal.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmSubscriptionNotificationModal implements OnInit {
  constructor(
    private readonly _nzModalRef: NzModalRef,
    private readonly _analyticsService: AnalyticsService,
    private readonly _onboardingStoreService: OnboardingStoreService
  ) {}

  public ngOnInit(): void {
    this._analyticsService.track({
      type: 'flow',
      flow: {
        type: this._onboardingStoreService.confirmUpdateVirtualMandateProposition ? 'onboarding' : 'subscription',
        title: 'synthèse confirmation',
        isLastStep: true
      }
    });
  }

  public confirmSubscription(): void {
    this._nzModalRef.close(true);
  }
}
