import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlanVirtualRecurrentTransferDeleteRequest extends Request<never, void> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      method: 'DELETE',
      path: `${options?.version ?? '/v1'}/users/me/investment_plans/${
        options?.pathParams?.investmentPlanIdentifier
      }/transfers/recurrent/virtual`
    });
  }

  public execute(): Observable<void> {
    return super.execute().pipe(map((response: HttpResponse<any>) => response.body));
  }
}

export function deleteInvestmentPlanVirtualRecurrentTransfer(
  options?: RequestOptions<never>
): InvestmentPlanVirtualRecurrentTransferDeleteRequest {
  return new InvestmentPlanVirtualRecurrentTransferDeleteRequest(options);
}
