import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DocumentsStore } from './documents.store';
import { DocumentState } from './documents.model';

@Injectable({ providedIn: 'root' })
export class DocumentsQuery extends QueryEntity<DocumentState> {
  constructor(protected documentsStore: DocumentsStore) {
    super(documentsStore);
  }
}
