import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ReferencesState } from './references.state';

function createInitialState(): ReferencesState {
  return {
    investmentPlanTypes: [],
    objectivePercentageStatuses: [],
    profiles: [],
    recurrences: [],
    esgParameters: {}
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'references' })
export class ReferencesStore extends Store<ReferencesState> {
  constructor() {
    super(createInitialState());
  }
}
