import { Injectable } from '@angular/core';
import { ICommand } from '@commands/interfaces';
import { AnalyticsStoreService, TokenStoreService, UserStoreService } from '@stores';

@Injectable({ providedIn: 'root' })
export class ClearUserSessionDataCommand implements ICommand {
  constructor(
    private readonly _tokenStoreService: TokenStoreService,
    private readonly _userStoreService: UserStoreService,
    private readonly _analyticsStoreService: AnalyticsStoreService
  ) {}

  public execute(): void {
    this._tokenStoreService.resetStore();
    this._userStoreService.resetStore();
    this._analyticsStoreService.resetStore();
  }
}
