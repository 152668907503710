import { Injectable } from '@angular/core';
import { InvestmentPlanPreSubscriptionContractSynthesisResponseDTO } from '@backend/dto';
import { getInvestmentPlanPreSubscriptionContractSynthesis } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GetUserInvestmentPlanPreSubscriptionContractSynthesisCommand implements ICommand {
  public execute(
    investmentPlanIdentifier: string
  ): Observable<InvestmentPlanPreSubscriptionContractSynthesisResponseDTO> {
    return getInvestmentPlanPreSubscriptionContractSynthesis({
      pathParams: { investmentPlanIdentifier }
    }).execute();
  }
}
