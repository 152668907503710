import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  public isLoading$: Observable<boolean>;

  private _isLoading$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isLoading$ = this._isLoading$.asObservable();
  }

  public updateLoadingStatus(isLoading: boolean): void {
    this._isLoading$.next(isLoading);
  }
}
