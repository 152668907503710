<!-- ADMIN HEADER -->
<div id="admin-header-container" *ngIf="isLoggedAsAdmin">
  <span class="icon">
    <i nz-icon nzType="lock"></i>
  </span>
  <div class="message">
    {{ 'app.header.admin.message' | translate }}
  </div>
  <button nz-button nzType="primary" class="no-radius admin-disconnect" (click)="disconnectAdmin()">
    {{ 'app.header.admin.cta.disconnect' | translate }}
  </button>
</div>

<!-- HEADER MENU -->
<div id="header-container" *ngIf="isLogged">
  <div id="header-logo">
    <img src="assets/images/MyMandate-logo.svg" (click)="navigateToMandates()" />
  </div>
  <ul class="header-menu" nz-menu nzMode="horizontal" nzSelectable="false" *ngIf="isUserOnboarded">
    <ng-container *ngFor="let menuItem of menuItems">
      <li
        nz-menu-item
        nzMatchRouter
        class="header-menu-item"
        [routerLink]="['webclient', menuItem.redirectTo]"
        (mouseover)="preloadBundle(menuItem)"
      >
        <a class="header-menu-item-link">
          {{ menuItem.label }}
        </a>
      </li>
    </ng-container>
  </ul>
  <button *ngIf="isDevMode" nz-button nzType="dashed" (click)="disconnectUser()">DISCONNECT</button>
</div>
