import { Injectable } from '@angular/core';
import { getInvestmentPlanAggregatedTNA } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { InvestmentPlan, InvestmentPlansStoreService } from '@stores';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GetInvestmentPlanAggregatedTnaCommand implements ICommand {
  constructor(private readonly _investmentPlansStoreService: InvestmentPlansStoreService) {}

  public execute(investmentPlan: InvestmentPlan): Observable<void> {
    return investmentPlan.isVirtual
      ? of(undefined)
      : getInvestmentPlanAggregatedTNA({
          pathParams: { investmentPlanIdentifier: investmentPlan.investmentPlanIdentifier }
        })
          .execute()
          .pipe(
            switchMap((response) => {
              this._investmentPlansStoreService.updateInvestmentPlanAggregatedTna(
                investmentPlan.investmentPlanIdentifier,
                response
              );
              return of(undefined);
            })
          );
  }
}
