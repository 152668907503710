import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toAmount' })
export class AmountPipe implements PipeTransform {
  public transform(
    value: number,
    currency: string,
    showSign: boolean = false,
    showDecimal: boolean = true
  ): string {
    const sign = showSign && value > 0 ? '+' : '';
    return sign + this._formatNumber(value, showDecimal) + currency;
  }

  private _formatNumber(value: number, showDecimal: boolean): string {
    return (value ? value.toFixed(showDecimal ? 2 : 0) : showDecimal ? '0.00' : '0').replace(
      /(\d)(?=(\d{3})+(?!\d))/g,
      '$1 '
    );
  }
}
