import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import {
  InvestmentPlanTemporaryVirtualRequestDTO,
  InvestmentPlanTemporaryVirtualResponseDTO
} from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class TemporaryVirtualInvestmentPlanCreateRequest extends Request<
  InvestmentPlanTemporaryVirtualRequestDTO,
  InvestmentPlanTemporaryVirtualResponseDTO
> {
  constructor(options?: RequestOptions<InvestmentPlanTemporaryVirtualRequestDTO>) {
    super({
      ...options,
      method: 'POST',
      path: `${options?.version ?? '/v2'}/users/me/investment_plans/temporary_virtual_plan`,
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

  public execute(): Observable<InvestmentPlanTemporaryVirtualResponseDTO> {
    return super
      .execute()
      .pipe(
        map((response: HttpResponse<InvestmentPlanTemporaryVirtualResponseDTO>) => response.body)
      );
  }
}

export function createTemporaryVirtualInvestmentPlan(
  options?: RequestOptions<InvestmentPlanTemporaryVirtualRequestDTO>
): TemporaryVirtualInvestmentPlanCreateRequest {
  return new TemporaryVirtualInvestmentPlanCreateRequest(options);
}
