import { Injectable } from '@angular/core';
import { closeQworkflowSession } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CloseQworkflowSessionCommand implements ICommand {
  public execute(sessionId: string): Observable<unknown> {
    return closeQworkflowSession({ pathParams: { sessionId } }).execute();
  }
}
