<div id="low-or-high-risk-notification-modal__container">
  <h3 class="title">
    {{'modals.low-or-high-risk-notification.title' | translate}}
  </h3>
  <p class="message">
    {{'modals.low-or-high-risk-notification.message' | translate}}
  </p>
  <div class="actions">
    <button
      nz-button
      e2e-id="redo-risk-profile-questionnaire"
      (click)="redoRiskProfileQuestionnaire()"
    >
      {{'modals.low-or-high-risk-notification.cta.redo' | translate}}
    </button>
    <button
      nz-button
      nzType="primary"
      e2e-id="stop-onboarding-process"
      *ngIf="isUserOnboarded"
      (click)="stopOnboardingProcess()"
    >
      {{'common.cta.understand' | translate}}
    </button>
  </div>
</div>
