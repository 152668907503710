import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { LogoutSessionRequestDTO } from '@backend/dto/auth.dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class LogoutSessionRequest extends Request<LogoutSessionRequestDTO, void> {
  constructor(options?: RequestOptions<LogoutSessionRequestDTO>) {
    super({
      ...options,
      method: 'POST',
      path: '/auth/logout_session',
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

  public execute(): Observable<void> {
    return super.execute().pipe(map((response: HttpResponse<void>) => response.body));
  }
}

export function logout(options?: RequestOptions<LogoutSessionRequestDTO>): LogoutSessionRequest {
  return new LogoutSessionRequest(options);
}
