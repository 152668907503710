import { Injectable } from '@angular/core';
import { EMandatesUIState } from '@enums';
import { Observable } from 'rxjs';
import { AppStore } from './app.store';
import { AppQuery } from './app.query';

@Injectable({ providedIn: 'root' })
export class AppStoreService {
  constructor(private readonly _appStore: AppStore, private readonly _appQuery: AppQuery) {}

  get mandatesUIStateStatus$(): Observable<EMandatesUIState> {
    return this._appQuery.select((state) => state.mandatesUIState);
  }

  public updateMandatesUIState(mandatesUIState: EMandatesUIState): void {
    this._appStore.update({ mandatesUIState });
  }
}
