import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlanPreSubscriptionDeleteRequest extends Request<never, void> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      method: 'DELETE',
      path: `/broad${options?.version ?? '/v1'}/users/me/investment_plans/${
        options?.pathParams?.investmentPlanIdentifier
      }/pre_subscription_cancellation`
    });
  }

  public execute(): Observable<void> {
    return super.execute().pipe(map((response: HttpResponse<any>) => response.body));
  }
}

export function deleteInvestmentPlanPreSubscription(
  options?: RequestOptions<never>
): InvestmentPlanPreSubscriptionDeleteRequest {
  return new InvestmentPlanPreSubscriptionDeleteRequest(options);
}
