import { Injectable } from '@angular/core';
import { MeResponseDTO } from '@backend/dto';
import { UserStore } from './user.store';
import { UserQuery } from './user.query';
import { AccessControlFunction } from '@app/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserStoreService {
  constructor(private readonly _userStore: UserStore, private readonly _userQuery: UserQuery) {}

  get birthDate(): string {
    const state = this._userQuery.getValue();
    return state?.user?.birthDate || state?.userIdentity?.birthDate;
  }

  public updateUserState(data: Partial<MeResponseDTO>): void {
    this._userStore.update((state) => ({ ...state, ...data }));
  }

  public hasAccessControlFunction(accessControlFunction: AccessControlFunction): boolean {
    return Boolean(
      this._userQuery.getValue()?.userAccess?.userRoles?.find((userRole) => userRole.toLowerCase() === accessControlFunction.toLowerCase())
    );
  }

  public hasAccessControlFunction$(accessControlFunction: AccessControlFunction): Observable<boolean> {
    return this._userQuery
      .select((state) => state?.userAccess?.userRoles)
      .pipe(
        map((userRoles) => {
          if (!userRoles) {
            return false;
          }

          return Boolean(userRoles.find((userRole) => userRole.toLowerCase() === accessControlFunction.toLowerCase()));
        })
      );
  }

  public hasAccessControlFunctions$(accessControlFunction: AccessControlFunction[]): Observable<boolean> {
    return this._userQuery
      .select((state) => state?.userAccess?.userRoles)
      .pipe(
        map((userRoles) => {
          if (!userRoles) {
            return false;
          }

          return accessControlFunction.some((fn) => Boolean(userRoles.find((userRole) => userRole.toLowerCase() === fn.toLowerCase())));
        })
      );
  }

  public resetStore(): void {
    this._userStore.reset();
  }
}
