import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { InvestmentPlanVirtualWithdrawTransferRequestDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlanVirtualWithdrawTransferRequest extends Request<
  InvestmentPlanVirtualWithdrawTransferRequestDTO,
  string
> {
  constructor(options?: RequestOptions<InvestmentPlanVirtualWithdrawTransferRequestDTO>) {
    super({
      ...options,
      method: 'POST',
      path: `${options?.version ?? '/v1'}/users/me/investment_plans/${
        options?.pathParams?.investmentPlanIdentifier
      }/transfers/withdraw/virtual`,
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

  public execute(): Observable<string> {
    return super.execute().pipe(map((response: HttpResponse<string>) => response.body));
  }
}

export function createInvestmentPlanVirtualWithdrawTransfer(
  options?: RequestOptions<InvestmentPlanVirtualWithdrawTransferRequestDTO>
): InvestmentPlanVirtualWithdrawTransferRequest {
  return new InvestmentPlanVirtualWithdrawTransferRequest(options);
}
