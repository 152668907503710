import { Injectable } from '@angular/core';
import { AnalyticsStepObject } from '@app/types/analytics.type';
import { Observable } from 'rxjs';
import { AnalyticsQuery } from './analytics.query';
import { AnalyticsStore, createAnalyticsInitialState } from './analytics.store';
import { CustomerInfoAnalyticsState } from './analytics.state';

@Injectable({ providedIn: 'root' })
export class AnalyticsStoreService {
  constructor(
    private readonly _analyticsStore: AnalyticsStore,
    private readonly _analyticsQuery: AnalyticsQuery
  ) {}

  get customerInfo(): CustomerInfoAnalyticsState {
    return {
      customerId: this._analyticsQuery.getValue()?.customerId,
      customerRole: this._analyticsQuery.getValue()?.customerRole
    } as CustomerInfoAnalyticsState;
  }

  get customerInfo$(): Observable<CustomerInfoAnalyticsState> {
    return this._analyticsQuery.select((state) => ({
      customerId: state.customerId,
      customerRole: state.customerRole
    }));
  }

  get stepNumber(): number {
    return this._analyticsQuery.getValue()?.stepNumber;
  }

  set stepNumber(count: number) {
    this._analyticsStore.update({ stepNumber: count });
  }

  get steps(): AnalyticsStepObject[] {
    return this._analyticsQuery.getValue()?.steps;
  }

  set steps(steps: AnalyticsStepObject[]) {
    this._analyticsStore.update({ steps });
  }

  get macroSteps(): AnalyticsStepObject[] {
    return this._analyticsQuery.getValue()?.macroSteps;
  }

  set macroSteps(macroSteps: AnalyticsStepObject[]) {
    this._analyticsStore.update({ macroSteps });
  }

  public setCustomerInfo(newState: CustomerInfoAnalyticsState): void {
    this._analyticsStore.update((state) => ({ ...state, ...newState }));
  }

  public resetStore(): void {
    this._analyticsStore.update((state) => ({
      customerId: state.customerId,
      customerRole: state.customerRole,
      ...createAnalyticsInitialState(),
      macroSteps: []
    }));
  }
}
