import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

export function ApplicationLanguageInitializerFactory(translateService: TranslateService, injector: Injector) {
  return () => {
    return new Promise<any>((resolve, reject) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      // @ts-ignore
      const language = environment.default.languagesMapping[environment.default.defaultLanguage];

      locationInitialized.then(() => {
        translateService.setDefaultLang(language);
        translateService
          .use(language)
          .pipe(first())
          .subscribe(
            () => {},
            (error) => {
              console.warn(`Unable to initialize application language with ${language}`);
              console.error(error);
            },
            () => resolve(null)
          );
      });
    });
  };
}
