import { Injectable } from '@angular/core';
import {
  getTemporaryInvestmentPlanRiskProfile,
  getTemporaryInvestmentPlanTargetAllocation
} from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { InvestmentPlanAllocation, Profile, ReferencesStoreService } from '@stores';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class FetchOnboardingProposalDataCommand implements ICommand {
  constructor(private readonly _referencesStoreService: ReferencesStoreService) {}

  public execute(
    investmentPlanIdentifier: string
  ): Observable<[InvestmentPlanAllocation, Profile]> {
    return combineLatest([
      getTemporaryInvestmentPlanTargetAllocation({
        pathParams: { investmentPlanIdentifier }
      }).execute(),
      getTemporaryInvestmentPlanRiskProfile({ pathParams: { investmentPlanIdentifier } }).execute()
    ]).pipe(
      map(([targetAllocation, riskProfileTextId]) => {
        targetAllocation.individualAssets = targetAllocation.individualAssets.map(
          (individualAsset, index) => {
            individualAsset.color =
              environment.chartColorsPalette[index % environment.chartColorsPalette.length];
            return individualAsset;
          }
        );

        return [
          targetAllocation,
          this._referencesStoreService.getInvestmentPlanProfile(riskProfileTextId)
        ];
      })
    );
  }
}
