import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import {
  InitialInvestmentMatrixDTO,
  InvestmentPlanSimulationCashFlowRequestDTO,
  InvestmentPlanSimulationCashFlowResponseDTO
} from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlanSimulationCashFlowRequest extends Request<
  InvestmentPlanSimulationCashFlowRequestDTO,
  InitialInvestmentMatrixDTO
> {
  constructor(options?: RequestOptions<InvestmentPlanSimulationCashFlowRequestDTO>) {
    super({
      ...options,
      method: 'POST',
      path: `${options?.version ?? '/v2'}/users/me/investment_plans/${
        options?.pathParams?.investmentPlanIdentifier
      }/simulation_cash_flow`,
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

  public execute(): Observable<InitialInvestmentMatrixDTO> {
    return super.execute().pipe(
      map((response: HttpResponse<InvestmentPlanSimulationCashFlowResponseDTO>) => response.body),
      map((response) => response.initialInvestmentMatrixResults)
    );
  }
}

export function getInvestmentPlanSimulationCashFlow(
  options?: RequestOptions<InvestmentPlanSimulationCashFlowRequestDTO>
): InvestmentPlanSimulationCashFlowRequest {
  return new InvestmentPlanSimulationCashFlowRequest(options);
}
