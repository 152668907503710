import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { UserInvestmentPlansHistoricalPerformanceResponseDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlansHistoricalPerformanceRequest extends Request<
  never,
  UserInvestmentPlansHistoricalPerformanceResponseDTO[]
> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      path: `${options?.version ?? '/v2'}/users/me/investment_plans/historical_performance`
    });
  }

  public execute(): Observable<UserInvestmentPlansHistoricalPerformanceResponseDTO[]> {
    return super
      .execute()
      .pipe(
        map(
          (response: HttpResponse<UserInvestmentPlansHistoricalPerformanceResponseDTO[]>) =>
            response.body
        )
      );
  }
}

export function getInvestmentPlansHistoricalPerformance(
  options?: RequestOptions<never>
): InvestmentPlansHistoricalPerformanceRequest {
  return new InvestmentPlansHistoricalPerformanceRequest(options);
}
