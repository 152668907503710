<div id="container">
  <nz-input-group [nzSuffix]="suffixIcon" [ngClass]="className">
    <input
      nz-input
      type="text"
      nzSize="large"
      [placeholder]="amountPlaceholder"
      [formControl]="amountInput"
      [formatter]="[moneyValueConverter]"
    />
  </nz-input-group>
  <ng-template #suffixIcon>
    <span>€</span>
  </ng-template>
</div>
