import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { InvestmentPlanState } from './investment-plans.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'investment-plans',
  idKey: 'investmentPlanIdentifier'
})
export class InvestmentPlansStore extends EntityStore<InvestmentPlanState> {
  constructor() {
    super({ updated: false });
  }
}
