import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';

class DownloadInvestmentPlansOperationsDetailsFileRequest extends Request<
  never,
  HttpResponse<string>
> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      path: `/broad${options?.version ?? '/v2'}/me/investment_plans/activities_details_file`,
      format: 'blob'
    });
  }

  public execute(): Observable<HttpResponse<string>> {
    return super.execute();
  }
}

export function getInvestmentPlansOperationsDetailsFile(
  options?: RequestOptions<never>
): DownloadInvestmentPlansOperationsDetailsFileRequest {
  return new DownloadInvestmentPlansOperationsDetailsFileRequest(options);
}
