import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LanguageHttpInterceptor } from './language.interceptor';
import { LoaderHttpInterceptor } from './loader.interceptor';
import { TokenHttpInterceptor } from './token.interceptor';
import { ErrorHttpInterceptor } from './error.interceptor';

export const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    multi: true,
    useClass: ErrorHttpInterceptor
  },
  {
    provide: HTTP_INTERCEPTORS,
    multi: true,
    useClass: TokenHttpInterceptor
  },
  {
    provide: HTTP_INTERCEPTORS,
    multi: true,
    useClass: LoaderHttpInterceptor
  },
  {
    provide: HTTP_INTERCEPTORS,
    multi: true,
    useClass: LanguageHttpInterceptor
  }
];

export * from './token.interceptor';
export * from './loader.interceptor';
export * from './language.interceptor';
export * from './error.interceptor';
