import { HttpClient } from '@angular/common/http';
import { ApplicationConfigurationState, ApplicationConfigurationStoreService } from '@stores';
import { environment } from '@environments/environment';

export function ApplicationConfigurationLoaderFactory(
  httpClient: HttpClient,
  applicationConfigurationStoreService: ApplicationConfigurationStoreService
) {
  return () => {
    return httpClient
      .get(`assets/config.json?v=${environment.applicationVersion}`)
      .toPromise()
      .then((response: ApplicationConfigurationState) => {
        applicationConfigurationStoreService.update(response);
        return undefined;
      });
  };
}
