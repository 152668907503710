import { Injectable } from '@angular/core';
import { UsersOnboardingResponseDTO } from '@backend/dto';
import { getUserOnboarding } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GetUserOnboardingCommand implements ICommand {
  public execute(): Observable<UsersOnboardingResponseDTO> {
    return getUserOnboarding().execute();
  }
}
