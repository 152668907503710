import { Injectable } from '@angular/core';
import { InvestmentPlanContractSynthesisResponseDTO } from '@backend/dto';
import { getInvestmentPlanContractSynthesis } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { InvestmentPlansStoreService } from '@stores';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GetUserInvestmentPlanContractSynthesisCommand implements ICommand {
  constructor(private readonly _investmentPlansStoreService: InvestmentPlansStoreService) {}

  public execute(
    investmentPlanIdentifier: string
  ): Observable<InvestmentPlanContractSynthesisResponseDTO> {
    return getInvestmentPlanContractSynthesis({
      pathParams: { investmentPlanIdentifier }
    })
      .execute()
      .pipe(
        tap((response) =>
          this._investmentPlansStoreService.updateInvestmentPlanContractSynthesis(
            investmentPlanIdentifier,
            response
          )
        )
      );
  }
}
