import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { OnboardingState } from './onboarding.state';
import { OnboardingStore } from './onboarding.store';

@Injectable({ providedIn: 'root' })
export class OnboardingQuery extends Query<OnboardingState> {
  constructor(protected onboardingStore: OnboardingStore) {
    super(onboardingStore);
  }
}
