import { ESGParametersDTO } from '@src/app/backend/dto';

export type Country = {
  name: string;
  textId: string;
  callingCode: string;
  isEligible: boolean;
};

export type Nationality = {
  name: string;
  textId: string;
};

export type Profile = {
  name: string;
  textId: string;
  shortDescription: string;
  longDescription: string;
  level: number;
  colorIndex: any; // NULL value receive from the backend
  profileTextId: string;
  gestionProfileDescription: string;
};

export type InvestmentPlanType = {
  textId: string;
  name: string;
  ranking: number;
  highLight: boolean;
};

export type ObjectivePercentageStatus = {
  minBound: number;
  maxBound: number;
  textId: string;
  name: string;
};

export type Recurrence = {
  textId: string;
  description: string;
};

export type PaymentTarget = {
  paymentTargetTextId: string;
  paymentMethod: number;
};

export type Language = {
  textId: string;
  name: string;
  locale: string;
  isDefault: boolean;
};

export type SponsorType = {
  textId: string;
  name: string;
};

export type DepositaryBank = {
  name: string;
  textId: string;
  street: string;
  streetNumber: string;
  city: string;
  zip: string;
  country: string;
  iban: string;
  bic: string;
  state: string;
  countryId: number;
  postCode: string;
  id: number;
};

export type Crisis = {
  name: string;
  description: string;
  textIdentifier: string;
  dateFrom: string;
  dateTo: string;
  sequenceNumber: number;
};

export type ESGParametersStateModel = {
  q2_A_Min?: number;
  q2_A_Med?: number;
  q2_A_Max?: number;
  q2_B_Min?: number;
  q2_B_Med?: number;
  q2_B_Max?: number;
  q2_C_Theme_E?: string;
  q2_C_Theme_S?: string;
  q2_C_Theme_G?: string;
  q3_Val1?: number;
  q3_Val2?: number;
  q3_Val3?: number;
  q3_Val4?: number;
  q3_Val5?: number;
  q2_A_DefaultAnswer?: number;
  q2_B_DefaultAnswer?: number;
  q2_C_DefaultAnswer?: string[];
  q3_DefaultAnswer?: number;
};

export type ReferencesState = {
  profiles: Profile[];
  investmentPlanTypes: InvestmentPlanType[];
  objectivePercentageStatuses: ObjectivePercentageStatus[];
  recurrences: Recurrence[];
  esgParameters: ESGParametersStateModel;
};

export class Frequency {
  private readonly _textId: string;
  private readonly _label: string;

  constructor(data: Recurrence) {
    this._textId = data.textId;
    this._label = data.description;
  }

  get textId(): string {
    return this._textId;
  }

  get label(): string {
    return this._label;
  }
}
