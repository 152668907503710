import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExportType } from '@app/types';
import { Parameters } from '@app/types';
import { getInvestmentPlansOperationsDetailsFile } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { EMimeType } from '@enums';
import { HelpersService } from '@services/helpers.service';
import { format } from 'date-fns';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DownloadUserInvestmentPlanOperationsDetailsFileCommand implements ICommand {
  constructor(private readonly _helpersService: HelpersService) {}

  public execute(data: { exportType: ExportType; ids: string[] }): void {
    let fileName: string;
    let queryParams: Parameters;

    if (data.ids && data.ids.length) {
      queryParams = {
        investmentPlanIdentifiers: encodeURI(data.ids.join(',')),
        typeOfFile: data.exportType
      };
    }

    getInvestmentPlansOperationsDetailsFile({ queryParams })
      .execute()
      .pipe(
        map((response: HttpResponse<string>) => {
          fileName = `${format(new Date(), 'yyyyMMdd')}-operations.${data.exportType}`;
          return response.body;
        })
      )
      .subscribe((response) => {
        this._helpersService.saveFileAs({
          blobData: response,
          fileType: EMimeType[data.exportType],
          fileName
        });
      });
  }
}
