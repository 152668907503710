import { Injectable } from '@angular/core';
import { ICommand } from '@commands/interfaces';
import { AdvisorNotificationModal } from '@modules/modals/advisor-notification/advisor-notification.modal';
import { NzModalService } from 'ng-zorro-antd';

@Injectable({ providedIn: 'root' })
export class ShowAdvisorNotificationModalCommand implements ICommand {
  constructor(private readonly _nzModalService: NzModalService) {}

  public execute(): void {
    this._nzModalService.create<AdvisorNotificationModal>({
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzContent: AdvisorNotificationModal,
      nzClassName: 'advisor-notification-modal'
    });
  }
}
