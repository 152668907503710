import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CreateDepositInvestAction, InvestAction } from '@app/types';
import { EInvestActionType } from '@enums';
import { Frequency, InvestmentPlan } from '@stores';

@Component({
  selector: 'app-investment-plan-deposit',
  templateUrl: './investment-plan-deposit.component.html',
  styleUrls: ['./investment-plan-deposit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestmentPlanDepositComponent {
  @Input()
  public investmentPlan: InvestmentPlan;

  @Output()
  public action = new EventEmitter<InvestAction>();

  private _selectedFrequency: Frequency;
  private _depositAmount: number;

  public onSelectedFrequencyChanged(selectedFrequency: Frequency): void {
    this._selectedFrequency = selectedFrequency;
  }

  public onAmountInputValueChanged(value: number): void {
    this._depositAmount = value;
  }

  public createDeposit(): void {
    if (this._selectedFrequency.textId !== 'None') {
      return this.action.emit({
        type: EInvestActionType.UpdateRecurrentDeposit,
        data: {
          investmentPlan: {
            identifier: this.investmentPlan.investmentPlanIdentifier,
            isVirtual: this.investmentPlan.isVirtual,
            contractName: this.investmentPlan.contractName,
            contractStatus: this.investmentPlan.contract.status,
            colorClassName: this.investmentPlan.colorClassName,
            name: this.investmentPlan.name,
            recurrentDeposit: this.investmentPlan.recurrentDeposit,
            currentAmount: this.investmentPlan.currentAmount,
            frequency: this._selectedFrequency,
            nextRecurrenceDate: this.investmentPlan.nextRecurrenceDate
          },
          amount: this._depositAmount,
          frequency: this._selectedFrequency
        }
      } as CreateDepositInvestAction);
    }

    this.action.emit({
      type: EInvestActionType.CreateDeposit,
      data: {
        investmentPlan: {
          identifier: this.investmentPlan.investmentPlanIdentifier,
          isVirtual: this.investmentPlan.isVirtual,
          contractName: this.investmentPlan.contractName,
          contractStatus: this.investmentPlan.contract.status,
          colorClassName: this.investmentPlan.colorClassName,
          name: this.investmentPlan.name
        },
        amount: this._depositAmount
      }
    } as CreateDepositInvestAction);
  }

  public createSubscription(): void {
    this.action.emit({
      type: EInvestActionType.CreateSubscription,
      data: {
        investmentPlan: this.investmentPlan
      }
    });
  }
}
