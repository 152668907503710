import { Injectable } from '@angular/core';
import { ICommand } from '@commands/interfaces';
import { ClearUserSessionDataCommand } from '@commands/ui/clear-user-session-data.command';
import { NavigateOutsideApplicationCommand } from '@commands/ui/navigate-outside-application.command';
import { ApplicationConfigurationStoreService } from '@stores';

@Injectable({ providedIn: 'root' })
export class NavigateToAuthorizationPageCommand implements ICommand {
  constructor(
    private readonly _clearUserSessionDataCommand: ClearUserSessionDataCommand,
    private readonly _applicationConfigurationStoreService: ApplicationConfigurationStoreService,
    private readonly _navigateOutsideApplicationCommand: NavigateOutsideApplicationCommand
  ) {}

  public execute(): void {
    const state = this._applicationConfigurationStoreService.state;
    const domain = `${window.location.protocol}//${window.location.host}`;
    const url = `${state.APIM_BASE_URL}${state.APIM_AUTHORIZE_URL}?response_type=code&client_id=${state.CLIENT_ID}&state=BirdeeState&scope=openid&redirect_uri=${domain}/webclient/authentication`;

    this._clearUserSessionDataCommand.execute();
    this._navigateOutsideApplicationCommand.execute({ url });
  }
}
