import { chartsEnv } from '@environments/environment.charts';
import * as i18nEnv from '@environments/environment.i18n.json';

// @ts-ignore
const version = require('../../package.json').version;

export const environment = {
  ...i18nEnv,
  ...chartsEnv,
  applicationVersion: version,
  production: true
};
