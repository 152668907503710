import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { UserInvestmentPlansSummaryResponseDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class InvestmentPlansSummaryRequest extends Request<never, UserInvestmentPlansSummaryResponseDTO> {
  constructor(options?: RequestOptions<never>) {
    super({ ...options, path: `${options?.version ?? '/v2'}/users/me/investment_plans/summary` });
  }

  public execute(): Observable<UserInvestmentPlansSummaryResponseDTO> {
    return super
      .execute()
      .pipe(map((response: HttpResponse<UserInvestmentPlansSummaryResponseDTO>) => response.body));
  }
}

export function getInvestmentPlansSummary(
  options?: RequestOptions<never>
): InvestmentPlansSummaryRequest {
  return new InvestmentPlansSummaryRequest(options);
}
