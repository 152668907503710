import {
  svgIconsGoalBuySomething,
  svgIconsGoalJustInvest,
  svgIconsGoalLoved,
  svgIconsGoalRetirement,
  svgIconsIconStateExclamationLinear,
  svgIconsObjectiveStatusCloud,
  svgIconsObjectiveStatusCloudysun,
  svgIconsObjectiveStatusRain,
  svgIconsObjectiveStatusSun,
  svgIconsObjectiveStatusThunder,
  svgIconsIconMenuProfile,
  svgIconsIconThumbUp
} from './svg-icons';

export const svgIcons = [
  svgIconsGoalBuySomething,
  svgIconsGoalJustInvest,
  svgIconsGoalLoved,
  svgIconsGoalRetirement,
  svgIconsIconStateExclamationLinear,
  svgIconsObjectiveStatusCloud,
  svgIconsObjectiveStatusCloudysun,
  svgIconsObjectiveStatusRain,
  svgIconsObjectiveStatusSun,
  svgIconsObjectiveStatusThunder,
  svgIconsIconMenuProfile,
  svgIconsIconThumbUp
];

export * from './svg-icons';
