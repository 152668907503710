import { Injectable } from '@angular/core';
import { getReferences } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { ReferencesStoreService } from '@stores';

@Injectable({ providedIn: 'root' })
export class GetReferencesCommand implements ICommand {
  constructor(private readonly _referencesStoreService: ReferencesStoreService) {}

  public execute(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      getReferences()
        .execute()
        .subscribe(
          (response) => {
            this._referencesStoreService.updateReferencesState(response);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
