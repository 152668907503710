import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { EligibilityState } from './eligibility.state';

function createInitialState(): EligibilityState {
  return {
    isEligible: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'eligibility' })
export class EligibilityStore extends Store<EligibilityState> {
  constructor() {
    super(createInitialState());
  }
}
