import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { TokenAuthResponseDTO } from '@backend/dto';
import { Request } from '@backend/requests/request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class RefreshTokenRequest extends Request<HttpParams, TokenAuthResponseDTO> {
  constructor(options?: RequestOptions<HttpParams>) {
    super({
      ...options,
      method: 'POST',
      path: '/auth/token',
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  public execute(): Observable<TokenAuthResponseDTO> {
    return super
      .execute()
      .pipe(map((response: HttpResponse<TokenAuthResponseDTO>) => response.body));
  }
}

export function refreshToken(options?: RequestOptions<HttpParams>): RefreshTokenRequest {
  return new RefreshTokenRequest(options);
}
