import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EErrorCodes, ETaskStatus } from '@app/enums';
import { GetUserOnboardingCommand } from '@commands/backend/get-user-onboarding.command';
import { EligibilityStoreService, UserStoreService } from '@stores';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { UsersOnboardingResponseDTO } from '@backend/dto';

@Injectable({ providedIn: 'root' })
export class UserOnboardingStatusService {
  public isOnboarded$: Observable<boolean>;

  private _status$ = new BehaviorSubject<boolean>(false);
  private _retrieved = false;
  private _statusAs$: Observable<boolean> = this._status$.asObservable();

  constructor(
    private readonly _getUserOnboardingCommand: GetUserOnboardingCommand,
    private readonly _eligibilityStoreService: EligibilityStoreService,
    private readonly _userStoreService: UserStoreService
  ) {
    this.isOnboarded$ = this._status$.asObservable();
  }

  get status$(): Observable<boolean> {
    return this._retrieved ? this._statusAs$.pipe(first()) : this.updateUserOnboardingStatus();
  }

  get status(): boolean {
    return this._status$.getValue();
  }

  public updateUserOnboardingStatus(): Observable<boolean> {
    return this._getUserOnboardingCommand.execute().pipe(
      map((response: UsersOnboardingResponseDTO) => {
        this._userStoreService.updateUserState({ userIdentity: response.userIdentity });
        return response.tasks;
      }),
      map((tasks) => {
        const task = tasks.find((item) => item.textId === 'BroadCreateContract');
        const isUserOnboarded = task && task.taskStatus === ETaskStatus.Ok;

        this._status$.next(isUserOnboarded);
        this._retrieved = true;
        return isUserOnboarded;
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        switch (errorResponse?.error?.error) {
          case EErrorCodes.InvalidGrantUserNotEligible:
          case EErrorCodes.InvalidGrantUserIsNotEligibleAnymore:
            this._eligibilityStoreService.updateEligibilityError(errorResponse.error.error);
            this._status$.next(false);
            this._retrieved = true;
            return of(false);
          default:
            return throwError(errorResponse);
        }
      })
    );
  }
}
