import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GetQworkflowSessionCommand } from '@commands/backend/get-qworkflow-session.command';
import { ICommand } from '@commands/interfaces';
import { OnboardingStoreService } from '@stores';
import { switchMap } from 'rxjs/operators';
import { CleanInvestmentPlanQuestionnaireSessionCommand } from '../backend';

@Injectable({ providedIn: 'root' })
export class CloseOnboardingProcessCommand implements ICommand {
  constructor(
    private readonly _onboardingStoreService: OnboardingStoreService,
    private readonly _getQworkflowSessionCommand: GetQworkflowSessionCommand,
    private readonly _cleanInvestmentPlanQuestionnaireSessionCommand: CleanInvestmentPlanQuestionnaireSessionCommand,
    private readonly _router: Router
  ) {}

  public execute(): void {
    this._getQworkflowSessionCommand
      .execute({
        planIdentifier: this._onboardingStoreService.investmentPlanIdentifier,
        onboardingType: this._onboardingStoreService.onboardingType
      })
      .pipe(
        switchMap(() => {
          return this._cleanInvestmentPlanQuestionnaireSessionCommand.execute(this._onboardingStoreService.investmentPlanIdentifier);
        })
      )
      .subscribe(() => {
        this._router.navigate(['webclient', 'mandates']);
      });
  }
}
