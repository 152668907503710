import { Injectable } from '@angular/core';
import { NotificationParams } from '@app/types';
import { ENotificationType } from '@enums';
import { NzNotificationService } from 'ng-zorro-antd';

// Duration in ms
const durations = {
  [ENotificationType.Info]: 6000,
  [ENotificationType.Success]: 6000,
  [ENotificationType.Warning]: 6000,
  [ENotificationType.Error]: 10000
};

@Injectable({ providedIn: 'root' })
export class ToastNotificationService {
  constructor(private readonly _nzNotificationService: NzNotificationService) {}

  public success(params: NotificationParams): void {
    this._createNotification(ENotificationType.Success, params);
  }

  public warning(params: NotificationParams): void {
    this._createNotification(ENotificationType.Warning, params);
  }

  public error(params: NotificationParams): void {
    this._createNotification(ENotificationType.Error, params);
  }

  private _createNotification(type: ENotificationType, params: NotificationParams): void {
    this._nzNotificationService.create(type, params.title ?? '', params.message ?? '', {
      nzClass: `toast-notification-${type}`,
      nzStyle: {
        width: '450px',
        marginLeft: '-70px'
      },
      nzDuration: durations.hasOwnProperty(type)
        ? durations[type]
        : durations[ENotificationType.Info]
    });
  }
}
