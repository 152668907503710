import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnalyticsDownloadDocumentInteraction } from '@app/types/analytics.type';
import { GetUserInvestmentPlanReportCommand } from '@commands/backend/get-user-investment-plan-report.command';
import { ICommand } from '@commands/interfaces';
import { AnalyticsService } from '@services/analytics.service';
import { HelpersService } from '@services/helpers.service';
import { Document } from '@stores/documents/documents.model';
import { map } from 'rxjs/operators';
import { parse } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class DownloadUserInvestmentPlanReportCommand implements ICommand {
  constructor(
    private readonly _getUserInvestmentPlanReportCommand: GetUserInvestmentPlanReportCommand,
    private readonly _helpersService: HelpersService,
    private readonly _analyticsService: AnalyticsService
  ) {}

  public execute(document: Document): void {
    let fileName: string;

    this._getUserInvestmentPlanReportCommand
      .execute(document.documentId)
      .pipe(
        map((response: HttpResponse<any>) => {
          fileName = response.headers.has('content-disposition')
            ? this._helpersService.getFilenameFromContentDispositionHeader(
                response.headers.get('content-disposition')
              )
            : `${parse(document.date, 'DD-MM-YYYY', new Date())}-${document.documentName}.pdf`;
          return response.body;
        })
      )
      .subscribe(
        (response) => {
          this._helpersService.saveFileAs({
            blobData: response,
            fileType: 'application/pdf',
            fileName
          });
        },
        (error) => {},
        () => {
          this._analyticsService.track({
            type: 'interaction',
            interaction: {
              type: 'download-document',
              data: { typeDoc: document.documentName } as AnalyticsDownloadDocumentInteraction
            }
          });
        }
      );
  }
}
