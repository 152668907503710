import { Injectable } from '@angular/core';
import { CreateDepositInvestAction } from '@app/types';
import { deleteInvestmentPlanVirtualRecurrentTransfer } from '@backend/requests';
import { ICommand } from '@commands/interfaces';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DeleteInvestmentPlanVirtualRecurrentTransferCommand implements ICommand {
  public execute(action: CreateDepositInvestAction): Observable<void> {
    return deleteInvestmentPlanVirtualRecurrentTransfer({
      pathParams: { investmentPlanIdentifier: action.data.investmentPlan.identifier }
    }).execute();
  }
}
