export enum ETaskStatus {
  Ok,
  NotOk,
  Pending,
  NoData
}

export enum EPaymentMethod {
  DirectBanking,
  EMandate,
  BankTransfer,
  Virtual
}

export enum EPaymentTarget {
  VirtualPortfolioManagement = 'VirtualPortfolioManagement'
}

export enum EMimeType {
  pdf = 'application/pdf',
  csv = 'text/csv'
}

export enum EMandateColorMapping {
  Signed = '#006a8e',
  PendingSignature = '#642f6c',
  Virtual = '#00816d'
}

export enum EInvestActionType {
  CreateDeposit = 'CREATE_DEPOSIT',
  CreateSubscription = 'CREATE_SUBSCRIPTION',
  CreateWithdraw = 'CREATE_WITHDRAW',
  UpdateRecurrentDeposit = 'UPDATE_RECURRENT_DEPOSIT',
  DeleteRecurrentDeposit = 'DELETE_RECURRENT_DEPOSIT'
}

export enum EErrorCodes {
  InvalidGrantUserNotEligible = 'invalid_grant_user_not_eligible',
  InvalidGrantUserIsNotEligibleAnymore = 'invalid_grant_user_is_not_eligible_anymore'
}

export enum EContractStatus {
  Virtual = 'Virtual',
  PendingSignature = 'PendingSignature',
  Signed = 'Signed'
}

export enum ENotificationType {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning'
}

export enum EMandatesUIState {
  Empty,
  Welcome,
  FirstInvest,
  PerformanceChart
}

export enum EGrantType {
  AuthorizationCode = 'authorization_code',
  BroadCodeGrant = 'broad_code_grant',
  RefreshToken = 'refresh_token'
}

export enum ESubscriptionRedemption {
  Subscription,
  Redemption
}

export enum ESimulationAdviceType {
  InitialAmount,
  RecurringCashFlow,
  TimeHorizon
}

export enum EQworkflowSessionStatus {
  New = 'New',
  Pending = 'Pending'
}
