import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LogOutAdminCommand, LogOutCommand } from '@commands/ui';
import { environment } from '@environments/environment';
import { HeaderMenuItem } from '@models';
import { TranslateService } from '@ngx-translate/core';
import { HeaderService, OnDemandPreloadService, UserOnboardingStatusService } from '@services';
import { AnalyticsStoreService, TokenStoreService } from '@stores';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  public menuItems: HeaderMenuItem[];
  public isLogged: boolean;
  public isUserOnboarded: boolean;
  public isLoggedAsAdmin: boolean;

  private _destroyed$ = new Subject<boolean>();

  constructor(
    private readonly _headerService: HeaderService,
    private readonly _router: Router,
    private readonly _tokenStoreService: TokenStoreService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _translateService: TranslateService,
    private readonly _userOnboardingStatusService: UserOnboardingStatusService,
    private readonly _logOutCommand: LogOutCommand,
    private readonly _analyticsStoreService: AnalyticsStoreService,
    private readonly _logOutAdminCommand: LogOutAdminCommand,
    private readonly _onDemandePreloadService: OnDemandPreloadService
  ) {}

  get isDevMode(): boolean {
    return !environment.production;
  }

  public ngOnInit(): void {
    this._userOnboardingStatusService.isOnboarded$.subscribe((isUserOnboarded) => {
      this.isUserOnboarded = isUserOnboarded;
      this._changeDetectorRef.detectChanges();
    });
    combineLatest([this._tokenStoreService.isLogged$, this._analyticsStoreService.customerInfo$])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([isLogged, customerInfo]) => {
        this.isLogged = isLogged;
        this.isLoggedAsAdmin = customerInfo?.customerRole === 'admin';
        this._changeDetectorRef.detectChanges();
      });
    this.menuItems = this._headerService.menuItems;
    this._changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.unsubscribe();
  }

  public navigateToMandates(): void {
    if (!this.isUserOnboarded) {
      return;
    }
    this._router.navigate(['webclient', this.menuItems[0].redirectTo]);
  }

  public disconnectUser(): void {
    this._logOutCommand.execute();
  }

  public disconnectAdmin(): void {
    this._logOutAdminCommand.execute('disconnect button clicked by an admin');
  }

  public preloadBundle(menuItem: HeaderMenuItem): void {
    this._onDemandePreloadService.startPreload(menuItem.redirectTo);
  }
}
