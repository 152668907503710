import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { InvestmentPlansStore } from './investment-plans.store';
import { InvestmentPlanState } from './investment-plans.model';

@Injectable({ providedIn: 'root' })
export class InvestmentPlansQuery extends QueryEntity<InvestmentPlanState> {
  constructor(protected investmentPlansStore: InvestmentPlansStore) {
    super(investmentPlansStore);
  }
}
