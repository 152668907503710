import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SimulationCashFlowState } from './simulation-cash-flow.state';
import { SimulationCashFlowStore } from './simulation-cash-flow.store';

@Injectable({ providedIn: 'root' })
export class SimulationCashFlowQuery extends Query<SimulationCashFlowState> {
  constructor(protected simulationCashFlowStore: SimulationCashFlowStore) {
    super(simulationCashFlowStore);
  }
}
