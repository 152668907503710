import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { OperationsState } from './operations.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'operations' })
export class OperationsStore extends EntityStore<OperationsState> {
  constructor() {
    super();
  }
}
