import { HttpResponse } from '@angular/common/http';
import { RequestOptions } from '@app/types';
import { Request } from '@backend/requests/request';
import { MeResponseDTO } from '@backend/dto';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class MeRequest extends Request<never, MeResponseDTO> {
  constructor(options?: RequestOptions<never>) {
    super({
      ...options,
      path: `${options?.version ?? '/v2'}/users/me`
    });
  }

  public execute(): Observable<MeResponseDTO> {
    return super.execute().pipe(map((response: HttpResponse<MeResponseDTO>) => response.body));
  }
}

export function getUserData(options?: RequestOptions<never>): MeRequest {
  return new MeRequest(options);
}
