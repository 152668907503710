import { Injectable } from '@angular/core';
import { TokenAuthResponseDTO } from '@backend/dto';
import { ICommand } from '@commands/interfaces';
import { NavigateToEligibilityPageCommand } from '@commands/ui/navigate-to-eligibility-page.command';
import { EErrorCodes } from '@enums';
import { AnalyticsService } from '@services/analytics.service';
import { LoaderService } from '@services/loader.service';
import { UserOnboardingStatusService } from '@services/user-onboarding-status.service';
import { AnalyticsStoreService, ApplicationConfigurationStoreService, EligibilityStoreService, TokenStoreService } from '@stores';
import { v4 as uuidv4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class AuthenticateAdminCommand implements ICommand {
  private _originData: TokenAuthResponseDTO;

  constructor(
    private readonly _loaderService: LoaderService,
    private readonly _applicationConfigurationStoreService: ApplicationConfigurationStoreService,
    private readonly _tokenStoreService: TokenStoreService,
    private readonly _analyticsStoreService: AnalyticsStoreService,
    private readonly _userOnboardingStatus: UserOnboardingStatusService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _eligibilityStoreService: EligibilityStoreService,
    private readonly _navigateToEligibilityPageCommand: NavigateToEligibilityPageCommand
  ) {}

  public execute(): void {
    this._loaderService.updateLoadingStatus(true);
    window.addEventListener('message', this._messageListener.bind(this));
  }

  private _messageListener(e: MessageEvent) {
    if (this._originData) {
      window.removeEventListener('message', this._messageListener.bind(this));
      return;
    }

    if (!e.data || e.origin !== this._applicationConfigurationStoreService?.state?.FORREST_ORIGIN) {
      return;
    }

    this._originData = e.data as TokenAuthResponseDTO;
    this._tokenStoreService.updateAppState(this._originData, true);

    this._analyticsStoreService.setCustomerInfo({
      customerRole: 'admin',
      customerId: uuidv4()
    });

    this._userOnboardingStatus.updateUserOnboardingStatus().subscribe(
      (isUserOnboarded: boolean) => {
        this._eligibilityStoreService.updateEligibilityStatus(true);
        this._analyticsService.track({
          type: 'page',
          page: { title: 'connexion/collaborateur' }
        });
        this._navigateToEligibilityPageCommand.execute({ isUserOnboarded });
      },
      (responseError) => {
        switch (responseError.error.error) {
          case EErrorCodes.InvalidGrantUserNotEligible:
          case EErrorCodes.InvalidGrantUserIsNotEligibleAnymore:
            this._eligibilityStoreService.updateEligibilityError(responseError.error.error);
            this._navigateToEligibilityPageCommand.execute();
            break;
          default:
            this._tokenStoreService.setError(responseError);
        }
      },
      () => {
        this._loaderService.updateLoadingStatus(false);
      }
    );
  }
}
